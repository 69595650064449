import { columnsheadId } from '../../universal/columnshead';
import WebUniversalNewEx, { initDataType, WUExtendPropsType } from '../../system/WebUniversalNewEx';
import ProductEdit from './ProductEdit';
import { Button, Select, Space } from 'antd';
import { useEffect, useRef, useState } from 'react';
import AreaProduct from './AreaProduct';
import fetchApi from '../../../utils/api/fetchApi';
import getList from '../../universal/getList';
import systemConfig from '../../../config/systematic';
import antMessage from '../../../utils/extend/AntdNotification'
import ProductSpecs from './ProductSpecs';

export default function Product(props: any) {
    const ref = useRef<any>(undefined);
    const apiId = "product"

    //价格数据
    // const [productPriceData, setProductPriceData] = useState<any>();

    const [warehouseCategoriesData, setWarehouseCategoriesData] = useState<any>()

    const [selectWarehouseCategories, setSelectWarehouseCategories] = useState<any>()

    //区域产品传递数据
    const [areaProductData, setAreaProductData] = useState<any>();

    /**产品规格传递数据 */
    const [productSpecsData, setProductSpecsData] = useState<any>();

    const [productSpecsModel, setProductSpecsModel] = useState<boolean>(false);
    //区域产品窗口
    const [modalAreaProductOpen,
        setModalAreaProductOpen] = useState(false);


    /** 后端数据Service */
    let wmService: any = systemConfig.wmService;
    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
            // eslint-disable-next-line react-hooks/exhaustive-deps
           
        }
        //同步获取数据 async - await
        const fetchData = async () => {
            fetchApi.GET(wmService, {
                apiId: "warehouseCategories",
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setWarehouseCategoriesData(res.data)
                }
            })
        }
        fetchData();

        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]); //初始化数据
    //表头
    const columnsHead = [
        {
            title: '默认区域',
            dataIndex: 'area',
            render: (text: any) => (text ? text.areaName : "")
        },
        {
            title: '专属id',
            dataIndex: 'selfId',
        },
        {
            title: '分类',
            dataIndex: 'classificationWarehouse',
            sorter: true,
            render: (text: any, record: any) => {
                return (
                    <div>{text.classificationWarehouseName}</div>
                )
            }
        },
        {
            title: '品名',
            dataIndex: 'productName',
        },
        {
            title: "单位",
            dataIndex: "productUnit",
            render: (text: any) => (text ? text.productUnitName : "")
        },
        {
            title: "起率",
            dataIndex: "miniRate",
            // render: (text: any) => (text ? text.productUnitName : "")
        },
        {
            title: '规格',
            dataIndex: 'productSpecs',
            render: (text: any, record: any) => {

                return <Space size="small">
                    <Button size={"small"} type="primary" onClick={() => hanldProductSpecs(record)}>规格</Button>
                </Space>
            }
        },
        {
            title: '区域产品',
            dataIndex: 'areaProduct',
            render: (text: any, record: any) => {

                return <Space size="small">
                    <Button size={"small"} type="primary" onClick={() => hanldAreaProduct(record.id)}>区域产品</Button>
                </Space>
            }
        },
        {
            title: '复制',
            dataIndex: 'copy',
            render: (text: any, record: any) => {

                return <Space size="small">
                    <Button size={"small"} type="primary" onClick={() => {
                        ref.current.openModal("COPY", record.id, record)
                    }}>复制</Button>
                </Space>
            }
        },
    ];
    const columns = columnsheadId
        .concat(columnsHead as []);
    /** 子表头内容 */
    // const expandableColumnsHead = [
    //     {
    //         title: "供应商",
    //         dataIndex: 'Supplier',
    //         render: (text: any, record: any) => (text ? text.supplierName : "")

    //     },
    //     {
    //         title: '产品价格',
    //         dataIndex: 'productPrice',
    //     },
    //     {
    //         title: "产品单位",
    //         dataIndex: "ProductUnit",
    //         render: (text: any, record: any) => (text ? text.wisProductUnitName : "")
    //     },
    // ]

    //传关参数
    let sendData = {
        type: 'EDIT',
        id: '',
        productName: ''
    }

    //打开区域产品
    const hanldAreaProduct = (e: any) => {
        sendData.id = e;
        setAreaProductData(sendData);
        setModalAreaProductOpen(true);
    };


    //关闭区域产品，子组件回调函数
    const onCloseModalAAreaProductOpen = (state: boolean) => {
        setModalAreaProductOpen(false);
        if (state) {
            const currentData: initDataType = {
                apiId
            }
           ref.current.initData(currentData);
        }

    };
    //打开产品规格
    const hanldProductSpecs = (e: any) => {
        setProductSpecsData({
            productId: e.id
        })
        setProductSpecsModel(true);
    };

    //关闭产品规格，子组件回调函数
    const onCloseProductSpecs = (state: boolean) => {
        setProductSpecsModel(false);
        if (state) {
            const currentData: initDataType = {
                apiId
            }
           ref.current.initData(currentData);
        }

    };
    /** 子表头 */
    // const expandableColumns = expandableColumnsHead;
    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '产品',
        apiId,
        showStates: false,
        disabledAdd: false,
        showOperation: true,
        showPrint: true,
        showAdd: true,
        pagination: true,
        columns,
        EditComponent: ProductEdit,
        //子表格
        // isExpandable: true,
        // expandableColumns,
        // expandableDataName: 'productPrice',
    };

    const aa = () => {

        fetchApi.GET(
            wmService,
            {
                apiId: "product",
                apiExtend: 'findMaxSelf',
                apiData: {
                    warehouseCategoriesId: selectWarehouseCategories
                }
            }
        ).then((res) => {
            antMessage('success', '查询成功', "最大品号为:" + res.data.selfId);

        }).catch((err => {
            antMessage('error', '查询失败', err.message + ' | status：' + err.errors.status);
        }))
    }
    const bb = (item: any) => {
        setSelectWarehouseCategories(item)
    }
    return (
        <>
            <Space>
                <Select
                    showSearch
                    placeholder="请选择大类"
                    optionFilterProp="children"
                    onChange={bb}
                    filterOption={(input, option: any) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                    {getList.general(warehouseCategoriesData, 'warehouseCategoriesName')}
                </Select>

                <Button onClick={aa}>查询最大品号</Button>
            </Space>

            <span> </span>
            <WebUniversalNewEx props={extendProps} ref={ref} ></WebUniversalNewEx>
            {/* {modalProductPriceopen ? (<ProductPrice closeModal={onCloseModalPriceopen}
                data={productPriceData} />)
                : null} */}

            {modalAreaProductOpen ? (<AreaProduct closeModal={onCloseModalAAreaProductOpen}
                data={areaProductData} />)
                : null}

            {productSpecsModel ? (<ProductSpecs closeModal={onCloseProductSpecs}
                data={productSpecsData} />)
                : null}
        </>

    )
}
