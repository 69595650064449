import React from 'react'
import { Button, Col, DatePicker, Form, Input, Row, Select, Space, Table } from 'antd';
import systemConfig from '../../../../config/systematic';
import antMessage from '../../../../utils/extend/AntdNotification'
import fetchApi from '../../../../utils/api/fetchApi';
import moment from 'moment';

export default function HandleSignature() {
    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;
    const [form] = Form.useForm();
    // const 
    const onFinish = () => {
        form.validateFields()
        .then(async (values: any) => {
            fetchApi.GET(wmService, {
                apiId: "handleSignature",
                apiExtend: 'handleUserLicenseExpand',
                apiData:values
            }).then((res)=>{
                
            })
        }).catch((err: any) => {
            console.log("err", err);
            antMessage('error', '发送失败', err.message + ' | status：' + err.errors.status);
          })
    }
    const getTempRoomTimeToCustomerReserve=()=>{
          fetchApi.GET(wmService, {
            apiId: "customerReserve",
            apiExtend: 'getTempRoomTimeToCustomerReserve',
        }).then((res)=>{
            antMessage('success', '成功');
             console.log("res",res);
             
        }).catch((err)=>{
              console.log("err",err);
              antMessage('error', '失败', err.message + ' | status：' + err.errors.status);
        })
          
    }
    const getReserveTempRoomTimeToCustomerReserve=()=>{
        fetchApi.GET(wmService, {
          apiId: "customerReserve",
          apiExtend: 'getReserveTempRoomTimeToCustomerReserve',
      }).then((res)=>{
          antMessage('success', '成功');
           console.log("res",res);
           
      }).catch((err)=>{
            console.log("err",err);
            antMessage('error', '失败', err.message + ' | status：' + err.errors.status);
      })
        
  }

  const bbb=()=>{
    fetchApi.POST(wmService, {
        apiId: "cateringOrder",
        apiExtend: 'bbb',
        apiData:{
        }
    }).then((res)=>{
        antMessage('success', '成功');
         console.log("res",res);
         
    }).catch((err)=>{
          console.log("err",err);
          antMessage('error', '失败', err.message + ' | status：' + err.errors.status);
    })
  }
  const reset=()=>{
    fetchApi.POST(wmService, {
        apiId: "invitationLetter",
        apiExtend: 'reset',
        apiData:{
        }
    }).then((res)=>{
        antMessage('success', '成功');
         console.log("res",res);
         
    }).catch((err)=>{
          console.log("err",err);
          antMessage('error', '失败', err.message + ' | status：' + err.errors.status);
    })
  }
  const ddd=()=>{
    fetchApi.POST(wmService, {
        apiId: "queuingList",
        apiExtend: 'ddd',
        apiData:{
        }
    }).then((res)=>{
        antMessage('success', '成功');
         console.log("res",res);
         
    }).catch((err)=>{
          console.log("err",err);
          antMessage('error', '失败', err.message + ' | status：' + err.errors.status);
    })
  }
    return (
        <>
            <Form
                form={form}
                onFinish={onFinish}
            >
                <Row>
                    <Col span={10}>
                        <Form.Item
                            label="需要删除的字段"
                            name="deleteStr"
                            rules={[{ required: true, message: '请输入字段!' }]}
                        >
                            <Input
                            // onChange={handleChange}
                            // onBlur={handleBlur}
                            // placeholder="Input a number"
                            />
                        </Form.Item>
                    </Col>

                    <Col span={10}>
                        <Form.Item
                            label="需要替换的字段"
                            name="replaceStr"
                            rules={[{ required: true, message: '请输入字段!' }]}
                        >
                            <Input
                            // onChange={handleChange}
                            // onBlur={handleBlur}
                            // placeholder="Input a number"
                            />
                        </Form.Item>
                    </Col>
                    
                    {/* <Col span={6}>
                        <Form.Item
                            label="删除的签名表"
                            name="signatures"
                        >
                            <Select
                                mode="multiple"
                                showSearch
                                placeholder="删除的签名表"
                                optionFilterProp="children"
                                onSearch={(e) => getList.search('dept', setDeptData, e)}
                                filterOption={(input, option: any) => {
                                    return true
                                }}
                            >
                                {getList.general(buyerData, 'buyerName')}
                            </Select>
                        </Form.Item>
                    </Col> */}





                    <Col span={4}>
                        <Form.Item >
                            <Space>
                                <Button type="primary" htmlType="submit">
                                    发送
                                </Button >

                                {/* purContrast */}
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Button onClick={getTempRoomTimeToCustomerReserve} danger>预订信息处理</Button>

            <Button onClick={getReserveTempRoomTimeToCustomerReserve} danger>预留房间预订信息处理</Button>


            <Button onClick={bbb} danger>备份点餐数据</Button>

            <Button onClick={reset} danger>重置邀请函信息</Button>

            <Button onClick={ddd} danger>备份排队数据</Button>


        </>
    )
}
