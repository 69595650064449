import { Button, List, Modal, Tooltip } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import systemConfig from '../../config/systematic';
import fetchApi from '../../utils/api/fetchApi';
import { SocketContext } from '../../App';
import antMessage from '../../utils/extend/AntdNotification'
import { showCustomNotification, useNotificationInstance } from '../../utils/extend/AntdNotificationEx';
export default function EntrepotList(props: any) {
    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;
    const [entrepotData, setEntrepotData] = useState<any>();

    const useContextData = useContext(SocketContext);

    const { contextData, setContextData } = useContextData;
    const notificationApi = useNotificationInstance()

    //显示分店弹窗
    const [entrepotOpen, setEntrepotOpen] = useState<boolean>(false);
    useEffect(() => {
        const fetchData = async () => {
            const entrepotRes = await fetchApi.GET(wmService, {
                apiId: 'entrepot',
                apiExtend: 'showListEx',
                apiData: {
                    mountDataOptions: {
                        userMount: {
                            // isEmployees: true,
                            // isDept:true,
                            isUserEntrepots: true
                        }
                    },
                }
            });
            if (entrepotRes.success === true) {

                setEntrepotData(entrepotRes.data)
            }

            await onchangeCurrentEntrepot({
                entrepotId: contextData?.currentEntrepotId,
                entrepotName: contextData?.currentEntrepotName,
                refresh: false
            })
        }
        fetchData()
    }, [])
    const onchangeCurrentEntrepot = async (item: any) => {
        
        if (item.refresh) {
            let nodeObj = {
                //以下可以修改
                users: {
                    entrepotId: item.entrepotId,

                }
            }
            fetchApi.PUT(wmService,
                {
                    apiId: 'configNodes',
                    apiExtend: 'setConfig',
                    apiData: {
                        id: 'config',
                        configName: '用户配置',
                        content: nodeObj,
                        isUser: true
                    }
                }).then(res => {

                    setEntrepotOpen(false)

                }).catch(err => {
                     showCustomNotification(notificationApi!, {
                                    type: 'error',
                                    message: '保存数据失败！',
                                    description: err.message,
                                });
                });
        }

        setContextData({ ...contextData, currentEntrepotName: item.entrepotName, refresh: item.refresh, currentEntrepotId: item.entrepotId })


    }
    return (
        <>
            <div style={{ fontSize: 15 }} onClick={() => setEntrepotOpen(true)} >
                <Tooltip title="点击可仓库">
                    当前仓库:<Button type="primary" size='small'> {contextData?.currentEntrepotName ? contextData?.currentEntrepotName : "请选择仓库"}</Button>
                </Tooltip>
            </div>

            {/**查看分店 */}
            {entrepotOpen ? <Modal
                title={<div style={{ fontSize: "20px" }}>请选择仓库</div>}
                open={true}
                onCancel={() => setEntrepotOpen(false)}
                onOk={() => setEntrepotOpen(false)}
                footer={null}
                width={"60%"}
                centered
            >
                <List
                    size="large"
                    dataSource={entrepotData}
                    renderItem={(item: any) => (
                        <List.Item key={item.id}>
                            <List.Item.Meta
                                title={<div style={{ fontSize: "30px" }}>{`${item.entrepotName}`}</div>}
                            />
                            <Button type="primary" size="large" onClick={() => onchangeCurrentEntrepot({ ...item, refresh: true })}>
                                选择
                            </Button>
                        </List.Item>
                    )}
                />
            </Modal> : null}
        </>

    )
}
