import { useRef } from 'react';
import WebUniversal, { WUExtendPropsType } from '../../../system/WebUniversalNewEx'
import { columnsheadId } from '../../../universal/columnshead';
import { Tooltip, Typography } from 'antd';
import systemConfig from '../../../../config/systematic';
import dayjs from 'dayjs';

/** 组件 */
const WebComponent = (props: any) => {
    const ref = useRef<any>(undefined);
    const { Paragraph } = Typography;
    const { data } = props;
    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;
    /** 表头内容 */
    const columnsHead = [
        {
            title: 'MqttOnline',
            dataIndex: 'mqttOnline',
            render: (text: any) => <Tooltip placement="topLeft" title={text?.mqttClientId}>{
                (typeof text !== "undefined" && text !== null) ?
                    (text.mqttClientId.length >= 8 ? text.mqttClientId.substring(0, 8) + '......' : text.mqttClientId)
                    : ''
            }</Tooltip>
        },
        {
            title: '模式',
            dataIndex: 'isSendingOrReceivingMode',
            render: (text: any) => {
                return (<>
                    {text === true ?
                        <Tooltip placement="topLeft"
                            title={`发送或接收模式`}>
                            <p style={{ color: 'yellow' }}>发送</p> </Tooltip> : '接收'}
                </>)
            }
        },
        {
            title: '内容',
            dataIndex: 'payload',
            render: (text: any) => <Tooltip placement="topLeft" title={text}>{
                (typeof text !== "undefined" && text !== null) ?
                    (text.length >= 8 ? text.substring(0, 8) + '......' : text)
                    : ''
            }</Tooltip>
        },
        {
            title: '发送状态',
            dataIndex: 'isSendSuccess',
            render: (text: any, record: any) => {
                return (<>
                    {text === true ?
                        <Tooltip placement="topLeft"
                            title={<Paragraph style={{ whiteSpace: 'pre-wrap' }}>
                                {`已发送: ${record.isSended}` +
                                    `\n发送时间: ${dayjs(record.sendingTime).format('YYYY-MM-DD HH:mm:ss')}` +
                                    `\n成功时间: ${dayjs(record.sendSuccessfullyAndReturnTime).format('YYYY-MM-DD HH:mm:ss')}`}
                            </Paragraph>}>
                            <p style={{ color: 'greenyellow' }}>成功</p> </Tooltip> : '失败'}
                </>)
            }
        },
        {
            title: '发送次数',
            dataIndex: 'sendCount'
        },
    ];

    /** 表头 */
    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: 'mqtt在线主题内容',
        apiId: 'mqttOnlineTopic',
        columns,
        showStates: true,
        pagination: true,
        showAdd: false,
        showEdit: false,
        sendPageData: data
    };

    return (
        <>
            <WebUniversal props={extendProps} ref={ref} />
        </>
    )
}

export default WebComponent;
