import { useEffect, useState } from "react";
import { columnsheadId } from "../../universal/columnshead";
import ReserveMenuModelUploadFileEdit from "./ReserveMenuModelUploadFileEdit";
import systemExpand from "../../../config/systemExpand";
import UploadFileListUniversal, { UFLUExtendPropsType } from "../../system/UploadFileListUniversalNewEx";

//组件 ReserveMenuModelUploadFile
export default function ReserveMenuModelUploadFile(props: any) {

    //extendProps 
    const [extendProps, setExtendProps] = useState<UFLUExtendPropsType>();

    //表头
    const columnsHead = [
        {
            title: '模板',
            dataIndex: 'reserveMenuModel',
            render: (text: any, record: any) => {
                return (
                    <div>{record.reserveMenuModel.reserveMenuModelName}</div>
                )
            }
        },
        {
            title: '排序',
            dataIndex: 'sortBy',
        },
    ];

    const filesColumns = columnsheadId
        .concat(columnsHead as []);

    useEffect(() => {
        systemExpand.getConfig({
            id: 'config',
            nodeId: 'files'
        }).then(res => {
            const { files } = res as any;
            const { reserveMenuModelFilesGroupId } = files;
            setExtendProps({
                ...props,
                titleName: '模板效果图',
                apiId: 'reserveMenuModel',
                filesGroupId: reserveMenuModelFilesGroupId,
                fileType: "files",
                //+ File
                //上传 + UploadFile
                //删除 + DeleteFile
                filesColumns,
                showImage: true,
                // showDownload: true,
                showStates: true,
                showDefault: true,
                showFileUpload: true,
                // showFileSelection: true,
                showOperation: true,
                extendIdName: "reserveMenuModelId",
                EditComponent: ReserveMenuModelUploadFileEdit,
            })
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (extendProps) {
        return (
            <UploadFileListUniversal props={extendProps} />
        )
    }
}