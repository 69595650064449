import { Form, Input, Switch, Row, Col, Select, Button, Tooltip } from 'antd';
import { useRef, useState, useEffect } from 'react';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal'
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx'
import fetchApi from '../../../utils/api/fetchApi';
import getList from '../../universal/getList';
import objectHandle from '../../../utils/baseLib/objectHandle';
import systemConfig from '../../../config/systematic';
import UserLogin from './UserLogin';
import UserLoginDevice from './UserLoginDevice';

//布局
const layout = {
    // labelCol: { span: 8 },
    // wrapperCol: { span: 16 }, 
};

const UserEdit = (props: any) => {
    const [form] = Form.useForm(); //form 
    const { data } = props;
    //userId
    const { id } = data;
    const ref = useRef<any>(undefined);
    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };
    const [roleData, setRoleData] = useState<any>();
    const [initialRolesId, setInitialRolesId] = useState<any>([]);
    // const [deptData, setDeptData] = useState<any>()
    const [employeesData, setEmployeesData] = useState<any>()
    const [userGroupData, setUserGroupData] = useState<any>();
    //打开/关闭 用户登录设备
    const [modalVisibleUserLoginDevice, setModalVisibleUserLoginDevice] = useState(false);
    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;
    useEffect(() => {
        if (ref.current) {

            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
            if (data.type === "EDIT") {
                ref.current.row.then(async (res: any) => {
                    if (res.success === true) {
                        const { rolesId, employeesId } = res.data;
                        let rolesRows;
                        if (objectHandle.isEmpty(rolesId) === false) {
                            rolesRows = rolesId.split('@');
                        }
                        setInitialRolesId(rolesRows);
                        form.setFieldsValue({
                            rolesId: rolesRows
                        })
                        if (objectHandle.isEmpty(employeesId) !== true) {
                            fetchApi.GET(wmService, {
                                apiId: 'sys_employees',
                                apiExtend: 'showSingle',
                                apiVariable: employeesId
                            }).then(res => {
                                if (res.success) {
                                    setEmployeesData(res.data);
                                }
                            });
                        }
                    }
                })
            }

        }
        const fetchData = async () => {
            fetchApi.GET(wmService,
                {
                    apiId: 'role',
                    apiExtend: 'showList'
                }).then(res => {
                    if (res.success) {
                        setRoleData(res.data);
                    }
                });
            // fetchApi.GET(wmService,
            //     {
            //         apiId: 'dept',
            //         apiExtend: 'showList',
            //         apiData:{

            //         }
            //     }).then(res => {
            //         if (res.success) {
            //             setDeptData(res.data)
            //         }
            //     });
            fetchApi.GET(wmService,
                {
                    apiId: 'userGroup',
                    apiExtend: 'showList'
                }).then(res => {
                    if (res.success) {
                        setUserGroupData(res.data)
                    }
                })

            // fetchApi.GET(wmService,
            //     {
            //         apiId: 'employees',
            //         apiExtend: 'showList'
            //     }).then(res => {
            //         if (res.success) {
            //             setEmployeesData(res.data)
            //         }
            //     })
        };
        fetchData();
    }, []);

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'user',
    };

    return (
        <>
            <WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '用户'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={750}>

                <Form
                    {...layout}
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                    onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}>

                    <Row gutter={[8, 0]}>
                        <Col span={12}>
                            <Form.Item
                                label="ID"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="用户名"
                                name="userName"
                                rules={[{ required: true, message: '请输入用户名!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="用户组"
                                name="userGroupId">
                                <Select
                                    showSearch
                                    placeholder="用户组"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(userGroupData, 'userGroupName')}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="员工"
                                name="employeesId"
                                rules={[{ required: false, message: '请选择员工!' }]}>
                                <Select
                                    showSearch
                                    placeholder="选择员工"
                                    optionFilterProp="children"
                                    onSearch={(e) => {
                                        getList.search('sys_employees', setEmployeesData, e);
                                    }}
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.generalEx(employeesData, { includeNull: true })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="角色"
                                name="rolesId"
                                initialValue={initialRolesId} >
                                <Select
                                    mode="multiple"
                                    showSearch
                                    placeholder="选择角色"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(roleData, 'roleName', 'id', undefined, false)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="地址"
                                name="address">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={9}>
                            <Form.Item
                                label="手机"
                                name="phone">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={15}>
                            <Form.Item
                                label="微信unionId"
                                name="unionId">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="签名ID"
                                name="signatureId">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="签名地址"
                                name="signatureUrl">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="同时在线"
                                tooltip='对当前用户登录数进行限制，超过登录数，会踢掉最先登录'
                                name="sameTimeOnlines">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item
                                label="管理员"
                                name="isAdmin"
                                valuePropName="checked"
                                initialValue={false}>
                                < Switch checked={false}
                                    disabled={false}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>

                        {/* <Col span={6}>
                            <Form.Item
                                label="检查登录设备"
                                name="isCheckLoginDevice"
                                tooltip='开启时，用户登录时检查登录设备'
                                valuePropName="checked"
                                initialValue={false}>
                                < Switch checked={false}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col> */}

                        <Col span={4}>
                            <Form.Item>
                                <Tooltip placement="topLeft" title={'用户绑定登录设备列表'}><Button
                                    onClick={() => setModalVisibleUserLoginDevice(true)}
                                >登录设备</Button></Tooltip>
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>
                <UserLogin data={data}></UserLogin>
            </AntDraggableModal>
            {modalVisibleUserLoginDevice ? (<UserLoginDevice payload={{
                userId: id,
                handleOk: () => setModalVisibleUserLoginDevice(false)
            }} />) : null}
        </>
    )
}

export default UserEdit;