import './login.css';
import { Form, Input, Button, Checkbox, Modal, Space, Tabs, TabsProps } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import fetchApi from '../../../utils/api/fetchApi';
import antMessage from '../../../utils/extend/AntdNotification';
import system from '../../../config/systematic';
import { useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { SocketContext, wsConnect } from '../../../App';
import { showCustomNotification, useNotificationInstance } from '../../../utils/extend/AntdNotificationEx';
import idHandle from '../../../utilsTs/baseLib/idHandle';
// import jwt from "jsonwebtoken";
//组件 Login 
const Login = (props: any) => {
    const navigator = useNavigate()
    const [form] = Form.useForm(); //form 

    const form1 = Form.useForm()[0]; //form 

    //判断是否选中
    // const [ischecked, setIsChecked] = useState<boolean>(true)
    /** 后端数据wmService */
    let wmService: any = system.wmService;
    const useContextData = useContext(SocketContext);
    const { contextData, setContextData } = useContextData;
    const [tabId, setTabId] = useState<any>("login")
    // const { wsServer } = useContextData;

    const [deviceId, setDeviceId] = useState<any>(null);
    const [deviceCode, setDeviceCode] = useState<any>(null);
    const [deviceName, setDeviceName] = useState<any>(null);

    const notificationApi = useNotificationInstance();


    useEffect(() => {

        // localStorage.

        if (localStorage.getItem("deviceId") !== null) {
            setDeviceId(localStorage.getItem("deviceId"))
        }
        if (localStorage.getItem("deviceCode") !== null) {
            setDeviceCode(localStorage.getItem("deviceCode"))
        }
        if (localStorage.getItem("deviceName") !== null) {
            setDeviceName(localStorage.getItem("deviceName"))
        }

    }, [])

    // const { contextData,setContextData } = useContextData;
    const onFinish = async (e: any) => {
        form.validateFields()
            .then(async (values: any) => {
                let dataEx = {
                    apiId: 'login',
                    apiExtend: 'login',
                    apiData: {
                        logId: values.logId,
                        logPass: values.logPass,
                        deviceId,
                        deviceCode,
                        isUserConfig: true

                    }
                }
                // fetchApi.POST(wmService, {
                //     apiId: 'device',
                //     apiExtend: 'register',
                //     apiVariable: deviceId,
                //     apiData: {
                //         deviceCode: 'deviceCode', //变量：设备码由使用者电脑生成，如获取不了，随机生成，唯一值
                //         deviceTypeId: 'computer', //设备类型id:'computer'电脑
                //         loginId:values.logId , //变量：登录id 
                //         associatedMode: { id: 'USER' }, //关联模式id
                //         serverMode: { id: 'HTTP' } //服务器模式
                //     }
                // })

                fetchApi.GET(wmService,
                    dataEx).then(res => {

                        if (res.success === true) {
                            const { userConfigData } = res.data
                            sessionStorage.setItem('branchStoreName', res.data.branchStoreName);
                            sessionStorage.setItem('isAdmin', res.data.isAdmin);
                            sessionStorage.setItem('token', res.data.jwtSign.token);
                            sessionStorage.setItem('areaId', res.data.areaId);
                            sessionStorage.setItem('userId', res.data.userId);
                            sessionStorage.setItem('userName', res.data.userName);
                            sessionStorage.setItem('branchStoreId', res.data.branchStoreId);
                            sessionStorage.setItem('deptId', res.data.deptId);
                            sessionStorage.setItem('brandId', res.data.brandId);
                            sessionStorage.setItem('brandName', res.data.brandName);

                            navigator("/")

                            localStorage.setItem('num', "0");
                            if (userConfigData) {
                                setContextData({ ...contextData, currentEntrepotName: userConfigData.users?.entrepotName, currentEntrepotId: userConfigData.users?.entrepotId })
                            }
                            //wsServer
                            wsConnect('tokenLogin', { token: res.data.jwtSign.token });

                        }
                        else {
                            showCustomNotification(notificationApi!, {
                                type: 'error',
                                message: "登录失败",
                            });
                            // antMessage('error', '登录失败', '');
                        }
                    }).catch(err => {
                        showCustomNotification(notificationApi!, {
                            type: 'error',
                            message: "登录失败",
                            description: err.message + ' status:' + err.errors.status
                        });
                    })
            })
            .catch(err => {

                showCustomNotification(notificationApi!, {
                    type: 'warning',
                    message: "无法通过数据校验",
                    description: "请输入所需数据!"
                });;
            })
    };

    const onFinish1 = async (e: any) => {
        console.log("e", e);
        form1.validateFields()
            .then(async (values: any) => {

                switch (Number(e)) {
                    //注册设备
                    case 1:
                        let deviceCodeEx = deviceCode;
                        if (!deviceCodeEx) {
                            deviceCodeEx = idHandle.getShortUuid({ "letterFix": "ALL" })
                            setDeviceCode(deviceCodeEx);
                            localStorage.setItem("deviceCode", deviceCodeEx)

                        }
                        fetchApi.POST(wmService, {
                            apiId: 'device',
                            apiExtend: 'register',
                            // apiVariable: deviceId,
                            apiData: {
                                deviceName:values.deviceName,
                                deviceCode: deviceCodeEx, //变量：设备码由使用者电脑生成，如获取不了，随机生成，唯一值
                                deviceTypeId: 'computer', //设备类型id:'computer'电脑
                                loginId: values.logId, //变量：登录id 
                                associatedMode: { id: 'USER' }, //关联模式id
                                serverMode: { id: 'HTTP' } //服务器模式
                            }
                        }).then((res) => {
                            const { id,deviceName } = res.data;
                            setDeviceId(id)
                            setDeviceName(deviceName)
                            localStorage.setItem("deviceId", id)
                            localStorage.setItem("deviceName", deviceName)

                            showCustomNotification(notificationApi!, {
                                type: "success",
                                message: "已申请注册设备",
                                description: "等待后台同意，同意过后即可登录成功!"
                            });;
                        })
                            .catch(err => {
                                showCustomNotification(notificationApi!, {
                                    type: 'error',
                                    message: "注册设备失败",
                                    description: err.message + ' status:' + err.errors.status
                                });
                            })
                        break;
                    //绑定设备
                    case 2:
                        if (!deviceId) {
                            showCustomNotification(notificationApi!, {
                                type: 'error',
                                message: "设备id不存在，请申请注册设备",

                            });
                            return false
                        }
                        fetchApi.POST(wmService, {
                            apiId: 'device',
                            apiExtend: 'associate',
                            
                            apiData: {
                                deviceId,
                                loginId: values.logId, //变量：登录id 
                                associatedMode: { id: 'USER' }, //关联模式id
                            }
                        }).then((res) => {
                           
                            showCustomNotification(notificationApi!, {
                                type: "success",
                                message: "已申请绑定设备",
                                description: "等待后台同意，同意过后即可登录成功!"
                            });;
                        })
                            .catch(err => {
                                
                                showCustomNotification(notificationApi!, {
                                    type: 'error',
                                    message: "绑定设备失败",
                                    description: err.message + ' status:' + err.errors.status
                                });
                            })
                        break;
                }

            })
            .catch(err => {

                showCustomNotification(notificationApi!, {
                    type: 'warning',
                    message: "无法通过数据校验",
                    description: "请输入所需数据!"
                });;
            })




    };


    const onChange = (key: string) => {
        setTabId(key)
    };

    const items: TabsProps['items'] = [
        {
            key: 'login',
            label: '登录',
            children: <Form
                form={form}
                name="normal_login"
                className="login-form"
                onKeyDown={(e: any) => {
                    if (e.key === 'Enter') { onFinish(e) }
                }}

                onFinish={onFinish}
            >
                <Form.Item
                    name="logId"
                    label="账号"
                    rules={[
                        {
                            required: true,
                            message: '请输入登录ID!',
                        },
                    ]}
                >
                    <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="登录ID" />
                </Form.Item>
                <Form.Item
                    name="logPass"
                    label="密码"
                    rules={[
                        {
                            required: true,
                            message: '请输入密码!',
                        },
                    ]}
                >
                    <Input
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        type="password"
                        placeholder="密码"
                    />
                </Form.Item>



            </Form>,
        },
        {
            key: 'bindDevice',
            label: '绑定设备',
            children: <Form
                form={form1}
                name="normal_bindDevice"
                className="bindDevice-form"


                onFinish={onFinish1}
            >
                <Form.Item
                    name="logId"
                    label="账号"
                    rules={[
                        {
                            required: true,
                            message: '请输入登录ID!',
                        },
                    ]}
                >
                    <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="登录ID" />
                </Form.Item>

                <Form.Item
                    name="deviceName"
                    label="设备名称"
                    
                >
                    <Input disabled={deviceId?true:false} placeholder={deviceName?deviceName:"自定义设备码"} />
                </Form.Item>



            </Form>,

        },

    ];
    // const getDeviceId = () => {
    //     fetchApi.POST(wmService, {
    //         apiId: "deviceManagement",
    //         apiExtend: "register",
    //         apiVariable:deviceId,
    //         apiData: {
    //             deviceCode,
    //             isRequiresDeviceCode: true //是否返回设备码
    //         }
    //     }).then((res) => {

    //         setDeviceId(res.data.id)
    //         setDeviceCode(res.data.deviceCode)
    //         localStorage.setItem("deviceId", res.data.id)
    //         localStorage.setItem("deviceCode", res.data.deviceCode)
    //     })
    // }



    return (
        <>

            <Modal
                title={<Tabs defaultActiveKey="login" items={items} onChange={onChange} />}
                open={true}
                footer={<>
                    <Space>
                        {/* <Button disabled={deviceId?false:true} onClick={onFinish}>登录</Button> */}
                        {tabId === "login" ? <Button onClick={onFinish}>登录</Button> : null}
                        {tabId === "bindDevice" ? <Button onClick={() => onFinish1(1)} disabled={deviceId ? true : false}>{"注册设备"}</Button> : null}
                        {tabId === "bindDevice" ? <Button onClick={() => onFinish1(2)} >{"绑定设备"}</Button> : null}

                    </Space>
                </>}
                keyboard
                //    onOk={onFinish}
                closable={false}
                maskClosable={false}
                width={400}
                centered
            >



            </Modal>
        </>

    );
}

export default Login;