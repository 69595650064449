import { Col, Form, Input, InputNumber, Row, Switch } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react'
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';
import { SocketContext } from '../../../App';

//布局
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

export default function ReserveMenuCategoryEdit(props: any) {

    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>(undefined);

    /** 文本框状态 */
    const [state, setState] = useState<any>(false);

    const useContextData = useContext(SocketContext);
    const { contextData } = useContextData;

    /** 后端数据wmService */
    let wmService: any = "";

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
        }
        const fetchData = async () => {

            form.setFieldsValue({ branchStoreId: contextData?.currentBranchStoreId });

            if (data.type === 'EDIT') {
                setState(true);
            }
        }
        fetchData()
    }, [])

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };

    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'reserveMenuCategory'
    };

    return (
        <>
            <WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '预订菜单分类'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
            >
                <Form
                    {...layout}
                    form={form}
                    initialValues={{ "sortBy": 999 }}
                    name="Edit"
                    onFinish={handleOk}
                    onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}
                >
                    <Row gutter={[16, 0]}>
                        <Col span={24}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={0}>
                            <Form.Item
                                label="branchStoreId"
                                name="branchStoreId">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="预订菜单分类"
                                name="reserveMenuCategoryName"
                                rules={[{ required: true, message: '请输入预订菜单分类!' }]}>
                                <Input disabled={state} />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="预订菜单分类(英文)"
                                name="reserveMenuCategoryNameEn">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="专属id(简码)"
                                name="selfId">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="排序"
                                name="sortBy">
                                <InputNumber min={1} />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="特殊类别"
                                name="isDefault"
                                valuePropName="checked"
                            >
                                <Switch
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                <Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}