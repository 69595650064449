import { useEffect, useRef } from 'react'
import { columnsheadId } from '../../universal/columnshead';
import WebUniversalNewEx, {  WUExtendPropsType } from '../../system/WebUniversalNewEx';

export default function MenuBinding(props: any) {

    const ref = useRef<any>(undefined);

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }

        //同步获取数据 async - await
        const fetchData = async () => {
        }

        fetchData();
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    //表头
    const columnsHead = [
        {
            title: '绑定Id (包含规格，特殊规格，备注，加料等等)',
            dataIndex: 'targetId',
        },
        {
            title: '绑定位置',
            dataIndex: 'bindingPositionName',
        },
        {
            title: '备注',
            dataIndex: 'memo',
        }
    ];

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '参数绑定',
        apiId: 'menuBinding',
        showAdd: false,
        showEdit: false,
        showDelete: sessionStorage.getItem('isAdmin'),
        columns,
        pagination: true,
    };

    return (
        <>
            <WebUniversalNewEx props={extendProps} ref={ref} />
        </>
    )
}