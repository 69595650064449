import WebUniversalNewEx, { initDataType, WUExtendPropsType } from '../../system/WebUniversalNewEx';
import { columnsheadId } from '../../universal/columnshead';
import ReserveMenuModelEdit from './ReserveMenuModelEdit';
import ReserveMenuStyle from './ReserveMenuStyle';
import { SocketContext } from '../../../App';
import { useContext, useEffect, useRef, useState } from 'react';
import { Button, Space } from 'antd';
import ReserveMenuModelUploadFile from "./ReserveMenuModelUploadFile";

export default function ReserveMenuModel(props: any) {

    const ref = useRef<any>(undefined);

    /** api服务id */
    const apiId = 'reserveMenuModel';

    //样式数据
    const [styleData, setStyleData] = useState<any>();

    //结构样式窗口
    const [reserveMenuStyleVisible, setReserveMenuStyleVisible] = useState(false);

    const useContextData = useContext(SocketContext);
    const { contextData, setContextData } = useContextData;

    useEffect(() => {

        if (contextData?.currentBrandId) {
            if (contextData?.refresh) {
                onRefresh({ brandId: contextData?.currentBrandId })
            }
        }

        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contextData?.currentBrandId]); //初始化数据

    const onRefresh = (item: any) => {
        const { brandId } = item;
        const currentData: initDataType = {
            apiId,
            sendPageData: {
                brandId
            }
        }
        ref.current.initData(currentData);

        setContextData({ ...contextData, refresh: false })
    }

    //打开结构样式
    const handleReserveMenuStyle = (e: any) => {

        sendData.id = e.id;
        sendData.brandId = e.brandId;
        sendData.brandName = e.brand.brandName;
        sendData.reserveMenuModelName = e.reserveMenuModelName;
        setStyleData(sendData);
        setReserveMenuStyleVisible(true);
    };

    //表头
    const columnsHead = [
        {
            title: '品牌',
            dataIndex: 'brand',
            render: (text: any, record: any) => {
                return (
                    <div>{record.brand.brandName}</div>
                )
            }
        },
        {
            title: '模板名',
            dataIndex: 'reserveMenuModelName',
        },
        {
            title: '结构样式',
            dataIndex: 'style',
            key: 'style',
            render: (text: any, record: any) => {
                return <Space size="small">
                    <Button size={"small"} type="primary" onClick={() => handleReserveMenuStyle(record)}>结构样式</Button>
                </Space>
            }
        },
    ];

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '菜式预订模板',
        apiId: 'reserveMenuModel',
        columns,
        showStates: true,
        showDefault: true,
        showImage: true,
        showUploadFile: true,
        pagination: true,
        extendIdName: "brandId",
        EditComponent: ReserveMenuModelEdit,
        arrayFilesName: 'reserveMenuModelFiles',
        UploadFileComponent: ReserveMenuModelUploadFile,
        sendPageData: { brandId: contextData?.currentBrandId },
        mountDataOptions: {
            userMount: {
                isBrand: true
            }
        }
    };

    //传关参数
    let sendData = {
        type: 'EDIT',
        key: '',
        id: '',
        brandId: '',
        brandName: '',
        reserveMenuModelName: ''
    }

    return (
        <>
            <WebUniversalNewEx props={extendProps} ref={ref}></WebUniversalNewEx>
            {reserveMenuStyleVisible
                ? (<ReserveMenuStyle
                    closeModal={setReserveMenuStyleVisible} data={styleData} />)
                : null}
        </>
    )
}