import { columnsheadId } from '../../universal/columnshead';
import WebUniversalNewEx, { WUExtendPropsType } from '../../system/WebUniversalNewEx';
import ExpandCategoryEdit from './ExpandCategoryEdit';
import { useEffect, useRef, useState } from 'react';
import { Button } from 'antd';
import WarehouseToMenu from '../menu/WarehouseToMenu';

export default function ExpandCategory(props: any) {

    const ref = useRef<any>(undefined);

    //添加扩展详细内容窗口
    const [menuWarehouseToDetailVisible, setMenuWarehouseToDetailVisible] = useState(false);

    //扩展详细内容数据
    const [menuWarehouseToDetailData, setMenuWarehouseToDetailData] = useState<any>();

    /** api服务id */
    const apiId = 'expandCategory';

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            // handleChangeOne = ref.current.handleChangeOne;
        }
        //同步获取数据 async - await
        const fetchData = async () => {
        }

        fetchData();
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    //添加详细内容
    const handleDetail = (record: any) => {

        sendData.key = 'menuWarehouseToExpandDetail';
        sendData.expandCategoryId = record.id;
        sendData.expandCategoryName = record.expandCategoryName;
        sendData.areaId = record.areaId;
        sendData.areaName = record.area.areaName;

        setMenuWarehouseToDetailData(sendData);
        setMenuWarehouseToDetailVisible(true);
    };

    //关闭穿梭框页面
    const onCloseMenuWarehouseToDetail = () => {
        setMenuWarehouseToDetailVisible(false);
    }

    //表头
    const columnsHead = [
        {
            title: '地区',
            dataIndex: 'areaName',
            render: (text: any, record: any) => {
                return (
                    <div>{record.area.areaName}</div>
                )
            }
        },
        {
            title: '扩展分类',
            dataIndex: 'expandCategoryName',
        },
        {
            title: '添加详细内容',
            dataIndex: 'checkDetail',
            render: (text: any, record: any) => (
                <Button type="primary" size='small' onClick={() => handleDetail(record)}>添加详细内容</Button>
            )
        }
        // {
        //     title: '排序',
        //     dataIndex: 'sortBy',
        // },
    ];

    const columns = columnsheadId
        .concat(columnsHead as []);

    //传关参数
    let sendData = {
        type: 'EDIT',
        key: '',
        expandCategoryId: '',
        expandCategoryName: '',
        areaId: '',
        areaName: ''
    }

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '扩展分类',
        apiId,
        showStates: true,
        columns,
        pagination: true,
        EditComponent: ExpandCategoryEdit,
        // mountDataOptions: {
        //     userMount: {
        //         isDept: true
        //     }
        // }
    };

    return (
        <>
            <WebUniversalNewEx props={extendProps} ref={ref}></WebUniversalNewEx>
            {menuWarehouseToDetailVisible
                ? (<WarehouseToMenu
                    closeModal={onCloseMenuWarehouseToDetail} data={menuWarehouseToDetailData} />)
                : null}
        </>
    )
}