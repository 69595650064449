import { Tooltip } from 'antd';
import WebUniversalNewEx, { WUExtendPropsType } from '../../../system/WebUniversalNewEx';
import { columnsheadId } from '../../../universal/columnshead';
import MqttTopicEdit from './MqttTopicEdit';

const WebComponent = (props: any) => {

    //表头
    const columnsHead = [
        {
            title: '主题名称',
            dataIndex: 'topicName',
        },
        {
            title: '默认唯一主题',
            dataIndex: 'isDefaultUniqueTopic',
            render: (text: any) => {
                return (<>
                    {text === true ?
                        <Tooltip placement="topLeft"
                            title={`默认唯一主题，如果: 是，topic由'<mqttTopicId>/<mqttClientId>'组成，默认：true`}>
                            <p style={{ color: 'yellow' }}>是</p> </Tooltip> : '否'}
                </>)
            }
        },
        {
            title: '服务质量',
            dataIndex: 'qos',
            sorter: true,
        },
    ];
    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: 'mqtt主题',
        apiId: 'mqttTopic',
        showStates: true,
        columns,
        pagination: true,
        EditComponent: MqttTopicEdit,
    };

    return (
        <WebUniversalNewEx props={extendProps}></WebUniversalNewEx>
    )
}

export default WebComponent