import React, { useContext, useEffect, useRef, useState } from 'react'
import { columnsheadId } from '../../universal/columnshead';
import WebUniversalNewExEx, { initDataType, WUExtendPropsType } from '../../system/WebUniversalNewExEx';
import TransferOrderEdit from './TransferOrderEdit';
import moment from 'moment';
import { Button, Select, Tooltip, Image, DatePicker, Popconfirm, Checkbox } from 'antd';
import getList from '../../universal/getList';
import systemConfig from '../../../config/systematic';
import fetchApi, { httpGet } from '../../../utils/api/fetchApi';
import orderCutKey from '../../../generalMethod/OrderCutKey';
import dayjs from 'dayjs';
import setOrderDate from '../../../generalMethod/setOrderDate';
import CancelOrder from './CancelOrder';
import entrepotPrint from '../../../generalMethod/entrepotPrint';
import orderPeration from '../../../generalMethod/orderPeration';
import { SocketContext } from '../../../App';

let wmService: any = systemConfig.wmService;

export default function TransferOrder(props: any) {
    const apiId = "transferOrder"
    const ref = useRef<any>(undefined);

    /**打开商品窗口 */
    // const [modalProductOpen, setModalProductOpen] = useState<boolean>(false);
    //删除按纽的checkBox是否checked
    let [dBtnChecked, setDBtnChecked] = useState<any>({});

    /**打开取消订单窗口 */
    const [modalCancelOrder, setModalCancelOrder] = useState<boolean>(false);
    /**选中的调拨单状态数据 */
    const [selectedOrderStatus, setSelectedOrderStatus] = useState<any>(-1);

    /**选中的调拨单状态数据 */
    const [selectedPrintStatus, setSelectedPrintStatus] = useState<any>(-1);
    const [date, setDate] = useState<any>()


    /**订单打印状态数据 */
    const [printStatus, setPrintStatus] = useState<any>()
    /**订单状态数据 */
    const [orderStatus, setOrderStatus] = useState<any>()
    /**传递商品窗口的数据 */
    // const [productData, setProductData] = useState<any>();
    const useContextData = useContext(SocketContext);
    const { contextData, setContextData } = useContextData;
    /**传递取消订单窗口的数据 */
    const [cancelOrderData, setCancelOrderData] = useState<any>();

    //表头
    const columnsHead = [
        {
            title: "调出部门",
            dataIndex: "exportDept",
            align: "center",
            render: (text: any) => <div style={{ "fontSize": 12.5 }}>
                {text ? text.deptName : ""}
            </div>
        },
        {
            title: "调入部门",
            dataIndex: "importDept",
            align: "center",

            render: (text: any) => <div style={{ "fontSize": 12.5 }}>
                {text ? text.deptName : ""}
            </div>
        },
        {
            title: "日期",
            dataIndex: "sysDate",
            align: "center",
            width: 85,
            render: (val: any, record: any) =>
                <span style={{ "fontSize": 12.5 }}>
                    {moment(val.createdAt).format('YYYY-MM-DD')}
                </span>

        },
        {
            title: '调出仓库',
            dataIndex: "exportEntrepot",
            align: "center",

            render: (text: any) => <div style={{ "fontSize": 12.5 }}>{text ? text.entrepotName : ""}</div>
        },
        {
            title: '调入仓库',
            dataIndex: "importEntrepot",
            align: "center",

            render: (text: any) => <div style={{ "fontSize": 12.5 }}>{text ? text.entrepotName : ""}</div>
        },
        {
            title: '流程',
            dataIndex: 'totalOrderProcessName',
            align: "center",
            width: 70,
            render: (text: any, record: any) => <div style={record.orderState === -99 ? { color: "red", "fontSize": 12.5 } : { "fontSize": 12.5 }}>{text ? text : ""}</div>

        },
        {
            title: '订单状态',
            dataIndex: 'orderStatusName',
            align: "center",
            width: 75,
            render: (text: any, record: any) => {

                return <div style={record.orderStatus === 4 ? { color: "red", "fontSize": 12.5 } : { "fontSize": 12.5 }}>{text ? text : ""}</div>

            }

        },
        {
            title: '制表',
            dataIndex: 'userLogin',
            align: "center",
            width: 60,
            render: (text: any) => <div style={{ "fontSize": 12.5 }}>{text ? text.userName : ""}</div>
        },
        {
            title: '印数',
            dataIndex: 'printNumber',
            align: "center",
            width: 50,

        },
        {
            title: '打印',
            dataIndex: 'print',
            align: "center",
            width: 50,
            render: (text: any, record: any) => (<Button style={record.printNumber >= 1 ? { "backgroundColor": "yellow", "color": "black" } : {}} disabled={record.orderState === 99 && record.orderStatus === 3 ? false : true} size='small' type='primary' onClick={() => entrepotPrint.printOrder("transferOrderPrint", "transferProducts", apiId, record)}>打印</Button>)
        },
        {
            title: '发出',
            dataIndex: 'exDeptSignature',
            align: "center",
            width: 55,
            render: (text: any, record: any) => {
                return Object.keys(text)?.length > 0 ? <Image width={40} height={40} src={text.signatureUrl}></Image> : null
            }
        },
        {
            title: '验收',
            align: "center",
            dataIndex: 'acDeptSignature',
            width: 55,
            render: (text: any, record: any) => (Object.keys(text)?.length > 0 ? <Image width={40} height={40} src={text.signatureUrl}></Image> : null)
        },
        {
            title: "金额",
            align: "center",
            dataIndex: 'sumTotalPrice',
            render: (text: any) => <div>{text ? text.sumPrice : ""}</div>
        },
        {
            title: "取消",
            align: "center",
            width: 50,
            // render: (text: any,record:any) =><Button danger onClick={()=>onCancelTransferOrder(record)}>取消</Button>
            render: (text: any, record: any) =>
                <Popconfirm description={(
                    <Checkbox
                        checked={dBtnChecked[record.id] ? dBtnChecked[record.id] : false}
                        onClick={(e: any) => {
                            setDBtnChecked({ ...dBtnChecked, [record.id]: e.target.checked })
                        }}>
                        {'是否取消并新增'}
                    </Checkbox>
                )} disabled={record.orderStatus === 1 || record.orderStatus === 4 || record.orderState === -99 || record.orderState === 99 ? true : false} title="确定取消吗?" onConfirm={() => orderPeration.cancel(apiId, record.id, ref.current.initData, dBtnChecked[record.id] ? dBtnChecked[record.id] : false, {
                    formName: "transferProducts",
                    type: "CANCEL",
                    setModal: setModalCancelOrder,
                    setData: setCancelOrderData,
                    orderType: 3

                })}>
                    <Button disabled={record.orderStatus === 1 || record.orderStatus === 4 || record.orderState === -99 || record.orderState === 99 ? true : false} size={"small"} type="text" danger >取消</Button>
                </Popconfirm>
        }

    ];
    /** 子表头内容 */
    const expandableColumnsHead: Array<any> = [
        {
            title: "id",
            dataIndex: "id",

            render: (text: any) => <Tooltip placement="topLeft" title={text}>{
                (typeof text !== "undefined" && text !== null) ?
                    (text.length >= 6 ? text.substring(0, 6) + '....' : text)
                    : ''
            }</Tooltip>
        },
        {
            title: "品号",
            dataIndex: "product",
            align: "center",

            render: (text: any) => (text ? text.selfId : "")
        },
        {
            title: "品名",
            dataIndex: "product",
            align: "center",

            render: (text: any) => (text?.productName)
        },
        {
            title: "单位",
            dataIndex: "product",
            align: "center",

            render: (text: any) => (text ? text.productUnit.productUnitName : "")
        },
        {
            title: "规格",
            align: "center",

            dataIndex: "productSpecifications"
        },
        {
            title: "数量",
            align: "center",

            dataIndex: "bNum"
        },
        {
            title: "单价",
            align: "center",

            dataIndex: "univalence"
        },
        {
            title: "总价格",
            align: "center",

            dataIndex: "totalPrice"
        },
        {
            title: "备注",
            align: "center",
            dataIndex: "memo"
        },
        {
            title: "输单时间",
            dataIndex: "sysDate",
            render: (text: any) => {
                return <>{text ? moment(text.createdAt).format("YYYY-MM-DD HH:mm:ss") : ""}</>
            }
        },

    ]
    useEffect(() => {

        setDate(localStorage.getItem("date"))
        localStorage.setItem("num", "0")
        if (ref.current) {
            wmService = ref.current.wmService;
        }
        const fetchData = async () => {

            httpGet(wmService,
                {
                    apiId: 'generalData',
                    apiVariable: 'printStatus',
                    apiExtend: 'showList'
                }).then(res => {
                    if (res.success) {

                        setPrintStatus(res.data)

                    }
                })
            httpGet(wmService,
                {
                    apiId: 'generalData',
                    apiVariable: 'orderStatus',
                    apiExtend: 'showList'
                }).then(res => {
                    if (res.success) {
                        //不影响原来数组数据
                        // let newData = [{ id: -1, name: "全部来源" }, ...res.data]

                        //会影响原来数组数据
                        res.data.unshift({ id: -1, name: "所有状态" })
                        setOrderStatus(res.data)

                    }
                })
        }
        fetchData()
        if (contextData?.currentEntrepotId && contextData?.refresh) {
            ref.current.initData();
            setContextData({ ...contextData, refresh: false })
        }
        window.addEventListener('keydown', (e) => orderCutKey.onKeyDown(e, ref, entrepotPrint.printOrder)); // 添加全局事件
        return () => {
            window.removeEventListener('keydown', (e) => orderCutKey.onKeyDown(e, ref, entrepotPrint.printOrder)); // 销毁
        };
    }, [contextData?.currentEntrepotId])




    // const onCancelTransferOrder = (item: any) => {

    //     setCancelTransferOrderData({ id: item.id })
    //     setModalCancelTransferOrder(true)

    // }
    // setCancelTransferOrderData
    /**关闭商品窗口并刷新数据 */
    // const onCloseProductOpen = (status: Boolean) => {
    //     setModalProductOpen(false)
    //     if (status === true) {
    //         ref.current.initData();
    //     }
    // }

    /**关闭商品窗口并刷新数据 */
    const onCloseCancelOrder = (status: Boolean) => {
        setModalCancelOrder(false)
        if (status === true) {
            ref.current.initData();
        }
    }

    /** 子表头 */
    const expandableColumns = expandableColumnsHead;
    const columns = columnsheadId
        .concat(columnsHead as []);


    const orderStatusChange = (e: any) => {
        localStorage.setItem("num", "0")
        setSelectedOrderStatus(e)
        const currentData: initDataType = {
            apiId,
            sendPageData: { orderStatus: e, printStatus: selectedPrintStatus }
        }
        ref.current.initData(currentData);
    }

    //打印状态
    const orderPrintChange = (e: any) => {
        setSelectedPrintStatus(e)
        const currentData: initDataType = {
            apiId,
            sendPageData: { printStatus: e, orderStatus: selectedOrderStatus }
        }
        ref.current.initData(currentData);
    }
    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '调拨单',
        apiId,
        showStates: true,
        columns,
        pagination: true,
        isAutomaticRefresh: true,
        EditComponent: TransferOrderEdit,
        handleEditAuthority: { orderStatus: 1 },
        handleDelectAuthority: { orderStatus: 1 },
        isLevel: true,
        levelKey: "exportEntrepot",
        //子表格
        isExpandable: true,
        expandableColumns,
        expandableDataName: 'transferProducts',
        mountDataOptions: {
            userMount: {
                isDept: true,
                isUserAreas: true
            }
        },
        sendPageData: { orderStatus: selectedOrderStatus, printStatus: selectedPrintStatus }
    };
    return (
        <>
            <label>订单状态 :  </label>
            <Select
                popupMatchSelectWidth={100}
                showSearch
                onChange={orderStatusChange}
                placeholder="状态"
                optionFilterProp="children"
                filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={getList.generalSelcetOption(orderStatus)}
            >
            </Select>
            <span> </span>
            <label>打印状态 :  </label>
            <Select
                popupMatchSelectWidth={100}
                showSearch
                onChange={orderPrintChange}
                placeholder="状态"
                options={getList.generalSelcetOption(printStatus)}
            >
            </Select>
            <span> </span>
            <label>日期 :  </label>
            <Tooltip placement="topLeft" title={"当前仅修改新建订单时间使用"}>
                <DatePicker defaultValue={dayjs(sessionStorage.getItem("date"))} onChange={setOrderDate.date} />
            </Tooltip>
            <span>   </span>
            <WebUniversalNewExEx ref={ref} props={extendProps}></WebUniversalNewExEx>

            {/* {modalProductOpen ? (<ProductEdit data={productData} closeModal={onCloseProductOpen}
            />)
                : null} */}
            {modalCancelOrder ? (<CancelOrder data={cancelOrderData} closeModal={onCloseCancelOrder}
            />)
                : null}
        </>

    )
}

// export const printOrder = async (item?: any, id?: any, initData?: any) => {

//     let newArr: Array<any> = [];
//     let socketId = localStorage.getItem("socketId")
//     let nameSpace = localStorage.getItem("nameSpace")
//     let subsystemId = localStorage.getItem("subsystemId")
//     console.log("路径", system.wsServer.url + nameSpace);
//     console.log("socketId", socketId);
//     console.log("nameSpace", nameSpace);
//     let title;
//     if (!socketId && !nameSpace && !subsystemId) return
//     if (item) {
//         title = item;
//         if (item.transferProducts) {
//             for (var i = 0; i < item.transferProducts.length; i++) {
//                 if (item.transferProducts[i].id !== "合计:") {
//                     newArr.push(item.transferProducts[i])
//                 }
//             }


//         }
//     } else {
//         let data = await fetchApi.GET(
//             wmService,
//             {
//                 apiId: "transferOrder",
//                 apiExtend: 'showSingle',
//                 apiVariable: id
//             }
//         )
//         if (data.success) {
//             title = data.data


//             for (var i = 0; i < data.data.transferProducts.length; i++) {
//                 if (data.data.transferProducts[i].id !== "合计:") {
//                     newArr.push(data.data.transferProducts[i])
//                 }
//             }
//         }
//     }




//     let printData = await fetchApi.POST(wmService, {
//         apiId: "entrepotOrder",
//         apiExtend: 'printOrder',
//         apiData: {
//             nameSpace,
//             socketId,
//             data: {
//                 ...title,
//                 transferProducts: newArr
//             },
//             type: "transferOrderPrint",
//             subsystemId
//         }
//     })
//     if (printData.success) {
//         if (initData) {
//             initData()
//         }
//     }




// }
