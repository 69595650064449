import { Button, List, Modal, Tooltip } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import systemConfig from '../../config/systematic';
import fetchApi from '../../utils/api/fetchApi';
import { SocketContext } from '../../App';
export default function BranchStoreList(props: any) {
    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;
    const [branchStoreData, setBranchStoreData] = useState<any>();

    const useContextData = useContext(SocketContext);

    const { contextData, setContextData } = useContextData;

    //显示分店弹窗
    const [branchStoreListOpen, setBranchStoreListOpen] = useState<boolean>(false);
    useEffect(() => {
        const fetchData = async () => {

            fetchApi.GET(wmService, {
                apiId: "branchStore",
                apiExtend: 'showListManage'
            }).then(res => {

                setBranchStoreData(res.data)
            })
            await onchangeCurrentBranchStore({
                id: contextData?.currentBranchStoreId,
                branchStoreName: contextData?.currentBranchStoreName,
                brandName: contextData?.currentBrandName,
                brandId: contextData?.currentBrandId,
                refresh: false
            })
        }
        fetchData()
    }, [])
    const onchangeCurrentBranchStore = async (item: any) => {
        setContextData({ ...contextData, currentBranchStoreName: item.branchStoreName, currentBranchStoreId: item.id, currentBrandName: item.brandName, currentBrandId: item.brandId, refresh: item.refresh })
        if (item.id) localStorage.setItem("currentBranchStoreId", item.id);
        if(item.branchStoreName) localStorage.setItem("currentBranchStoreName", item.branchStoreName);
        if(item.brandName) localStorage.setItem("currentBrandName", item.brandName);
        if(item.brandId) localStorage.setItem("currentBrandId", item.brandId);


        setBranchStoreListOpen(false)
    }
    return (
        <>
            <div style={{ fontSize: 15 }} onClick={() => setBranchStoreListOpen(true)} >
                <Tooltip title="点击可修改分店">
                    当前分店:<Button type="primary" size='small'>{contextData?.currentBranchStoreName ? `${contextData?.currentBrandName ? contextData?.currentBrandName : null}${contextData?.currentBranchStoreName}` : "请选择分店"}</Button> 
                </Tooltip>
            </div>

            {/**查看分店 */}
            {branchStoreListOpen ? <Modal
                title={<div style={{ fontSize: "20px" }}>请选择分店</div>}
                open={true}
                onCancel={() => setBranchStoreListOpen(false)}
                onOk={() => setBranchStoreListOpen(false)}
                footer={null}
                width={"60%"}
                centered
            >
                <List
                    size="large"
                    dataSource={branchStoreData}
                    renderItem={(item: any) => (
                        <List.Item key={item.id}>
                            <List.Item.Meta
                                title={<div style={{ fontSize: "30px" }}>{`${item.brandName}~${item.branchStoreName}`}</div>}
                            />
                            <Button type="primary" size="large" onClick={() => onchangeCurrentBranchStore({ ...item, refresh: true })}>
                                选择
                            </Button>
                        </List.Item>
                    )}
                />
            </Modal> : null}
        </>

    )
}
