import { Form, Input, InputNumber, Select } from 'antd';
import { useEffect, useRef, useState } from 'react'
import fetchApi, { httpGet } from '../../../utils/api/fetchApi';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';
import getList from '../../universal/getList';
import antMessage from '../../../utils/extend/AntdNotification';
import systemConfig from '../../../config/systematic';

//布局
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

export default function ProductEdit(props: any) {
    /**单位数据 */
    const [productUnitData, setProductUnitData] = useState<any>();

    /**区域数据 */
    const [areaData, setAreaData] = useState<any>();
    /**分类数据 */
    const [classificationWarehouseData, setClassificationWarehouseData] = useState<any>();
    const [form] = Form.useForm(); //form 
    const { data } = props;


    const ref = useRef<any>(undefined);

    const handleOk = (e: any) => {

        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();

    };
    /** 后端数据Service */
    let wmService: any = systemConfig.wmService;;


    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
        }
        const fetchData = async () => {
            httpGet(wmService, {
                apiId: "classificationWarehouse",
                apiExtend: 'showList'
            }).then(res => {

                if (res.success) {
                    setClassificationWarehouseData(res.data)
                }
            })

            httpGet(wmService, {
                apiId: "productUnit",
                apiExtend: 'showList'
            }).then(res => {
                setProductUnitData(res.data)
            })

            httpGet(wmService, {
                apiId: "area",
                apiExtend: 'showList'
            }).then(res => {
                setAreaData(res.data)
            })
            if (data.type === "NEW") {
                let areaId = localStorage.getItem("areaId")
                form.setFieldValue("areaId", areaId)
            }


        }
        fetchData()
    }, [])
    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'product',
        mountDataOptions: {
            userMount: {
                isUserLogin: true
            }
        }
    };
    const onChangeClassificationWarehouse = (e: any) => {
        if (data.type === 'NEW') {
            const findData = classificationWarehouseData.find((item: any) => item.id === e);
            if (findData) {
                fetchApi.GET(
                    wmService,
                    {
                        apiId: "product",
                        apiExtend: 'findMaxSelf',
                        apiData: {
                            warehouseCategoriesId: findData.warehouseCategoriesId
                        }
                    }
                ).then((res) => {
                    if (res.data.selfId) {
                        form.setFieldValue("selfId", Number(res.data.selfId) + 1)
                    }
                }).catch((err => {
                    antMessage('error', '查询失败');
                }))
            }

        }
    }
    const children = (isLoading: boolean) => {
        return <AntDraggableModal
            title={(data.type === 'NEW' ? '新增' : data.type === "COPY" ? "复制" : "编辑") + '产品'}
            open={true}
            okText='确定'
            cancelText='取消'
            onOk={handleOk}
            onCancel={handleCancel}
            closable={false}
            maskClosable={false}
            confirmLoading={isLoading}
        >
            <Form
                {...layout}
                form={form}
                name="Edit"
                onFinish={handleOk}
                onKeyDown={(e: any) => {
                    if (e.key === 'Enter') {
                        handleOk(e)
                    }
                }}
            >
                <Form.Item
                    label="id"
                    name="id" >
                    <Input disabled />
                </Form.Item>
                <Form.Item
                    label="专属id"
                    name="selfId"
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="默认区域"
                    name="areaId"
                    rules={[{ required: true, message: '请选择默认区域!' }]}>
                    <Select

                        showSearch
                        placeholder="请选择默认区域"
                        optionFilterProp="children"
                        filterOption={(input, option: any) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                        {getList.general(areaData, 'areaName')}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="分类"
                    name="classificationWarehouseId"
                    rules={[{ required: true, message: '分类!' }]}>
                    <Select
                        onChange={onChangeClassificationWarehouse}
                        showSearch
                        placeholder="分类"
                        optionFilterProp="children"
                        filterOption={(input, option: any) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                        {getList.general(classificationWarehouseData, 'classificationWarehouseName')}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="品名"
                    name="productName"
                    rules={[{ required: true, message: '请输入品名!' }]}>
                    <Input />
                </Form.Item>


                <Form.Item
                    label="单位"
                    name="productUnitId"
                    rules={[{ required: true, message: '单位!' }]}>
                    <Select
                        showSearch
                        placeholder="单位"
                        optionFilterProp="children"
                        filterOption={(input, option: any) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                        {getList.general(productUnitData, 'productUnitName')}
                    </Select>
                </Form.Item>

                <Form.Item
                    name={"miniRate"}
                    // label="数量"
                    label={"起率"}

                >
                    <InputNumber
                        // style={{ width: 50, marginLeft: 5 }}
                        min={0.1} max={1} placeholder="数量" />
                </Form.Item>


            </Form>
        </AntDraggableModal>

    }
    return (
        <>
            <WebEditUniversal ref={ref} props={extendProps} children={children} />

        </>
    )
}
