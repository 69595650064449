import { Col, Form, Input, InputNumber, Row, Select, Switch } from 'antd';
import { useCallback, useEffect, useRef, useState } from 'react';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';
import fetchApi from '../../../utils/api/fetchApi';
import getList from '../../../utilsTsx/universal/getList';

//布局
const layout = {
    // labelCol: { span: 6 },
    // wrapperCol: { span: 20},
};

const EditComponent = (props: any) => {

    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>(undefined);

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };
    const [wmService, setWmService] = useState<string>('');
    const [deviceTypeAssociatedModeIdData, setDeviceTypeAssociatedModeIdData] = useState<any>();
    const [defaultIdData, setDefaultIdData] = useState<any>();

    /**
     * 获取defaultId
     * @param e 
     * @param wmsUrl 后端数据地址
     */
    const getDefaultId = useCallback((e: string, wmsUrl?: string) => {
        wmsUrl = typeof wmsUrl === 'undefined' ? wmService : wmsUrl;
        switch (e) {
            case 'USER':
                fetchApi.GET(wmsUrl, {
                    apiId: 'user',
                    apiExtend: 'showList'
                }).then(res => {
                    if (res.success) {
                        setDefaultIdData(res.data);
                    }
                })
                break;
            case 'CLIENTUSER':
                fetchApi.GET(wmsUrl, {
                    apiId: 'clientUser',
                    apiExtend: 'showList'
                }).then(res => {
                    if (res.success) {
                        setDefaultIdData(res.data);
                    }
                })
                break;
            default:
                setDefaultIdData(undefined);
                break;
        }
    }, [wmService]); // 依赖项数组，确保 wmService 变化时重新创建函数

    useEffect(() => {
        /** 后端数据地址 */
        let wms: any = "";
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wms = ref.current.wmService;
            setWmService(wms);
            ref.current.row.then((res: any) => {
                if (typeof res !== 'undefined') {
                    if (res.success === true) {
                        const { associatedModeId } = res.data;
                        getDefaultId(associatedModeId, wms);
                    }
                }
            })
        }
        const fetchData = async () => {
            fetchApi.GET(wms, {
                apiId: 'generalData',
                apiVariable: 'associatedMode',
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setDeviceTypeAssociatedModeIdData(res.data);
                }
            })
        }
        fetchData()
    }, [getDefaultId])

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'deviceType'
    };

    return (
        <>
            <WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '设备类型'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={600}
            >
                <Form
                    {...layout}
                    form={form}
                    initialValues={{ "sortBy": 999 }}
                    name="Edit"
                    onFinish={handleOk}
                ><Row>
                        <Col span={24}>
                            <Form.Item
                                label="id"
                                name="id"
                                tooltip='如不输入，自动生成'>
                                <Input disabled={data.type === 'NEW' ? false : true} />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="类型"
                                name="deviceTypeName"
                                rules={[{ required: true, message: '请输入设备类型!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={10}>
                            <Form.Item
                                label="关联模式"
                                name="associatedModeId"
                                tooltip='设备的关联模式'>
                                <Select
                                    showSearch
                                    placeholder="选择关联id模式"
                                    optionFilterProp="children"
                                    onSelect={(e) => { getDefaultId(e); }}
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(deviceTypeAssociatedModeIdData)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={14}>
                            <Form.Item
                                label="默认id"
                                name="defaultId"
                                tooltip='根据关联模式获取'>
                                <Select
                                    showSearch
                                    placeholder="选择默认id"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(defaultIdData, {
                                        includeNull: true,
                                        setItemNameFun: (item: any) => {
                                            let currentId;
                                            let name;
                                            if (item) {
                                                const { id, userName, unionId, nickName } = item;
                                                if (userName) {
                                                    currentId = id;
                                                    name = userName;
                                                } else if (unionId) {
                                                    currentId = unionId;
                                                    name = nickName;
                                                }
                                            }
                                            return { id: currentId, name };
                                        }
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="设备码"
                                name="isRequiredDeviceCode"
                                tooltip='如开启，注册设备需要设备码'
                                valuePropName="checked"
                                initialValue={true}>
                                <Switch checked={true}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>

                        <Col span={5}>
                            <Form.Item
                                label="认证"
                                name="isDefaultAuthenticate"
                                tooltip='如开启，注册的设备需要认证'
                                valuePropName="checked"
                                initialValue={true}>
                                <Switch checked={true}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="人工检验"
                                name="isManualInspection"
                                tooltip='如开启，注册的设备与帐号关联需要人工检验'
                                valuePropName="checked"
                                initialValue={true}>
                                <Switch checked={true}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="排序"
                                name="sortBy">
                                <InputNumber min={1} />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                <Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
};

export default EditComponent;