import AntDraggableModal from "../../../utils/extend/AntDraggableModal";
import { Descriptions } from 'antd';
import { useEffect, useState } from 'react'
import fetchApi from '../../../utils/api/fetchApi';
import system from "../../../config/systematic";

export default function CheckIngredient(props: any) {

    //console.log(props);
    const { data, closeModal } = props;

    const [rowsData, setRowsData] = useState<any>();

    /** 后端数据Service */
    let wmService = system.wmService;

    /**数据 */
    useEffect(() => {

        const fetchData = async () => {

            fetchApi.GET(wmService, {
                apiId: "menuWarehouseIngredient",
                apiExtend: "showSingle",
                apiVariable: data.id,
                apiData: {
                    mountDataOptions: {
                        userMount: {
                            isBranchStore: true
                        }
                    }
                }
            }).then(async res => {
                if (res.success) {

                    let newDatas: any = [];
                    newDatas.push(
                        {
                            label: '菜式名称',
                            span: 2,
                            children: res.data.menuWarehouseSpecId ? res.data.menuWarehouse.foodName : res.data.feastTeamMenuWarehouse.foodName,
                        },
                        {
                            label: '规格',
                            children: res.data.menuWarehouseSpecId ? res.data.menuWarehouseSpec.menuWarehouseSpecName : res.data.productUnit.productUnitName,
                        },
                        {
                            label: '售价',
                            children: res.data.sellPrice + " 元",
                        },
                        {
                            label: '成本价',
                            children: res.data.costPrice + " 元",
                        },
                        {
                            label: '毛利率',
                            children: res.data.grossProfitRatio + " %",
                        },
                        {
                            span: 'filled',
                            styles: { label: { border: '0px', backgroundColor: "#1f1f1f" } },
                            children: <p> </p>,
                        },
                        {
                            label: '名称',
                            styles: { content: { backgroundColor: "#282828" } },
                            children: "定量 + 单位",
                        },
                        {
                            label: '起率',
                            styles: { content: { backgroundColor: "#282828" } },
                            children: "总价",
                        },
                        {
                            label: '金额',
                            styles: { content: { backgroundColor: "#282828", width: 250 } },
                            children: "备注",
                        },
                    )
                    // setIngredientBaseData(res.data);

                    let new_arr: any = [];
                    let str_arr: any = [];

                    /** 点击编辑后，根据数据的内容来进行分析，筛选出之前有数据的口味 */
                    for (var i_data = 0, len_data = res.data.rowsDetail.length; i_data < len_data; i_data++) {
                        if (res.data.rowsDetail[i_data].length === 0) {
                            str_arr.push(i_data);
                        }
                        else {
                            str_arr.push(999);
                        }
                    }

                    await fetchApi.GET(wmService, {
                        apiId: 'generalData',
                        apiVariable: 'ingredient',
                        apiExtend: 'showList'
                    }).then(ress => {
                        new_arr = ress.data.map((item: any) => ({
                            id: item.id,                      //设置字段，在form.list下的form.item中指定的字段值
                            name: item.name,
                            visible: str_arr[item.id] === item.id ? false : item.visible
                        }));
                    });

                    await analyzeData(newDatas, new_arr, res.data.rowsDetail);
                }
            })
        }
        fetchData()
    }, [])

    const analyzeData = (array: any, ingredientData: any, ingredientBaseData: any) => {
        for (var i_data = 0, len_data = ingredientData.length; i_data < len_data; i_data++) {
            if (ingredientData[i_data].visible === true) {
                array.push(
                    {
                        label: ingredientData[i_data].name,
                        span: 'filled',
                        styles: { label: { border: '0px', backgroundColor: "#1f1f1f" } },
                        children: <p> </p>,
                    },
                )

                for (var j = 0, len = ingredientBaseData.length; j < len; j++) {
                    for (var k = 0, k_len = ingredientBaseData[j].length; k < k_len; k++) {
                        if (i_data === ingredientBaseData[j][k].ingredientId) {
                            array.push(
                                {
                                    label: ingredientBaseData[j][k].product.productName,
                                    styles: { content: { backgroundColor: "#282828" } },
                                    children: ingredientBaseData[j][k].amount + " " + ingredientBaseData[j][k].productUnit.productUnitName,
                                },
                                {
                                    label: ingredientBaseData[j][k].yieldRatio + " %",
                                    styles: { content: { backgroundColor: "#282828" } },
                                    children: ingredientBaseData[j][k].unitPrice + " 元",
                                },
                                {
                                    label: ingredientBaseData[j][k].total + " 元",
                                    styles: { content: { backgroundColor: "#282828" } },
                                    children: ingredientBaseData[j][k].memo,
                                },
                            )
                        }
                    }
                }
            }
        }
        setRowsData(array);
    }

    const handleOk = (e: any) => {
        closeModal(true);
    };

    return (
        <>
            <AntDraggableModal
                title={data.brandName + " " + data.branchStoreName + ' 配料表'}
                open={true}
                okText='关闭'
                // cancelText={stringHandle.isUndefined()}
                cancelButtonProps={{ style: { display: "none" } }}
                onOk={handleOk}
                //onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={1100}>
                <Descriptions bordered items={rowsData} />
            </AntDraggableModal>
        </>
    )
}