import PrinterEdit from './PrinterEdit';
import WebUniversal, { initDataType, WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';
import moment from 'moment';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebUniversalNewEx from '../../system/WebUniversalNewEx';
import { useContext, useEffect, useRef } from 'react';
import { Switch } from 'antd';
import { SocketContext } from '../../../App';
//组件 
const Printer = (props: any) => {

    /** api服务id */
    const apiId = 'printer';
    const ref = useRef<any>(undefined);
    const useContextData = useContext(SocketContext);
    const { contextData, setContextData } = useContextData;
    const { deptId, closeModal, isModel } = props;
    useEffect(() => {
        if (contextData?.currentBranchStoreId) {
            if (contextData?.refresh) {
                onRefresh({ branchStoreId: contextData?.currentBranchStoreId })
            }
        }
    }, [contextData?.currentBranchStoreId])
    //表头
    const columnsHead = [
        {
            title: '打印机名称',
            dataIndex: 'printerName',
        },
        {
            title: 'window打印机名称',
            dataIndex: 'windowsPrinterName',
        },

        {
            title: 'IP地址',
            dataIndex: 'ipAddress',
        },
        {
            title: '分店',
            dataIndex: 'branchStore',
            render: (text: any, record: any) => {
                return (
                    <div>{record.branchStore.branchStoreName}</div>
                )
            }
        },
        {
            title: "边距",
            dataIndex: "isMargins",
            render: (text: any, record: any) => (
                <Switch checkedChildren="是" unCheckedChildren="否"
                    checked={text} key={record.id}
                    onChange={(e) => ref.current.handleChangeOne(record.id, 'isMargins', e, apiId)}
                />
            )
        },
        {
            title: '边距类型',
            dataIndex: 'marginType',
        },

        {
            title: '上',
            dataIndex: 'top',
        },
        {
            title: '下',
            dataIndex: 'bottom',
        },
        {
            title: '左',
            dataIndex: 'left',
        },
        {
            title: '右',
            dataIndex: 'right',
        },
        {
            title: '最后修改人',
            dataIndex: 'userId',
            render: (text: any, record: any) => {
                return (
                    <div>{record.user.userName}</div>
                )
            }
        },
        {
            title: '最后修改时间',
            dataIndex: 'address',
            render: (text: any, record: any) => {
                return (
                    <div>{moment(record.sysDate.updatedAt).format('YYYY-MM-DD HH:mm:ss')}</div>
                )
            }
        },
        {
            title: '排序',
            dataIndex: 'sortBy',
        },
    ];
    const onRefresh = (item: any) => {

        const { branchStoreId } = item
        // setSbId(null)
        // setRoomIds([])
        const currentData: initDataType = {
            apiId,
            sendPageData:{branchStoreId }
        }

        ref.current.initData(currentData);

        setContextData({ ...contextData, refresh: false })
    }
    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '打印机',
        apiId,
        columns,
        sendPageData: { deptId, branchStoreId: contextData?.currentBranchStoreId },
        showStates: true,
        pagination: true,
        EditComponent: PrinterEdit,
        mountDataOptions: {
            userMount: {
                isDept: true,
            }
        },
    };
    const handleOk = (e: any) => {
        closeModal(true);
    };
    return (

        <>
            {isModel ? <AntDraggableModal
                title={'打印机'}
                open={true}
                okText='确定'
                cancelButtonProps={{ style: { display: "none" } }}
                onOk={handleOk}
                closable={false}
                maskClosable={false}
                width={800}>
                <WebUniversalNewEx props={extendProps} ref={ref}></WebUniversalNewEx>
            </AntDraggableModal> : <WebUniversalNewEx ref={ref} props={extendProps}></WebUniversalNewEx>}


        </>
    )
}

export default Printer;