import { Button, Modal, Space } from 'antd'
import CastOrderDrawe from './CastOrderDrawe';
import { useState } from 'react';

export default function OrderList(props: any) {
    const { data, closeModal } = props;

    const { rows } = data;



    const [castOrderDraweData, setCastOrderDraweData] = useState<any>();
    const [castOrderDraweOpen, setCastOrderDraweOpen] = useState<boolean>(false);
    const handleOk = (e: any) => {

        closeModal(true)

    }
    const handleCancel = (e: any) => {
        closeModal(false)

    }
    //关闭收银订单详情
    const onCloseCastOrderDraw = async (status: boolean) => {
        setCastOrderDraweOpen(false);
        if (status) {
            await closeModal(true)


        }
    }

    /**打开收银订单详情 */
    const handleCastOrderDrawe = (dataEx: any) => {
        setCastOrderDraweOpen(true)
        setCastOrderDraweData({ id: dataEx.id, tempRoomId: data.tempRoomId, timeTypeId: data.timeTypeId, type: "EDIT", isIo: true })
    }
    return (
        <>
            <Modal

                title={"请选择订单"}
                open={true}
                onCancel={handleCancel}
                onOk={handleOk}
                footer={null}
                width={"70%"}
            >
                <ul>
                    {rows.map((order: any) => (
                        <li key={order.id}>
                            <Space>
                                <span>{`${order.id}`}</span>
                                <span>{`${order.orderDate}`}</span>
                                <span>{`${order.diningName}`}</span>
                                {order.isTarry ? <span style={{ color: "red" }}>滞留中:是</span> :  <span>滞留中:否</span>}
                                {order.isReverseClosing ? <span style={{ color: "red" }}>反结账中:是</span> : <span >反结账中:否</span>}

                                {/* <span style={{color:"red"}}>{`滞留中:${order.isTarry?"是":"否"}`}</span> */}
                                {/* <span style={{color:"red"}}>{`反结账中:${order.isReverseClosing}`}</span> */}


                                <Button onClick={() => handleCastOrderDrawe({ id: order.id })}>
                                    选择
                                </Button>
                            </Space>


                        </li>
                    ))}
                </ul>
            </Modal>

            {/**收银订单详情 */}
            {
                castOrderDraweOpen ? (<CastOrderDrawe data={castOrderDraweData} closeModal={onCloseCastOrderDraw}
                />)
                    : null
            }
        </>



    )
}
