import React, { useContext, useEffect, useRef, useState } from 'react';
import RoomTypeEdit from './RoomTypeEdit';
import WebUniversal, { initDataType, WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';
import RoomTypeUploadFile from './RoomTypeUploadFile';
import { Button } from 'antd';
import PrintConfiguration from '../../basic/printManagement/PrintConfiguration';
import { SocketContext } from '../../../App';

//组件 RoomType 
const RoomType = (props: any) => {

  /** api服务id */
  const apiId = 'roomType';
  const ref = useRef<any>(undefined);
  const [roomTypeId, setRoomTypeId] = useState<string>();
  const [modalPrintConfiguration, setModalPrintConfiguration] = useState(false);
  const useContextData = useContext(SocketContext);
  const { contextData, setContextData } = useContextData;

  useEffect(() => {
    if (contextData?.currentBranchStoreId && contextData?.refresh) {
      const currentData: initDataType = {
        apiId,
        sendPageData: {branchStoreId: contextData?.currentBranchStoreId }
      }
      ref.current.initData(currentData);

      setContextData({ ...contextData, refresh: false })
      // onRefresh({ branchStoreId: contextData?.currentBranchStoreId })
    }
  }, [contextData?.currentBranchStoreId])
  //表头
  const columnsHead = [
    {
      title: '分店',
      dataIndex: 'branchStore',
      render: (text: any) => (text ? text.branchStoreName : "")

    },
    // menuHeads_Name
    {
      title: '菜谱头',
      dataIndex: 'menuHeads_Name',
    },
    {
      title: '最低容纳人数',
      dataIndex: 'containMinNum',

    },
    {
      title: '最高容纳人数',
      dataIndex: 'containMaxNum',

    },
    {
      title: '房间类型',
      dataIndex: 'roomType',
    },
    {
      title: '别名',
      dataIndex: 'anotherName',
    },
    {
      title: '最低消费',
      dataIndex: 'minConsumption',
    },
    {
      title: '预定金',
      dataIndex: 'deposit',
    },
    {
      title: '点餐模式',
      dataIndex: 'orderingModeName',
    },
    {
      title: '线上支付',
      dataIndex: 'isOnlinePayment',
      render:(text:any)=>(text?"是":"否")
    },
    {
      title: '打印配置',
      render: (text: any, record: any) => {
        return <Button onClick={() => handlePrintConfiguration(record)} size={"small"} type="primary" >打印配置</Button>
      }
    }

  ];
  const handlePrintConfiguration = (record: any) => {
    setRoomTypeId(record.id)
    setModalPrintConfiguration(true)
  }

  const closePrintConfiguration = (status: boolean) => {
    setModalPrintConfiguration(false)
    if (status) {

    }
  }

  const columns = columnsheadId
    .concat(columnsHead as []);

  let extendProps: WUExtendPropsType = {
    ...props,
    titleName: '房间类型',
    apiId,
    columns,
    showStates: true,
    pagination: true,
    showReserve: true,
    showImage: true,
    showUploadFile: true,
    showDeposit: true,
    EditComponent: RoomTypeEdit,
    arrayFilesName: 'roomTypeFiles',
    sendPageData: {branchStoreId: contextData?.currentBranchStoreId },
    mountDataOptions: {
      userMount: {
        isDept: true,
      }
    },
    UploadFileComponent: RoomTypeUploadFile,
  };

  return (
    <>
      <WebUniversal props={extendProps} ref={ref} />
      {modalPrintConfiguration ? (<PrintConfiguration
        closeModal={closePrintConfiguration}
        roomTypeId={roomTypeId}
        isModel={true}
      ></PrintConfiguration>) : null}
    </>
  )
}

export default RoomType;
