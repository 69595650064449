import React, { useContext, useEffect, useRef, useState } from 'react'
import { columnsheadId } from '../../universal/columnshead';
import WebUniversalNewExEx, { initDataType, WUExtendPropsType } from '../../system/WebUniversalNewExEx';
import PurchaseOrderEdit from './PurchaseOrderEdit';
import moment from 'moment';
import { Button, Select, Tooltip, Image, DatePicker, Popconfirm, Checkbox } from 'antd';
import { httpGet } from '../../../utils/api/fetchApi';
import systemConfig from '../../../config/systematic';
import getList from '../../universal/getList';
import ProductEdit from './ProductEdit';
import orderCutKey from '../../../generalMethod/OrderCutKey';
import setOrderDate from '../../../generalMethod/setOrderDate';
import dayjs from 'dayjs';
import entrepotPrint from '../../../generalMethod/entrepotPrint';
import orderPeration from '../../../generalMethod/orderPeration';
import CancelOrder from './CancelOrder';
import { SocketContext } from '../../../App';

let wmService: any = systemConfig.wmService;

export default function PurchaseOrder(props: any) {


    /** api服务id */
    const apiId = 'purchaseOrder';
    const ref = useRef<any>(undefined);
    /** 后端数据wmService */

    let initData: any = ref.current?.initData;

    /**订单状态数据 */
    const [orderStatus, setOrderStatus] = useState<any>()

    /**选中的收货单状态数据 */
    const [selectedOrderStatus, setSelectedOrderStatus] = useState<any>(-1);

    //删除按纽的checkBox是否checked
    let [dBtnChecked, setDBtnChecked] = useState<any>({});
    /**打开取消订单窗口 */
    const [modalCancelOrder, setModalCancelOrder] = useState<boolean>(false);
    /**打开商品窗口 */
    const [modalProductOpen, setModalProductOpen] = useState<boolean>(false);

    /**传递商品窗口的数据 */
    const [productData, setProductData] = useState<any>();
    /**订单打印状态数据 */
    const [printStatus, setPrintStatus] = useState<any>()
    /**传递取消订单窗口的数据 */
    const [cancelOrderData, setCancelOrderData] = useState<any>();
    /**选中的调拨单状态数据 */
    const [selectedPrintStatus, setSelectedPrintStatus] = useState<any>(-1);
    const useContextData = useContext(SocketContext);
    const { contextData, setContextData } = useContextData;

    useEffect(() => {


        localStorage.setItem("num", "0")

        if (ref.current) {


            wmService = ref.current.wmService;
            initData = ref.current.initData;
        }
        const fetchData = async () => {
            httpGet(wmService,
                {
                    apiId: 'generalData',
                    apiVariable: 'printStatus',
                    apiExtend: 'showList'
                }).then(res => {
                    if (res.success) {
                        setPrintStatus(res.data)
                    }
                })
            httpGet(wmService,
                {
                    apiId: 'generalData',
                    apiVariable: 'orderStatus',
                    apiExtend: 'showList'
                }).then(res => {
                    if (res.success) {
                        //不影响原来数组数据
                        // let newData = [{ id: -1, name: "全部来源" }, ...res.data]
                        //会影响原来数组数据
                        res.data.unshift({ id: -1, name: "所有状态" })
                        setOrderStatus(res.data)
                    }
                })
        }
        fetchData()
        if (contextData?.currentEntrepotId && contextData?.refresh) {
            ref.current.initData();
            setContextData({ ...contextData, refresh: false })
        }
        window.addEventListener('keydown', (e) => orderCutKey.onKeyDown(e, ref, entrepotPrint.printOrder)); // 添加全局事件
        return () => {
            window.removeEventListener('keydown', (e) => orderCutKey.onKeyDown(e, ref, entrepotPrint.printOrder)); // 销毁
        };
    }, [contextData?.currentEntrepotId])
    //表头
    const columnsHead = [

        {
            title: "部门",
            dataIndex: "dept",
            render: (text: any) => <div style={{ "fontSize": 12.5 }}>
                {text ? text.deptName : ""}
            </div>,
            align: "center"
        },
        {
            title: '仓库',
            dataIndex: 'entrepot',
            render: (text: any) => <div style={{ "fontSize": 12.5 }}>{text ? text.entrepotName : ""}</div>,
            align: "center"

        },
        {
            title: '采购商',
            dataIndex: 'buyer',
            render: (text: any) => <div style={{ "fontSize": 12.5 }}>{text ? text.buyerName : ""}</div>,
            align: "center"

        },
        {
            title: "日期",
            dataIndex: "sysDate",
            align: "center",
            width: 80,
            render: (val: any, record: any) =>
                <span style={{ "fontSize": 12.5 }}>
                    {moment(val.createdAt).format('YYYY-MM-DD')}
                </span>,
        },
        {
            title: '流程',
            dataIndex: 'totalOrderProcessName',
            align: "center",
            width: 70,
            render: (text: any, record: any) => <div style={record.orderState === -99 ? { color: "red", "fontSize": 12.5 } : { "fontSize": 12.5 }}>{text ? text : ""}</div>
        },
        {
            title: '订单状态',
            dataIndex: 'orderStatusName',
            align: "center",
            width: 75,
            render: (text: any, record: any) => {
                return <div style={record.orderStatus === 4 ? { color: "red", "fontSize": 12.5 } : { "fontSize": 12.5 }}>{text ? text : ""}</div>
            }
        },
        {
            title: '制表',
            dataIndex: 'userLogin',
            render: (text: any) => (text ? text.userName : ""),
            align: "center",
            width: 60,
        },
        {
            title: '印数',
            dataIndex: 'printNumber',
            align: "center",
            width: 50,
        },
        {
            title: '打印',
            dataIndex: 'print',
            align: "center",
            width: 50,
            render: (text: any, record: any) => (<Button style={record.printNumber >= 1 ? { "backgroundColor": "yellow", "color": "black" } : {}} disabled={record.orderState === 99 && record.orderStatus === 3 ? false : true} size='small' type='primary' onClick={() => entrepotPrint.printOrder("purchaseOrderPrint", "purchaseProducts", apiId, record)}>打印</Button>)
        },
        {
            title: '验收',
            dataIndex: 'acDeptSignature',
            align: "center",
            width: 55,
            render: (text: any, record: any) => (Object.keys(text).length > 0 ? <Image width={40} height={40} src={text.signatureUrl}></Image> : null)
        },
        {
            title: "金额",
            align: "center",
            dataIndex: 'sumTotalPrice',
            render: (text: any) => <div>{text ? text.sumPrice : ""}</div>
        },
        {
            title: "取消",
            align: "center",
            width: 50,
            // render: (text: any,record:any) =><Button danger onClick={()=>onCancelTransferOrder(record)}>取消</Button>
            render: (text: any, record: any) =>
                <Popconfirm description={(
                    <Checkbox
                        checked={dBtnChecked[record.id] ? dBtnChecked[record.id] : false}
                        onClick={(e: any) => {
                            setDBtnChecked({ ...dBtnChecked, [record.id]: e.target.checked })
                        }}>
                        {'是否取消并新增'}
                    </Checkbox>
                )} disabled={record.orderStatus === 1 || record.orderStatus === 4 || record.orderState === -99 ? true : false} title="确定取消吗?" onConfirm={() => orderPeration.cancel(apiId, record.id, ref.current.initData, dBtnChecked[record.id] ? dBtnChecked[record.id] : false, {
                    formName: "purchaseProducts",
                    type: "CANCEL",
                    setModal: setModalCancelOrder,
                    setData: setCancelOrderData,
                    orderType: 2

                })}>
                    <Button disabled={record.orderStatus === 1 || record.orderStatus === 4 || record.orderState === -99 ? true : false} size={"small"} type="text" danger >取消</Button>
                </Popconfirm>
        }
    ];



    /** 子表头内容 */
    const expandableColumnsHead: Array<any> = [
        {
            title: "id",
            dataIndex: "id",

            render: (text: any) => <Tooltip placement="topLeft" title={text}>{
                (typeof text !== "undefined" && text !== null) ?
                    (text.length >= 6 ? text.substring(0, 6) + '....' : text)
                    : ''
            }</Tooltip>
        },
        {
            title: "品号",
            dataIndex: "product",
            render: (text: any) => (text ? text.selfId ? text.selfId : "" : "")
        },

        {
            title: "品名",
            dataIndex: "product",
            render: (text: any) => {
                return text?.productName
            }
        },
        {
            title: "单位",
            dataIndex: "product",
            render: (text: any) => (text ? text.productUnit.productUnitName : "")
        },
        {
            title: "规格",
            dataIndex: "productSpecifications"
        },
        {
            title: "数量",
            dataIndex: "bNum",
            render: (text: any) => (text || text === Number(0) ? text.toFixed(2) : undefined)
        },
        {
            title: "单价",
            dataIndex: "univalence",
            render: (text: any) => (text || text === Number(0) ? text.toFixed(2) : undefined)
        },
        {
            title: "总价格",
            dataIndex: "totalPrice",
            render: (text: any) => (text || text === Number(0) ? Number(text).toFixed(2) : undefined)
        },
        {
            title: "备注",
            dataIndex: "memo"
        },
        {
            title: "输单时间",
            dataIndex: "sysDate",
            render: (text: any) => {
                return <>{text ? moment(text.createdAt).format("YYYY-MM-DD HH:mm:ss") : ""}</>
            }
        },

        // {
        //     title: "操作",
        //     render: (_text: any, record: any) => {
        //         // console.log("record", record);
        //         return <>
        //             {record.id === "合计:" ? null : <Button size="small" onClick={() => onOpenProduct(record, "purchaseProduct", "收货商品", "EDIT")}>编辑</Button>}

        //         </>
        //     }
        // },
    ]
    /** 子表头 */
    const expandableColumns = expandableColumnsHead;

    const columns = columnsheadId
        .concat(columnsHead as []);






    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '收货订单',
        apiId: 'purchaseOrder',
        showStates: true,
        columns,
        pagination: true,
        isAutomaticRefresh: true,
        //  showDelete:false,
        EditComponent: PurchaseOrderEdit,
        handleEditAuthority: { orderStatus: 1 },
        handleDelectAuthority: { orderStatus: 1 },
        mountDataOptions: {
            userMount: {
                isDept: true,
                isUserAreas: true
            }
        },
        isLevel: true,
        levelKey: "entrepot",

        //子表格
        isExpandable: true,
        expandableColumns,
        expandableDataName: 'purchaseProducts',
        sendPageData: { orderStatus: selectedOrderStatus, printStatus: selectedPrintStatus }
    };

    const findStringById = (str: string, id: string) => {
        // 使用split方法将字符串按'#'分割成多个部分
        const parts = str.split('#');
        // 遍历每个部分，查找以输入的id开头，后面紧跟'@'的字符串
        for (let part of parts) {
            const [prefix, note] = part.split('@');
            // 如果找到了以id开头的部分，并且它正好是id（没有多余字符），则返回note
            if (prefix === id) {
                return note;
            }
        }
        // 如果没有找到完全匹配的id，返回null
        return null;
    }
    const orderStateChange = (e: any) => {
        localStorage.setItem("num", "0")
        setSelectedOrderStatus(e)
        const currentData: initDataType = {
            apiId,
            sendPageData: { orderStatus: e, printStatus: selectedPrintStatus }
        }
        ref.current.initData(currentData);
    }
    //打印状态
    const orderPrintChange = (e: any) => {
        setSelectedPrintStatus(e)
        const currentData: initDataType = {
            apiId,
            sendPageData: { printStatus: e, orderStatus: selectedOrderStatus }
        }
        ref.current.initData(currentData);
    }
    /**打开商品数据 */
    // const onOpenProduct = (item: any, apiId: string, title: any, type: any) => {
    //     if (type === "NEW") {
    //         setProductData({ id: item.id, apiId, title, type, orderName: "purchaseOrderId" })
    //     }
    //     if (type === "EDIT") {
    //         setProductData({ id: item.id, apiId, title, type })
    //     }
    //     setModalProductOpen(true)
    // }
    /**关闭商品窗口并刷新数据 */
    const onCloseProductOpen = (status: Boolean) => {
        setModalProductOpen(false)
        if (status === true) {

            ref.current.initData();
        }
    }

    /**关闭商品窗口并刷新数据 */
    const onCloseCancelOrder = (status: Boolean) => {
        setModalCancelOrder(false)
        if (status === true) {
            ref.current.initData();
        }
    }

    // const [aaOpen, setAaOpen] = useState<boolean>(false)
    // const aa = () => {
    //     setAaOpen(true)
    // }
    return (
        <>
            {/* <Button onClick={aa}>菜品处理</Button> */}
            <label>订单状态 :  </label>
            <Select
                popupMatchSelectWidth={100}
                showSearch
                onChange={orderStateChange}
                placeholder="状态"
                optionFilterProp="children"
                filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={getList.generalSelcetOption(orderStatus, "name")}
            >
            </Select>
            <span>   </span>
            <label>打印状态 :  </label>
            <Select
              popupMatchSelectWidth={100}
                showSearch
                onChange={orderPrintChange}
                placeholder="状态"
                options={getList.generalSelcetOption(printStatus)}
            >
            </Select>
            <span> </span>
            <label>订单日期 :  </label>
            <Tooltip placement="topLeft" title={"当前仅修改新建订单时间使用"}>
                <DatePicker defaultValue={dayjs(sessionStorage.getItem("date"))} onChange={setOrderDate.date} />
            </Tooltip>
            <span>   </span>
            <WebUniversalNewExEx ref={ref} props={extendProps}></WebUniversalNewExEx>

            {modalProductOpen ? (<ProductEdit data={productData} closeModal={onCloseProductOpen}
            />)
                : null}
            {modalCancelOrder ? (<CancelOrder data={cancelOrderData} closeModal={onCloseCancelOrder}
            />)
                : null}
        </>

    )
}
