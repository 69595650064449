import React, { useContext, useEffect, useState } from 'react';
import { Alert, Col, Form, Input, InputNumber, Radio, Row, Select, Space, Switch } from 'antd';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import system from "../../../config/systematic";
import fetchApi from '../../../utils/api/fetchApi';
import getList from '../../universal/getList';
import antMessage from '../../../utils/extend/AntdNotification';
import objectHandle from '../../../utils/baseLib/objectHandle';
import { SocketContext } from '../../../App';
import { showCustomNotification, useNotificationInstance } from '../../../utils/extend/AntdNotificationEx';

const { TextArea } = Input;

export default function Synchronize(props: any) {
    const notificationApi = useNotificationInstance()

    const [form] = Form.useForm(); //form 

    const { closeModal, data } = props;

    /**单位数据 */
    const [productUnitData, setProductUnitData] = useState<any>();

    /**菜式仓库大分类数据 */
    const [menuWarehouseCategoryData, setMenuWarehouseCategoryData] = useState<any>();

    /**菜式仓库小分类数据 */
    const [menuWarehouseTypeData, setMenuWarehouseTypeData] = useState<any>();

    /**烹调方法数据 */
    const [cookMethodData, setCookMethodData] = useState<any>();

    /**地区数据 */
    const [areaData, setAreaData] = useState<any>();

    /**记录选择前的菜式仓库大分类 */
    const [defaultCategoryId, setDefaultCategoryId] = useState<any>();

    /**小分类的状态 */
    const [disable, setDisable] = useState<any>(true);

    /**创作年份的文本框状态 */
    const [createdDisable, setCreatedDisable] = useState<any>(true);

    /**菜式仓数据 */
    const [menuWarehouseData, setMenuWarehouseData] = useState<any>([]);


    /**选择关联菜式数据 */
    const [selectedData, setSelectedData] = useState<any>();

    /**是否能够再选择关联菜式 */
    const [isSelect, setIsSelect] = useState<any>(false);

    // 菜谱头数据
    const [menuHeadBaseData, setMenuHeadBaseData] = useState<any>();
    const [menuHeadData, setMenuHeadData] = useState<any>();

    // 同步模式
    const [synchronizeStyle, setSynchronizeStyle] = useState<any>(0);

    /**菜品数据 */
    const [menuSpecData, setMenuSpecData] = useState<any>();

    /**特殊规格是否有数据 */
    const [specialState, setSpecialState] = useState<any>();

    /**用餐性质数据 */
    const [sceneData, setSceneData] = useState<any>();

    /**扩展分类数据 */
    const [expandCategoryData, setExpandCategoryData] = useState<any>();

    /**后端数据wmService */
    const wmService = system.wmService;

    const useContextData = useContext(SocketContext);
    const { contextData } = useContextData;

    useEffect(() => {

        const fetchData = async () => {

            // form.setFieldsValue({ branchStoreId: localStorage.getItem("currentBranchStoreId") });
            form.setFieldsValue({ branchStoreId: contextData?.currentBranchStoreId });

            fetchApi.GET(wmService, {
                apiId: "menuWarehouseCategory",
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setMenuWarehouseCategoryData(res.data);
                }
            });

            fetchApi.GET(wmService, {
                apiId: "menuWarehouseType",
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setMenuWarehouseTypeData(res.data);
                }
            });

            fetchApi.GET(wmService, {
                apiId: "cookMethod",
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setCookMethodData(res.data);
                }
            });

            if (data.type !== 'NEW') {
                setDisable(false);
            }

            fetchApi.GET(wmService, {
                apiId: "productUnit",
                apiExtend: 'showList'
            }).then(res => {
                setProductUnitData(res.data);
            });

            fetchApi.GET(wmService, {
                apiId: "area",
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setAreaData(res.data);
                }
            });

            fetchApi.GET(wmService, {
                apiId: 'scene',
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setSceneData(res.data);
                }
            });

            fetchApi.GET(wmService, {
                apiId: 'expandCategory',
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setExpandCategoryData(res.data);
                }
            });

            fetchApi.GET(wmService, {
                apiId: "menuHead",
                apiExtend: 'showList',
                apiData: {
                    // branchStoreId: localStorage.getItem("currentBranchStoreId"),
                    branchStoreId: contextData?.currentBranchStoreId,
                    mountDataOptions: {
                        userMount: {
                            isDept: true
                        }
                    },
                }
            }).then(res => {
                if (res.success) {
                    newMenuHeadData(res.data, setMenuHeadBaseData, true);
                    newMenuHeadData(res.data, setMenuHeadData, false);
                }
            });

            formListData(data.branchStoreMenuWarehouseSpecRow);
            form.setFieldsValue({ 'key': data.key });
            form.setFieldsValue({ 'foodName': data.foodName });
            form.setFieldsValue({ 'foodNameEn': data.foodNameEn });
            form.setFieldsValue({ 'description': data.description });
            form.setFieldsValue({ 'introduction': data.introduction });
            form.setFieldsValue({ 'branchStoreMenuWarehouseId': data.branchStoreMenuWarehouseId });

            if (data.path === 'BranchStoreMenuWarehouseSpec') {
                setSynchronizeStyle(1);
            }

            if (data.key === 'RelatePrinter') {
                form.setFieldsValue({ 'menuSpecSpecialId': data.menuSpecSpecialId });
                form.setFieldsValue({ 'setMealRelationId': data.setMealRelationId });
                form.setFieldsValue({ 'setMealRelationStr': data.setMealRelationStr });
                form.setFieldsValue({ 'spareId': data.spareId });
                setSynchronizeStyle(3);
            }

            if (data.key === 'ToMenuSpec' || data.key === 'ToMenuWarehouse') {
                fetchApi.GET(wmService, {
                    apiId: "branchStoreMenuWarehouse",
                    apiExtend: "showSingle",
                    apiVariable: data.branchStoreMenuWarehouseId
                }).then(res => {
                    if (res.success) {
                        let state: any = true;
                        for (var i = 0; i < res.data.branchStoreMenuWarehouseSpecs.length; i++) {
                            if (res.data.branchStoreMenuWarehouseSpecs[i].isSpecial) {
                                if (res.data.branchStoreMenuWarehouseSpecs[i].branchStoreMenuWarehouseSpecSpecials.length === 0) {
                                    state = false;
                                }
                            }
                        }
                        setSpecialState(state);
                    }
                });
            }
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const newMenuHeadData = async (rows: any, setData: any, key: any) => {
        let newSelectData: Array<any> = [];
        for (var j = 0; j < rows.length; j++) {
            const res = await fetchApi.GET(wmService, {
                apiId: 'menu',
                apiExtend: 'checkMenu',
                apiData: {
                    branchStoreMenuWarehouseId: data.branchStoreMenuWarehouseId,
                    menuHeadId: rows[j].id
                }
            })
            newSelectData.push({
                value: rows[j].id,
                label: rows[j].menuHeadName,
                disabled: res.data.length > 0 ? key : !key
            })
        }
        setData(newSelectData);
    }

    //formList数据初始状态
    const formListData = async (rows: any) => {

        //自定义formList数据初始状态
        let newData: Array<any> = [];
        let formName = "specIds";

        if (rows) {
            for (var i = 0; i < rows.length; i++) {
                if (!rows[i].isSpecial) {
                    newData.push({
                        branchStoreMenuWarehouseSpecId: rows[i].id,
                        branchStoreMenuWarehouseSpecName: rows[i].branchStoreMenuWarehouseSpecName,
                        menuWarehouseSpecId: rows[i].menuWarehouseSpecId
                    })
                }
            }
            form.setFieldsValue({ [formName]: newData });
        }
    }

    //选择关联菜式后查询数据
    const selectData = async (e: any) => {
        fetchApi.GET(wmService, {
            apiId: 'menuWarehouse',
            apiExtend: 'showSingle',
            apiVariable: e
        }).then(res => {
            newSelectData(res.data.menuWarehouseSpecs, undefined, setSelectedData);
            setIsSelect(true);
        })
    }

    const newSelectData = async (rows: any, sendData: any, setData: any) => {
        let newData: Array<any> = [];
        if (rows === undefined) {
            for (var i = 0; i < selectedData.length; i++) {
                newData.push({
                    value: selectedData[i].value,
                    label: selectedData[i].label,
                    selected: selectedData[i].disabled ? (selectedData[i].selected === sendData.key ? selectedData[i].value === sendData.id ? sendData.key : '' : selectedData[i].selected) : (selectedData[i].value === sendData.id ? sendData.key : ''),
                    disabled: selectedData[i].disabled ? (selectedData[i].selected === sendData.key ? selectedData[i].value === sendData.id ? true : false : selectedData[i].disabled) : (selectedData[i].value === sendData.id ? true : false),
                    // disabled: selectedData[i].value === id ? true : selectedData[i].disabled
                })
            }
        }
        else {
            for (var j = 0; j < rows.length; j++) {
                newData.push({
                    value: rows[j].id,
                    label: rows[j].menuWarehouseSpecName,
                    selected: '',
                    disabled: false
                })
            }
        }

        setData(newData);
    }

    const newData = async (rows: any, setData: any) => {
        let newData: Array<any> = [];
        for (var j = 0; j < rows.length; j++) {
            const res = await fetchApi.GET(wmService, {
                apiId: 'branchStoreMenuWarehouse',
                apiExtend: 'checkMenuWarehouse',
                apiData: {
                    menuWarehouseId: rows[j].id,
                    // branchStoreId: localStorage.getItem("currentBranchStoreId"),
                    branchStoreId: contextData?.currentBranchStoreId,
                    mountDataOptions: {
                        userMount: {
                            isDept: true
                        }
                    }
                }

            })
            newData.push({
                value: rows[j].id,
                label: rows[j].foodName + (rows[j].brand ? "（" + rows[j].brand.brandName + "）" : ""),
                disabled: res.data.length > 0 ? rows[j].id !== res.data[0].menuWarehouseId ? false : true : false
            })
        }
        setData(newData);
    }

    //查找数据
    const searchData = async (queryString: any) => {

        if (objectHandle.isEmpty(queryString) === true) {
            console.log('查询不能为空');
            return undefined;
        }

        const res = await fetchApi.GET(wmService, {
            apiId: 'menuWarehouse',
            apiExtend: 'showList',
            apiData: {
                queryString
            }
        })
        newData(res.data, setMenuWarehouseData);
    }

    const handleOk = (e: any) => {
        form.validateFields() //数据校验
            .then(async (values: any) => {
                // console.log(values);
                fetchApi.POST(wmService, {
                    apiId: 'branchStoreMenuWarehouse',
                    apiExtend: 'synchronize',
                    apiData: {
                        ...values,
                        mountDataOptions: {
                            userMount: {
                                isBranchStore: true
                            }
                        },
                        // menuWarehouseId: data.id,
                        // brandsId: form.getFieldsValue()['brandsId']
                    }
                }).then(res => {
                    if (res.success) {
                        antMessage('success', '添加成功', 'success');
                        closeModal(true);
                    }
                }).catch(err => {
                    antMessage('error', '错误，无法保存', err.message + ' | status:' + err.errors.status);
                })
            })
            .catch((err: any) => {
                showCustomNotification(notificationApi!, {
                    type: 'warning',
                    message: "无法通过数据校验",
                    description: "请输入所需数据!"
                });;
            });
    };

    // /**关闭窗口并刷新数据 */
    // const handleCancel = () => {
    //     closeModal(false)

    //         getData(true);
    // }

    const created = (e: any) => {
        if (form.getFieldValue('created') >= 1) {
            setCreatedDisable(false);
        }
        else {
            setCreatedDisable(true);
        }
    }

    const getDefaultCategoryToTypeData = (e: any) => {
        fetchApi.GET(wmService, {
            apiId: "menuWarehouseCategory",
            apiExtend: 'getTypeList',
            apiVariable: form.getFieldValue('menuWarehouseCategoryId'),
        }).then(res => {
            if (res.success) {
                setMenuWarehouseTypeData(res.data)
            }
        });
    }

    //选择扩展分类后，把扩展修改设为true
    const selectExpandCategory = async (e: any, option: any) => {
        form.setFieldsValue({ expandCategoryChange: true });
    }

    const getDefaultCategoryData = (e: any) => {
        setDefaultCategoryId(form.getFieldValue('menuWarehouseCategoryId'));
    }

    const getCategoryToTypeData = (menuWarehouseCategoryId: string) => {
        setDisable(false);

        if (menuWarehouseCategoryId !== defaultCategoryId) {
            form.setFieldsValue({ menuWarehouseTypeId: "" });
        }

        fetchApi.GET(wmService, {
            apiId: "menuWarehouseCategory",
            apiExtend: 'getTypeList',
            apiVariable: menuWarehouseCategoryId,
        }).then(res => {
            if (res.success) {
                setMenuWarehouseTypeData(res.data)
            }
        });
    }

    const selectChange = (e: any, name: any) => {
        let sendData = {
            id: e,
            key: name
        }
        newSelectData(undefined, sendData, setSelectedData);
    };

    const onChangeSpecial = (e: any) => {
        setSynchronizeStyle(e.target.value);
    };

    return (
        <>
            <AntDraggableModal
                title={"【 " + (data.path === 'MenuSpecSpecial' ? data.menuSpecSpecialName : data.foodName) + " 】完善以下信息后" + (data.foodType === 'setMeal' ? "  关联打印机" : (data.key === "FromMenuWarehouse" ? "  与仓库菜式相关联" : " 同步到菜谱/仓库"))}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={() => closeModal(false)}
                closable={false}
                maskClosable={false}
                width={700}
            >
                <Form
                    form={form}
                    initialValues={{ "price": 999, "sortBy": 1 }}
                    name="Edit"
                    style={(synchronizeStyle === 1 || synchronizeStyle === 3) ? { height: 450 } : {}}
                    onFinish={handleOk}
                    onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}
                >
                    <Row gutter={[8, 0]}>
                        <Col span={!synchronizeStyle ? data.key === "ToMenuWarehouse" ? 24 : 0 : 0}>
                            <Row gutter={[8, 0]}>
                                <Col span={0}>
                                    <Form.Item
                                        label="门店Id"
                                        name="branchStoreId"
                                    >
                                        <Input disabled={true} />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        name="specialId"
                                    >
                                        <Radio.Group onChange={onChangeSpecial}>
                                            <Space direction="vertical">
                                                <Radio
                                                    key={1}
                                                    value={1}
                                                >
                                                    同步到菜谱
                                                </Radio>
                                                <Radio
                                                    key={2}
                                                    value={2}
                                                    disabled={sessionStorage.getItem('isAdmin') === 'true' ? data.menuWarehouseId ? true : false : true}
                                                >
                                                    同步到仓库
                                                </Radio>
                                            </Space>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row gutter={[16, 0]}>
                        <Col span={0}>
                            <Form.Item
                                label="关键字"
                                name="key"
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>

                        <Col span={0}>
                            <Form.Item
                                label="门店菜式ID"
                                name="branchStoreMenuWarehouseId"
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>

                        {/* 这部分是关联打印机 */}
                        <Col span={0}>
                            <Form.Item
                                label="menuSpecSpecialId"
                                name="menuSpecSpecialId">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={0}>
                            <Form.Item
                                label="setMealRelationId"
                                name="setMealRelationId">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={0}>
                            <Form.Item
                                label="setMealRelationStr"
                                name="setMealRelationStr">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={0}>
                            <Form.Item
                                label="spareId"
                                name="spareId">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={synchronizeStyle === 3 && data.key === "RelatePrinter" ? 24 : 0}>
                            <Form.Item
                                label="选择相关联菜式"
                                name="toRelateMenuSpecId"
                                rules={[{ required: synchronizeStyle === 3 && data.key === "RelatePrinter" ? true : false, message: '请选择相关联菜式!' }]}>
                                <Select
                                    onSearch={(e) => getList.searchEx('menuSpec', setMenuSpecData, e, menuSpecData, {
                                        apiExtend: 'showSpecAndPrinter',
                                        andWhereObj: {
                                            // branchStoreId: localStorage.getItem("currentBranchStoreId"),
                                            branchStoreId: contextData?.currentBranchStoreId,
                                            mountDataOptions: {
                                                userMount: {
                                                    isDept: true
                                                }
                                            }
                                        }
                                    })}
                                    optionLabelProp='name'
                                    showSearch
                                    allowClear
                                    placeholder="选择相关联菜式 (匹配菜式的打印机)"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) => {
                                        return true
                                    }}
                                    options={menuSpecData ? menuSpecData : []}
                                >
                                </Select>

                                {/* <Select
                                    mode="multiple"
                                    showSearch
                                    placeholder="选择相关联菜式 (匹配菜式的打印机)"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) => {
                                        return true
                                    }}
                                    options={menuHeadData}
                                >
                                </Select> */}
                            </Form.Item>
                        </Col>


                        {/* 这部分是同步规格 和 同步到菜谱*/}
                        <Col span={synchronizeStyle === 1 && data.key === "ToMenuSpec" ? specialState ? 24 : 0 : 0}>
                            <Form.Item
                                label="选择菜谱类型"
                                name="toMenuSpecMenuHeadId"
                                rules={[{ required: synchronizeStyle === 1 && data.key === "ToMenuSpec" ? true : false, message: '请选择菜谱类型!' }]}>
                                <Select
                                    mode="multiple"
                                    showSearch
                                    placeholder="选择菜谱类型"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) => {
                                        return true
                                    }}
                                    options={menuHeadData}
                                >
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={synchronizeStyle === 1 && (data.key === "ToMenuSpec" || data.key === "ToMenuWarehouse") ? specialState ? 0 : 24 : 0}>
                            <Form.Item>
                                <Alert
                                    message="错误提示"
                                    description="部分 “特殊规格” 或 “套餐类别” 没有任何数据或数据不在“启用”状态下，所以暂不提供同步功能，请完善数据后再进行同步！！"
                                    type="error"
                                    style={{ fontSize: 30 }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={synchronizeStyle === 1 && data.key === "ToMenuWarehouse" ? specialState ? 24 : 0 : 0}>
                            <Form.Item
                                label="选择菜谱类型"
                                name="toMenuMenuHeadId"
                                rules={[{ required: synchronizeStyle === 1 && data.key === "ToMenuWarehouse" ? true : false, message: '请选择菜谱类型!' }]}>
                                <Select
                                    mode="multiple"
                                    showSearch
                                    placeholder="选择菜谱类型"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) => {
                                        return true
                                    }}
                                    options={menuHeadBaseData}
                                >
                                </Select>
                            </Form.Item>
                        </Col>


                        {/* 这部分是关联仓库菜式 */}
                        <Col span={!synchronizeStyle && data.key === "FromMenuWarehouse" ? 24 : 0}>
                            <Form.Item
                                label="选择需要关联菜式"
                                name="menuWarehouseId"
                                rules={[{ required: data.key === "FromMenuWarehouse" ? true : false, message: '请选择需要关联的菜式!' }]}
                            >
                                <Select
                                    style={{ width: 300, marginLeft: 8 }}
                                    onSearch={(e) => searchData(e)}
                                    onSelect={selectData}
                                    disabled={isSelect}
                                    showSearch
                                    placeholder="填写菜式名称,查找相关菜式"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) => {
                                        return true
                                    }}
                                    // options={getList.generalSelcetOption(menuWarehouseData, "foodName")}
                                    options={menuWarehouseData}
                                >
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={!synchronizeStyle && data.key === "FromMenuWarehouse" && data.branchStoreMenuWarehouseSpecRow.length > 0 ? 24 : 0}>
                            <Form.List name="specIds">
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(({ key, name, ...restField }) => (
                                            <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                                <Form.Item
                                                    hidden
                                                    label="门店规格"
                                                    {...restField}
                                                    name={[name, 'branchStoreMenuWarehouseSpecId']}
                                                >
                                                    <Input disabled />
                                                </Form.Item>

                                                <Form.Item
                                                    label="规格名称"
                                                    {...restField}
                                                    name={[name, 'branchStoreMenuWarehouseSpecName']}
                                                >
                                                    <Input disabled />
                                                </Form.Item>

                                                <Form.Item
                                                    {...restField}
                                                    label="选择关联仓库的规格"
                                                    name={[name, 'menuWarehouseSpecId']}
                                                >
                                                    {/* <Select
                                                        showSearch
                                                        style={{ width: 200 }}
                                                        placeholder="选择规格"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option: any) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                        {getList.general(selectedData, 'menuWarehouseSpecName')}
                                                    </Select> */}

                                                    <Select
                                                        showSearch
                                                        style={{ width: 200 }}
                                                        placeholder="选择规格"
                                                        onChange={(e) => selectChange(e, name)}
                                                        optionFilterProp="children"
                                                        filterOption={(input, option: any) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                        options={selectedData}
                                                    >
                                                    </Select>
                                                </Form.Item>
                                            </Space>
                                        ))}
                                    </>
                                )}
                            </Form.List>
                        </Col>


                        {/* 这部分是同步到仓库 */}
                        <Col span={synchronizeStyle === 2 && data.key === "ToMenuWarehouse" ? 24 : 0}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={synchronizeStyle === 2 && data.key === "ToMenuWarehouse" ? 12 : 0}>
                            <Form.Item
                                label="创作年份"
                                name="created">
                                <InputNumber min={1992} max={2992} onChange={created} addonBefore="创于" addonAfter="年" />
                            </Form.Item>
                        </Col>

                        <Col span={synchronizeStyle === 2 && data.key === "ToMenuWarehouse" ? 12 : 0}>
                            <Form.Item
                                label="创作地"
                                name="areaId"
                                rules={[{ required: createdDisable ? false : true, message: '请选择创作地!' }]}
                            >
                                <Select
                                    disabled={createdDisable}
                                    placeholder="创作地"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.generalEx(areaData, {
                                        itemName: "areaName",
                                        includeNull: false
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={synchronizeStyle === 2 && data.key === "ToMenuWarehouse" ? 24 : 0}>
                            <Form.Item
                                label="菜式名称"
                                name="foodName"
                            >
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={synchronizeStyle === 2 && data.key === "ToMenuWarehouse" ? 24 : 0}>
                            <Form.Item
                                label="菜式英文"
                                name="foodNameEn"
                            // rules={[{ required: synchronizeStyle === 2 && data.key === "ToMenuWarehouse" ? true : false, message: '请输入菜式英文名!' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={synchronizeStyle === 2 && data.key === "ToMenuWarehouse" ? 24 : 0}>
                            <Form.Item
                                label="菜式描述"
                                name="description"
                            >
                                <Input placeholder="如：外脆内嫩 鲜甜香滑 / 精酿啤酒、椰子汁、姜汁" />
                            </Form.Item>
                        </Col>

                        <Col span={synchronizeStyle === 2 && data.key === "ToMenuWarehouse" ? 12 : 0}>
                            <Form.Item
                                label="菜式仓库大分类"
                                name="menuWarehouseCategoryId"
                                rules={[{ required: synchronizeStyle === 2 && data.key === "ToMenuWarehouse" ? true : false, message: '菜式仓库大分类!' }]}>
                                <Select
                                    placeholder="菜式仓库大分类"
                                    optionFilterProp="children"
                                    onSelect={getCategoryToTypeData}
                                    onFocus={getDefaultCategoryData}
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.generalEx(menuWarehouseCategoryData, {
                                        itemName: "menuWarehouseCategoryName",
                                        includeNull: false
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={synchronizeStyle === 2 && data.key === "ToMenuWarehouse" ? 12 : 0}>
                            <Form.Item
                                label="菜式仓库小分类"
                                name="menuWarehouseTypeId"
                                rules={[{ required: synchronizeStyle === 2 && data.key === "ToMenuWarehouse" ? true : false, message: '菜式仓库小分类!' }]}>
                                <Select
                                    disabled={disable}
                                    placeholder="菜式仓库小分类"
                                    optionFilterProp="children"
                                    onMouseEnter={getDefaultCategoryToTypeData}
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.generalEx(menuWarehouseTypeData, {
                                        itemName: "menuWarehouseTypeName",
                                        includeNull: false
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={synchronizeStyle === 2 && data.key === "ToMenuWarehouse" ? 24 : 0}>
                            <Form.Item
                                label="用餐性质"
                                name="scenesId">
                                <Select
                                    mode="multiple"
                                    showSearch
                                    placeholder="选择用餐性质"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(sceneData, 'sceneName', 'id', undefined, false)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={synchronizeStyle === 2 && data.key === "ToMenuWarehouse" ? 24 : 0}>
                            <Form.Item
                                label="扩展分类"
                                name="expandCategorysId">
                                <Select
                                    mode="multiple"
                                    onChange={selectExpandCategory}
                                    showSearch
                                    placeholder="选择扩展分类"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.generalEx(expandCategoryData, {
                                        itemName: "expandCategoryName",
                                        setItemNameFun: (item: any) => {
                                            let name = '';
                                            if (item) {
                                                const { area } = item;
                                                if (area) {
                                                    name = `【 ${area.areaName} 】`;
                                                }
                                                name += item.expandCategoryName;
                                            }
                                            return name;
                                        },
                                        includeNull: false
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={synchronizeStyle === 2 && data.key === "ToMenuWarehouse" ? 0 : 0}>
                            <Form.Item
                                label="扩展修改"
                                name="expandCategoryChange"
                                valuePropName="checked"
                                initialValue={false}>
                                <Switch checked={false}
                                    disabled
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>

                        <Col span={synchronizeStyle === 2 && data.key === "ToMenuWarehouse" ? 12 : 0}>
                            <Form.Item
                                label="烹调方法"
                                name="cookMethodId"
                                rules={[{ required: synchronizeStyle === 2 && data.key === "ToMenuWarehouse" ? true : false, message: '烹调方法!' }]}>
                                <Select
                                    showSearch
                                    placeholder="烹调方法"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.generalEx(cookMethodData, {
                                        itemName: "cookMethodName",
                                        includeNull: false
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={synchronizeStyle === 2 && data.key === "ToMenuWarehouse" ? 12 : 0}>
                            <Form.Item
                                label="单位"
                                name="productUnitId"
                                rules={[{ required: synchronizeStyle === 2 && data.key === "ToMenuWarehouse" ? true : false, message: '请输入单位!' }]}>
                                <Select
                                    showSearch
                                    placeholder="单位"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.generalEx(productUnitData, {
                                        itemName: "productUnitName",
                                        includeNull: false
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={synchronizeStyle === 2 && data.key === "ToMenuWarehouse" ? 12 : 0}>
                            <Form.Item
                                label="价格"
                                name="price">
                                <InputNumber min={0} addonBefore="¥" addonAfter="元" />
                            </Form.Item>
                        </Col>

                        <Col span={synchronizeStyle === 2 && data.key === "ToMenuWarehouse" ? 12 : 0}>
                            <Form.Item
                                label="排序"
                                name="sortBy">
                                <InputNumber min={1} />
                            </Form.Item>
                        </Col>

                        <Col span={synchronizeStyle === 2 && data.key === "ToMenuWarehouse" ? 24 : 0}>
                            <Form.Item
                                label="介绍"
                                name="introduction"
                            >
                                <TextArea
                                    placeholder="菜式介绍"
                                    autoSize={{ minRows: 2, maxRows: 5 }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={synchronizeStyle === 2 && data.key === "ToMenuWarehouse" ? 24 : 0}>
                            <Form.Item
                                label="是否强制同步"
                                name="isMandatory"
                                valuePropName="checked"
                                initialValue={false}>
                                <Switch checked={false}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </AntDraggableModal >
        </>
    );
};