import { Col, Form, Input, InputNumber, Radio, Row, Switch } from 'antd';
import { useContext, useEffect, useRef, useState } from 'react'
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';
import TextArea from 'antd/es/input/TextArea';
import fetchApi from '../../../utils/api/fetchApi';
import { SocketContext } from '../../../App';

export default function ReserveMenuEdit(props: any) {

    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>(undefined);

    const [showLanguageTypeData, setShowLanguageTypeData] = useState<any>([]);

    /** 后端数据wmService */
    let wmService: any = "";

    const useContextData = useContext(SocketContext);
    const { contextData } = useContextData;

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
        }
        const fetchData = async () => {

            form.setFieldsValue({ branchStoreId: contextData?.currentBranchStoreId });
            // form.setFieldsValue({ branchStoreId: localStorage.getItem("currentBranchStoreId") });
            form.setFieldsValue({ customerReserveId: data.sendPageData.customerReserveId });

            fetchApi.GET(wmService, {
                apiId: 'generalData',
                apiVariable: 'showLanguageType',
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setShowLanguageTypeData(res.data);
                }
            });
        }
        fetchData()
    }, [])

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };

    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'reserveMenu',
        mountDataOptions: {
            userMount: {
                isBrand: true
            }
        }
    };

    return (
        <>
            <WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '预订菜单'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={1000}
            >
                <Form
                    form={form}
                    initialValues={{ "showLanguageTypeId": 'CN', "sortBy": 999 }}
                    name="Edit"
                    onFinish={handleOk}
                    onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}
                >
                    <Row gutter={[16, 0]}>
                        <Col span={12}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="预订单ID"
                                name="customerReserveId">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={0}>
                            <Form.Item
                                label="branchStoreId"
                                name="branchStoreId">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="预订菜单名称"
                                name="reserveMenuName"
                                rules={[{ required: true, message: '请输入预订菜单名称!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="售价"
                                name="sellPrice"
                            >
                                <InputNumber disabled={true} min={0}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="原价"
                                name="originalPrice"
                            >
                                <InputNumber disabled={true} min={0}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="标题"
                                name="title">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="副标题"
                                name="subtitle">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="脚注"
                                name="footnote">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="副脚注"
                                name="subFootnote">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="备注"
                                name="memo"
                            >
                                <TextArea
                                    placeholder="备注"
                                    autoSize={{ minRows: 2, maxRows: 5 }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="显示语种"
                                name="showLanguageTypeId"
                            >
                                <Radio.Group>
                                    {
                                        showLanguageTypeData.map((item: any) => {
                                            return <Radio value={item.id}>{item.name}</Radio>
                                        })
                                    }
                                </Radio.Group>
                            </Form.Item>
                        </Col>

                        {/* <Col span={6}>
                            <Form.Item
                                label="排序"
                                name="sortBy">
                                <InputNumber min={1} />
                            </Form.Item>
                        </Col> */}

                        {/* <Col span={6}>
                            <Form.Item
                                label="允许他人修改"
                                name="isModifyByOther"
                                valuePropName="checked"
                                initialValue={false}>
                                <Switch checked={false}
                                    checkedChildren="允许"
                                    unCheckedChildren="不允许" />
                            </Form.Item>
                        </Col> */}

                        <Col span={6}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                <Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}