import WebUniversalNewEx, { WUExtendPropsType } from '../../system/WebUniversalNewEx';
import { columnsheadId } from '../../universal/columnshead';
import DeviceModeEdit from './DeviceModelEdit';

const WebComponent = (props: any) => {

    //表头
    const columnsHead = [
        {
            title: '品牌',
            dataIndex: 'brand',
        },
        {
            title: '品名',
            dataIndex: 'itemName',
        },
        {
            title: '型号',
            dataIndex: 'modelName',
        }
    ];
    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '设备型号',
        apiId: 'deviceModel',
        showStates: true,
        columns,
        pagination: true,
        EditComponent: DeviceModeEdit,
    };

    return (
        <WebUniversalNewEx props={extendProps}></WebUniversalNewEx>
    )
}

export default WebComponent