import React, { useEffect, useState } from 'react'
import AntDraggableModal from '../../../utils/extend/AntDraggableModal'
import moment from 'moment';
import { Badge, Card, List } from 'antd';
import fetchApi from '../../../utils/api/fetchApi';
import systemConfig from '../../../config/systematic';
import DrawerDetailList from '../cashier/DrawerDetailList';

export default function RoomCateringOrder(props: any) {
    const { data, closeModal } = props;

    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;

    const [rows, setRows] = useState<Array<any>>([])




    const [menuData, setMenuData] = useState<any>();
    const [menuOpen, setMenuOpen] = useState<boolean>(false);



    useEffect(() => {
        initData(true,1)
    }, [])


    //初始化数据
    const initData = (status?: boolean,open?: number) => {

        fetchApi.GET(wmService, {
            apiId: "room",
            apiExtend: "queryOrderList",
            apiData: {
                roomId: data.roomId,
                timeTypeId: data.timeTypeId,
                caOrderStatus: data.caOrderStatus,
                tarryType:2
            }
        }).then((res) => {

            let data = res.data;
            setRows(data)
            if (status) {
               
                switch (open) {
                    //初始打开时候，判断里面长度是否为1，若是，则自动打开详情弹窗
                    case 1:
                        if (data.length === 1) {
                            handleCheckMenu(data[0])
                        }
                        break;
                    //关闭抽屉时是否判断本页面数据长度是否为0，若为零自动关闭
                    case 2:
                        if (data.length === 0) closeModal(true)
                        break;
                       //关闭抽屉时是否判断本页面数据长度是否为1，若为零自动关闭
                    case 3:
                        if (data.length === 1) closeModal(true)
                        break;
                }
            }
        })
    }

    const handleOk = (e: any) => {
        closeModal(true)
    }
    const handleCancel = (e: any) => {
        closeModal(true)

    }






    //查看订单详情抽屉
    const handleCheckMenu = (order: any) => {
        setMenuData({
            id: order.id,
            roomNum: order.roomNum,
            caOrderStatus:order.caOrderStatus,
            caMenuStatus:[2,3,999],
            type:"noPending"
        })
        setMenuOpen(true)
    }
    //关闭订单详情抽屉
    const onCloseCheckMenu = (status: boolean,open?: number) => {
        setMenuOpen(false);
        if (status) {
            initData(true, open)
        }
    }



    return (
        <>
            <AntDraggableModal
         
                title={`订单列表`}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={true}
                width={"80%"}
                footer={null}
            >

                <List
                  size="large"
                    itemLayout="horizontal"
                    grid={{ gutter: 16, column: 3 }}
                    dataSource={rows}
                    // position
                    renderItem={(order: any) => (
                        <List.Item>
                            <Card
                                title={<div onClick={() => handleCheckMenu(order)} style={{ display: "flex", justifyContent: "space-between", alignItems: "center", fontSize:20 }}>
                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                        <div >时间:{order.orderDate}</div>

                                    </div>
                                    <div >房间:{order.roomNum}</div>
                                </div>}


                            >
                                <div>
                                    <div>整单备注:{order.memo}</div>
                                </div>
                                {/* <p>金额：{order.amount}</p> */}
                            </Card>
                        </List.Item>
                    )}
                />
            </AntDraggableModal>

            {/* *查看菜品 */}
            {/* {detailOpen ? (<CateringOrderDetail data={detailData} closeModal={onCloseDetail}
            />)
                : null} */}
            {/**查看菜品 */}
            {/* {menuOpen ? (<MenuList data={menuData} closeModal={onCloseCheckMenu}
            />)
                : null} */}
            {menuOpen ? <DrawerDetailList data={menuData} closeModal={onCloseCheckMenu} ></DrawerDetailList> : null}


        </>
    )
}
