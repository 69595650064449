import WebUniversalNewEx, { WUExtendPropsType } from '../../system/WebUniversalNewEx';
import { columnsheadId } from '../../universal/columnshead';
import ReserveMenuStyleEdit from './ReserveMenuStyleEdit';
import { useEffect, useRef } from 'react';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import { Switch, Tooltip } from 'antd';

export default function ReserveMenuStyle(props: any) {

    const ref = useRef<any>(undefined);
    const { closeModal, data } = props;

    /** api服务id */
    const apiId = 'reserveMenuStyle';

    useEffect(() => {

        //同步获取数据 async - await
        const fetchData = async () => {

        }
        fetchData();
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    const handleOk = (e: any) => {
        closeModal(false);
    };

    //表头
    const columnsHead = [
        {
            title: '模板名',
            dataIndex: 'reserveMenuModelName',
            render: (text: any, record: any) => {
                return (
                    <div>{record.reserveMenuModel.reserveMenuModelName}</div>
                )
            }
        },
        {
            title: '模块',
            dataIndex: 'reserveMenuModuleName',
        },
        {
            title: '替换内容',
            dataIndex: 'isReplace',
            render: (text: any, record: any) => {
                if (record.isReplace) {
                    return (
                        <Tooltip placement="topLeft" title={'替换内容为 ' + record.replaceContent} color='green'>
                            <Switch checkedChildren="是" unCheckedChildren="否"
                                checked={text} key={record.id}
                            />
                        </Tooltip>
                    )
                }
                else {
                    return (
                        <Switch checkedChildren="是" unCheckedChildren="否"
                            checked={text} key={record.id}
                        />
                    )
                }
            }
        }
    ];

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '预订菜单样式',
        apiId,
        columns,
        showStates: true,
        pagination: true,
        // extendIdName: "brandId",
        EditComponent: ReserveMenuStyleEdit,
        sendPageData: {
            reserveMenuModelId: data.id
        },
        // mountDataOptions: {
        //     userMount: {
        //         isDept: true
        //     }
        // },
    };

    return (
        <>
            <AntDraggableModal
                title={data.brandName + " 【" + data.reserveMenuModelName + "】"}
                open={true}
                okText='关闭'
                // cancelText={stringHandle.isUndefined()}
                cancelButtonProps={{ style: { display: "none" } }}
                onOk={handleOk}
                onCancel={handleOk}
                closable={false}
                // maskClosable={false}
                width={1000}>
                <WebUniversalNewEx props={extendProps} ref={ref}></WebUniversalNewEx>
            </AntDraggableModal>
        </>
    )
}