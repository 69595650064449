import { Col, Form, Input, Row, Select, Switch } from 'antd';
import { useEffect, useRef, useState } from 'react';
import AntDraggableModal from '../../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../../system/WebEditUniversalNewEx';
import getList from '../../../../utilsTsx/universal/getList';

//布局
const layout = {
    // labelCol: { span: 6 },
    // wrapperCol: { span: 20},
};

const EditComponent = (props: any) => {

    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>(undefined);

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };
    const [wmService, setWmService] = useState<string>('');

    const qosData = [{
        id: 0,
        name: '最多一次'
    }, {
        id: 1,
        name: '至少一次'
    }, {
        id: 2,
        name: '恰好一次'
    }];

    useEffect(() => {
        /** 后端数据地址 */
        let wms: any = "";
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wms = ref.current.wmService;
            setWmService(wms);
            // ref.current.row.then((res: any) => {
            // })
        }
        // const fetchData = async () => {
        // }
        // fetchData()
    }, [])

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'mqttTopic'
    };

    return (
        <>
            <WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + 'mqtt主题'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={600}
            >
                <Form
                    {...layout}
                    form={form}
                    initialValues={{ "sortBy": 999 }}
                    name="Edit"
                    onFinish={handleOk}
                ><Row>
                        <Col span={24}>
                            <Form.Item
                                label="id"
                                name="id"
                                tooltip='如不输入，自动生成'>
                                <Input disabled={data.type === 'NEW' ? false : true} />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="主题"
                                name="topicName">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="默认唯一主题"
                                name="isDefaultUniqueTopic"
                                tooltip="默认唯一主题，如果: 是，topic由'<mqttTopicId>/<clientId>'组成，默认：true"
                                valuePropName="checked"
                                initialValue={true}>
                                <Switch checked={true}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>

                        <Col span={10}>
                            <Form.Item
                                label="服务质量"
                                name="qos"
                                tooltip='传送数据时的服务质量，0（最多一次）; 1（至少一次）; 2（恰好一次）'>
                                <Select
                                    showSearch
                                    placeholder="选择服务质量"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(qosData)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                <Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
};

export default EditComponent;