import { useContext, useEffect, useRef, useState } from 'react'
import { columnsheadId } from '../../universal/columnshead';
import WebUniversalNewEx, { initDataType, WUExtendPropsType } from '../../system/WebUniversalNewEx';
import MenuHeadEdit from './MenuHeadEdit';
import { Button, Col, Form, Input, Modal, Row, Select, Space, Spin, Switch } from 'antd';
// import BranchStoreList from '../../generalComponents/BranchStoreList';
import WarehouseToMenu from '../menu/WarehouseToMenu';
import ShowMenu from '../menu/ShowMenu';
import antMessage from '../../../utils/extend/AntdNotification'
import fetchApi from '../../../utils/api/fetchApi';
import systemConfig from '../../../config/systematic';
import DownloadExcel from '../../report/DownloadExcel';
// import getList from '../../universal/getList';
import { SocketContext } from '../../../App';
import { showCustomNotification, useNotificationInstance } from '../../../utils/extend/AntdNotificationEx';

export default function MenuHead(props: any) {

    const ref = useRef<any>(undefined);

    const [form] = Form.useForm();

    /** api服务id */
    const apiId = 'menuHead';

    /** 更改一项数据 */
    // let handleChangeOne: any;

    //选择转移菜式
    const [menuData, setMenuData] = useState<any>();

    //转移菜式
    const [warehouseToMenuVisible, setWarehouseToMenuVisible] = useState(false);

    //菜式窗口
    const [showMenuVisible, setShowMenuVisible] = useState(false);
    const notificationApi = useNotificationInstance()

    //菜谱类型数据
    const [menuHeadData, setMenuHeadData] = useState<any>();

    /** 操作方式 */
    const [handleKey, setHandleKey] = useState<any>();

    //导出菜式参数
    const [downloadData, setDownloadData] = useState<any>();

    /** 显示菜谱类型数据 */
    const [showMenuHeadVisible, setShowMenuHeadVisible] = useState<any>(false);

    /**选择菜谱后，分类数据 */
    const [shopTypeData, setShopTypeData] = useState<any>();

    /**是否能够再选择菜谱 */
    const [isSelect, setIsSelect] = useState<any>(false);

    const [loadingData, setLoadingData] = useState<any>(false);

    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;

    const useContextData = useContext(SocketContext);
    const { contextData, setContextData } = useContextData;

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
        }

        //同步获取数据 async - await
        const fetchData = async () => {
        }

        if (contextData?.currentBranchStoreId) {
            if (contextData?.refresh) {
                onRefresh({ branchStoreId: contextData?.currentBranchStoreId })
            }
        }

        fetchData();
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contextData?.currentBranchStoreId]); //初始化数据

    const onRefresh = (item: any) => {
        const { branchStoreId } = item;
        const currentData: initDataType = {
            apiId,
            sendPageData: {
                branchStoreId
            }
        }
        ref.current.initData(currentData);

        setContextData({ ...contextData, refresh: false })
    }

    //改变数据
    const handleChange = async (id: any, columnName: string, isValue: boolean) => {
        const bodys = {
            apiId: "menuHead",
            apiVariable: id,
            apiExtend: "updateSingle",
            apiData: {
                columnName,
                isValue
            }
        }

        fetchApi.PUT(wmService, bodys).then(res => {
            showCustomNotification(notificationApi!, {
                type: 'success',
                message: '编辑成功',
                description: 'success',
            });;
            initData();
        }).catch(err => {
            antMessage('error', '删编辑失败', err.message);
        })
    };

    //关闭菜谱类型
    const onCloseCopyMenuHead = () => {
        setShowMenuHeadVisible(false);
        setIsSelect(false);
        form.setFieldsValue({ menuHeadId: null });
        form.setFieldsValue({ shopTypeIds: null });
        setShopTypeData([]);
    }

    //打开复制菜谱类型
    const handleCopyMenuHead = async (record: any) => {

        const checkData = await fetchApi.GET(wmService, {
            apiId: 'menuHead',
            apiExtend: 'checkMenuData',
            apiData: {
                menuHeadId: record.id
            }
        })
        if (checkData.data) {
            antMessage('error', '当前菜谱已有数据', "不能从其他菜谱复制任何数据。");
        }
        else {
            fetchApi.GET(wmService, {
                apiId: "menuHead",
                apiExtend: 'showList',
                apiData: {
                    // branchStoreId: localStorage.getItem("currentBranchStoreId"),
                    branchStoreId: contextData?.currentBranchStoreId,
                    mountDataOptions: {
                        userMount: {
                            isDept: true
                        }
                    },
                }
            }).then(async res => {
                if (res.success) {

                    let newSelectData: Array<any> = [];
                    for (var j = 0; j < res.data.length; j++) {
                        const ress = await fetchApi.GET(wmService, {
                            apiId: 'menuHead',
                            apiExtend: 'checkMenuData',
                            apiData: {
                                menuHeadId: res.data[j].id
                            }
                        })

                        newSelectData.push({
                            value: res.data[j].id,
                            label: res.data[j].menuHeadName,
                            disabled: !ress.data
                        })
                    }
                    setMenuHeadData(newSelectData);
                }
            });

            setHandleKey("copy");
            form.setFieldsValue({ currentMenuHeadId: record.id });
            setShowMenuHeadVisible(true);
        }
    };

    const handleOk = (e: any) => {
        form.validateFields() //数据校验
            .then(async (values: any) => {
                switch (handleKey) {
                    case 'copy':
                        setLoadingData(true);
                        fetchApi.POST(wmService, {
                            apiId: 'menuHead',
                            apiExtend: 'copyAllMenuHead',
                            apiData: {
                                ...values,
                                mountDataOptions: {
                                    userMount: {
                                        isBranchStore: true
                                    }
                                },
                            }
                        }).then(res => {
                            if (res.success) {
                                setLoadingData(false);
                                antMessage('success', '添加成功', 'success');
                                onCloseCopyMenuHead();
                            }
                        }).catch(err => {
                            antMessage('error', '错误，无法保存', err.message + ' | status:' + err.errors.status);
                        })
                        break;
                    case 'download':
                        let res = await fetchApi.GET(wmService, {
                            apiId: "menuHead",
                            apiExtend: 'downloadData',
                            apiData: {
                                ...values,
                            }
                        })
                        if (res.success) {
                            onCloseCopyMenuHead();
                            await DownloadExcel(res.data, "menuHead", { menuHeadName: downloadData.menuHeadName, branchStoreName: downloadData.brandName + downloadData.branchStoreName });
                        }
                        break;
                }
            })
            .catch((err: any) => {
                if (handleKey === 'copy') {
                    showCustomNotification(notificationApi!, {
                        type: 'warning',
                        message: "无法通过数据校验",
                        description: "请输入所需数据!"
                    });
                }
            });
    };

    const initData = async () => {
        const currentData: initDataType = {
            apiId,
            sendPageData: {
                // branchStoreId: localStorage.getItem("currentBranchStoreId")
                branchStoreId: contextData?.currentBranchStoreId
            },
        }
        ref.current.initData(currentData);
    }

    //关闭穿梭框页面
    const onCloseWarehouseToMenu = async (status: boolean) => {
        setWarehouseToMenuVisible(false);
        // if (status) {
        //     await initData();
        // }
    }

    //打开添加菜式
    const handleWarehouseToMenu = (e: any) => {
        // console.log(e);

        sendData.key = 'branchStoreMenuWarehouseToMenu';
        sendData.menuHeadId = e.id;
        sendData.menuHeadName = e.menuHeadName
        sendData.brandId = e.branchStore.brand.id;
        sendData.brandName = e.branchStore.brand.brandName;
        sendData.branchStoreId = e.branchStore.id;
        sendData.branchStoreName = e.branchStore.branchStoreName;
        setMenuData(sendData);
        setWarehouseToMenuVisible(true);
    };

    //打开查看菜式
    const handleShowMenu = (e: any) => {

        sendData.menuHeadId = e.id;
        sendData.menuHeadName = e.menuHeadName
        sendData.brandId = e.branchStore.brand.id;
        sendData.brandName = e.branchStore.brand.brandName;
        sendData.branchStoreId = e.branchStore.id;
        sendData.branchStoreName = e.branchStore.branchStoreName;
        setMenuData(sendData);
        setShowMenuVisible(true);
    };

    //打开导出文档窗口
    const handleDownloadExcel = async (e: any) => {

        sendData.menuHeadId = e.id;
        sendData.menuHeadName = e.menuHeadName
        sendData.brandId = e.branchStore.brand.id;
        sendData.brandName = e.branchStore.brand.brandName;
        sendData.branchStoreId = e.branchStore.id;
        sendData.branchStoreName = e.branchStore.branchStoreName;
        setDownloadData(sendData);

        setHandleKey("download");
        form.setFieldsValue({ currentMenuHeadId: e.id });
        selectData(e.id, 'download');
        setShowMenuHeadVisible(true);
    };

    //重组分类数据
    const newMenuShopTypeData = async (rows: any, setData: any) => {
        let newSelectData: Array<any> = [];
        for (var j = 0; j < rows.length; j++) {
            newSelectData.push({
                value: rows[j].id,
                label: rows[j].shopTypeName,
                disabled: !rows[j].state
            })
        }
        setData(newSelectData);
    }

    //选择菜谱后查询分类
    const selectData = async (e: any, key: any) => {
        fetchApi.GET(wmService, {
            apiId: "menuShopType",
            apiExtend: 'showShopTypeList',
            apiData: {
                menuHeadId: e,
                // branchStoreId: localStorage.getItem("currentBranchStoreId"),
                branchStoreId: contextData?.currentBranchStoreId,
                mountDataOptions: {
                    userMount: {
                        isDept: true
                    }
                }
            }
        }).then(res => {
            if (res.success) {
                newMenuShopTypeData(res.data, setShopTypeData);
                if (key === 'copy') {
                    setIsSelect(true);
                }
            }
        })
    }

    //表头
    const columnsHead = [
        {
            title: '门店',
            dataIndex: 'branchStoreName',
            render: (text: any, record: any) => {
                return (
                    <div>{record.branchStore.brand.brandName + " " + record.branchStore.branchStoreName}</div>
                )
            }
        },
        {
            title: '菜谱名称',
            dataIndex: 'menuHeadName',
        },

        // sessionStorage.getItem('isAdmin') === 'true' ?
        //     {
        //         title: '添加菜式',
        //         dataIndex: 'addToMenu',
        //         key: 'addToMenu',
        //         render: (text: any, record: any) => {
        //             return <Space size="small">
        //                 <Button size={"small"} type="primary" onClick={() => handleWarehouseToMenu(record)}>添加菜式</Button>
        //             </Space>
        //         }
        //     } : {},

        {
            title: '查看菜式',
            dataIndex: 'showMenu',
            key: 'showMenu',
            render: (text: any, record: any) => {
                return <Space size="small">
                    <Button size={"small"} type="primary" onClick={() => handleShowMenu(record)}>查看菜式</Button>
                </Space>
            }
        },
        {
            title: '导出文档',
            dataIndex: 'downloadExcel',
            key: 'downloadExcel',
            render: (text: any, record: any) => {
                return <Space size="small">
                    <Button onClick={() => handleDownloadExcel(record)}>导出文档</Button>
                </Space>
            }
        },
        sessionStorage.getItem('isAdmin') === 'true' ?
            {
                title: '复制',
                dataIndex: 'copyMenuHead',
                render: (text: any, record: any) => {
                    return <Space size="small">
                        <Button size={"small"} type="primary" onClick={() => handleCopyMenuHead(record)}>从..菜谱复制</Button>
                    </Space>
                }
            } : {},
        {
            title: '排序',
            dataIndex: 'sortBy',
        },
        {
            title: '微信查看',
            dataIndex: 'isWxCheck',
            render: (text: any, record: any) => (
                <Switch checkedChildren="是" unCheckedChildren="否"
                    checked={text} key={record.id}
                    onChange={(e) => handleChange(record.id, 'isWxCheck', e)}
                />
            )
        }
    ];

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '菜谱类型',
        apiId: 'menuHead',
        showStates: true,
        showDefault: true,
        columns,
        pagination: true,
        // sendPageData: { branchStoreId: localStorage.getItem("currentBranchStoreId") },
        sendPageData: { branchStoreId: contextData?.currentBranchStoreId },
        extendIdName: "branchStoreId",
        EditComponent: MenuHeadEdit,
        mountDataOptions: {
            userMount: {
                isDept: true
            }
        }
    };

    //传关参数
    let sendData = {
        type: 'EDIT',
        key: '',
        menuHeadId: '',
        menuHeadName: '',
        brandId: '',
        brandName: '',
        branchStoreId: '',
        branchStoreName: ''
    }

    return (
        <>
            {/* <BranchStoreList onRefresh={onRefresh}></BranchStoreList>
            <br></br> */}
            <Modal
                title={(handleKey === 'copy' ? "复制" : "导出") + "所选分类的所有菜式"}
                open={showMenuHeadVisible}
                onOk={handleOk}
                onCancel={onCloseCopyMenuHead}
                cancelButtonProps={{ disabled: loadingData }}
                okButtonProps={{ disabled: loadingData }}
                closable={false}
                style={{ fontSize: '20px' }}
                width={500}
            >
                {loadingData ? (
                    <Spin size="large" tip="Loading...">
                        <Form
                            form={form}
                            name="Edit"
                            style={{ height: 450 }}
                        >
                            <Row gutter={[8, 0]}>
                                <Col span={handleKey === 'copy' ? 24 : 0}>
                                    <Form.Item
                                        label="选择菜谱"
                                        name="menuHeadId"
                                        rules={[{ required: handleKey === 'copy' ? true : false, message: '请选择菜谱!' }]}>
                                        <Select
                                            showSearch
                                            onSelect={(e) => selectData(e, 'copy')}
                                            disabled={isSelect}
                                            placeholder="选择菜谱"
                                            optionFilterProp="children"
                                            filterOption={(input, option: any) => {
                                                return true
                                            }}
                                            options={menuHeadData}
                                        >
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={0}>
                                    <Form.Item
                                        label="当前菜谱类型ID"
                                        name="currentMenuHeadId">
                                        <Input disabled={true} />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        label="选择分类"
                                        name="shopTypeIds"
                                        rules={[{ required: handleKey === 'copy' ? true : false, message: '请选择分类!' }]}>
                                        <Select
                                            mode="multiple"
                                            showSearch
                                            placeholder={handleKey === 'copy' ? "选择分类" : <span style={{ color: '#DD0' }}>如不选择分类则默认为全部分类</span>}
                                            optionFilterProp="children"
                                            filterOption={(input, option: any) => {
                                                return true
                                            }}
                                            options={shopTypeData}
                                        >
                                            {/*没有重组数据前使用这种
                                    {getList.generalEx(shopTypeData, {
                                        itemName: "shopTypeName",
                                        includeNull: false
                                    })} */}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Spin>
                ) :
                    (
                        <Form
                            form={form}
                            name="Edit"
                            style={{ height: 450 }}
                        >
                            <Row gutter={[8, 0]}>
                                <Col span={handleKey === 'copy' ? 24 : 0}>
                                    <Form.Item
                                        label="选择菜谱"
                                        name="menuHeadId"
                                        rules={[{ required: handleKey === 'copy' ? true : false, message: '请选择菜谱!' }]}>
                                        <Select
                                            showSearch
                                            onSelect={(e) => selectData(e, 'copy')}
                                            disabled={isSelect}
                                            placeholder="选择菜谱"
                                            optionFilterProp="children"
                                            filterOption={(input, option: any) => {
                                                return true
                                            }}
                                            options={menuHeadData}
                                        >
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={0}>
                                    <Form.Item
                                        label="当前菜谱类型ID"
                                        name="currentMenuHeadId">
                                        <Input disabled={true} />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        label="选择分类"
                                        name="shopTypeIds"
                                        rules={[{ required: handleKey === 'copy' ? true : false, message: '请选择分类!' }]}>
                                        <Select
                                            mode="multiple"
                                            showSearch
                                            placeholder={handleKey === 'copy' ? "选择分类" : <span style={{ color: '#DD0' }}>如不选择分类则默认为全部分类</span>}
                                            optionFilterProp="children"
                                            filterOption={(input, option: any) => {
                                                return true
                                            }}
                                            options={shopTypeData}
                                        >
                                            {/*没有重组数据前使用这种
                                    {getList.generalEx(shopTypeData, {
                                        itemName: "shopTypeName",
                                        includeNull: false
                                    })} */}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    )}
            </Modal>
            <br></br>
            <WebUniversalNewEx props={extendProps} ref={ref} />

            {warehouseToMenuVisible
                ? (<WarehouseToMenu
                    closeModal={onCloseWarehouseToMenu} data={menuData} />)
                : null}

            {showMenuVisible
                ? (<ShowMenu
                    closeModal={setShowMenuVisible} data={menuData} />)
                : null}

        </>
    )
}