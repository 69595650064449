import { Col, Form, Input, InputNumber, Radio, Row, Select, Space, Switch } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react'
import fetchApi from '../../../utils/api/fetchApi';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';
import getList from '../../universal/getList';
import systemConfig from '../../../config/systematic';
import { SocketContext } from '../../../App';

//布局
const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 16 },
};

export default function MenuSpecEdit(props: any) {

    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>(undefined);

    /** 菜谱数据 */
    const [menuData, setMenuData] = useState<any>();

    /** 是否需要创建特殊规格新数据 */
    const [specialData, setSpecialData] = useState<any>(99);

    /** 是否特殊规格 */
    const [specialState, setSpecialState] = useState<any>(true);

    /** 是否强制数量 */
    const [mandatoryState, setMandatoryState] = useState<any>(false);

    /** 是否必选 */
    const [requiredState, setRequiredState] = useState<any>(true);

    /** 是否阶段性打折 */
    const [periodDiscountState, setPeriodDiscountState] = useState<any>(false);

    /** 是否需要创建新数据 */
    const [newData, setNewData] = useState<any>(true);

    /** 是否可以改价格 */
    const [priceData, setPriceData] = useState<any>(false);

    /** 是否可以改默认状态 */
    const [defaultState, setDefaultState] = useState<any>(false);

    /** 是否显示选择数量 */
    const [chooseNumDisable, setChooseNumDisable] = useState<any>(false);

    /** 设置最大数量 */
    const [dataMax, setDataMax] = useState<any>(1);

    /** 门店菜式仓库规格 */
    const [branchStoreMenuWarehouseSpecData, setBranchStoreMenuWarehouseSpecData] = useState<any>([]);

    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;

    const useContextData = useContext(SocketContext);
    const { contextData } = useContextData;

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
            ref.current.row.then(async (res: any) => {
                if (typeof res === 'undefined') { return }
                if (res.success === true) {
                    if (res.data.isSpecial) {
                        setSpecialState(true);
                        setPriceData(true);
                        setRequiredState(!res.data.isSpecial);
                        setMandatoryState(res.data.isSpecial);
                        setPeriodDiscountState(true);
                        setChooseNumDisable(true);
                        setDataMax(res.data.menuSpecSpecials.length);
                        setDefaultState(res.data.isSpecial);
                    }
                }
            })
        }
        const fetchData = async () => {

            if (data.type === 'EDIT') {
                setSpecialState(true);
            }

            form.setFieldsValue({ menuId: data.sendPageData.menuId });

            fetchApi.GET(wmService, {
                apiId: 'menu',
                apiExtend: 'showList',
                apiData: {
                    // branchStoreId: localStorage.getItem("currentBranchStoreId"),
                    branchStoreId: contextData?.currentBranchStoreId,
                    mountDataOptions: {
                        userMount: {
                            isBranchStore: true
                        }
                    }
                }
            }).then(res => {
                if (res.success) {
                    setMenuData(res.data);
                }
            });

            fetchApi.GET(wmService, {
                apiId: 'branchStoreMenuWarehouseSpec',
                apiExtend: 'checkSpec',
                branchStoreMenuWarehouseId: data.sendPageData.branchStoreMenuWarehouseId,
                menuId: data.sendPageData.menuId
            }).then(res => {
                if (res.success) {
                    setBranchStoreMenuWarehouseSpecData(res.data);
                }
            });

            // fetchApi.GET(wmService, {
            //     apiId: 'menuSpec',
            //     apiExtend: 'showSingle',
            //     apiVariable: data.id,
            // }).then(res => {
            //     if (res.success) {
            //         if (res.data.menuSpecSpecials.length) {
            //             setDataMax(res.data.menuSpecSpecials.length);
            //         }
            //     }
            // });
        }
        fetchData()
    }, [])

    const onChangeRadio = (e: any) => {
        setNewData(!newData)
        // let branchStoreMenuWarehouseSpecName: any;
        // for (var i_data = 0, len_data = branchStoreMenuWarehouseSpecData.length; i_data < len_data; i_data++) {
        //     if (branchStoreMenuWarehouseSpecData[i_data].id === e.target.value) {
        //         branchStoreMenuWarehouseSpecName = branchStoreMenuWarehouseSpecData[i_data].branchStoreMenuWarehouseSpecName;
        //     }
        // }
        // if (e.target.value === "999") {
        //     setSpecialData(0);
        //     form.setFieldsValue({ menuSpecName: "" });
        // }
        // else {
        //     form.setFieldsValue({ menuSpecName: branchStoreMenuWarehouseSpecName });
        // }
    };

    const updateData = (value: any, row: any) => {

        let newDatas: any = [];
        if (value === 0) {
            row.map((item: any) => {
                if (item.isSpecial === true) {
                    newDatas.push(item);
                    setDefaultState(true);
                }
            })
        }
        if (value === 1) {
            row.map((item: any) => {
                if (item.isSpecial === false) {
                    newDatas.push(item);
                    setDefaultState(false);
                }
            })
        }
        setBranchStoreMenuWarehouseSpecData(newDatas);
    };

    const onChangeSpecial = (e: any) => {
        updateData(e.target.value, branchStoreMenuWarehouseSpecData);
        if (e.target.value === 0) {
            // form.setFieldsValue({ price: 0 });
            // form.setFieldsValue({ promotionPrice: 0 });
            // form.setFieldsValue({ limit: 99 });

            setPriceData(true);
            form.setFieldsValue({ isSpecial: true });
            setSpecialState(true);
        }
        setSpecialData(e.target.value);
    };

    const onSelectData = (data: any) => {

        form.setFieldsValue({ menuSpecName: data.branchStoreMenuWarehouseSpecName });
        form.setFieldsValue({ otherFoodName: data.otherFoodName });
        form.setFieldsValue({ price: data.price });
        form.setFieldsValue({ promotionPrice: data.price });
        form.setFieldsValue({ isSpecial: data.isSpecial });
        form.setFieldsValue({ limit: 99 });
        form.setFieldsValue({ sortBy: data.sortBy });
        form.setFieldsValue({ isUse: data.isUse });

        if (data.isSpecial) {
            fetchApi.GET(wmService, {
                apiId: 'branchStoreMenuWarehouseSpec',
                apiExtend: 'showSingle',
                apiVariable: data.id,
            }).then(res => {
                if (res.success) {
                    if (res.data.branchStoreMenuWarehouseSpecSpecials.length) {
                        form.setFieldsValue({ chooseNum: res.data.branchStoreMenuWarehouseSpecSpecials.length });
                        setDataMax(res.data.branchStoreMenuWarehouseSpecSpecials.length);
                    }
                }
            });

            setRequiredState(!data.isSpecial);
            setMandatoryState(data.isSpecial);
            setPeriodDiscountState(data.isSpecial);
            setChooseNumDisable(true);
        }
    };

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };

    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    const samePrice = (e: any) => {
        form.setFieldsValue({ promotionPrice: e });
        form.setFieldsValue({ limit: 99 });
    }

    const changePrice = (e: any) => {
        if (form.getFieldsValue()['price'] < e) {
            form.setFieldsValue({ limit: 99 });
            form.setFieldsValue({ promotionPrice: form.getFieldsValue()['price'] });
        }
        else {
            if (form.getFieldsValue()['price'] !== e) {
                form.setFieldsValue({ limit: 1 });
            }
            else {
                form.setFieldsValue({ limit: 99 });
            }
        }
    }

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'menuSpec'
    };

    return (
        <>
            <WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? specialData === 99 ? '选择需要添加规格的类型' : newData ? '选择或填写菜式规格' : '新增菜式规格' : '编辑菜式规格')}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={800}
            >
                <Form
                    {...layout}
                    form={form}
                    initialValues={{ "sortBy": 999 }}
                    name="Edit"
                    onFinish={handleOk}
                    onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}
                >
                    <Row gutter={[8, 0]}>
                        <Col span={data.type === "NEW" && newData && specialData === 99 ? 24 : 0}>
                            <Row gutter={[8, 0]}>
                                <Col span={24}>
                                    <Form.Item
                                        name="specialId"
                                    >
                                        <Radio.Group onChange={onChangeSpecial}>
                                            <Space direction="vertical">
                                                <Radio
                                                    key={1}
                                                    value={0}
                                                >
                                                    选择并添加特殊规格(如选粉、选汤、选甜度、选冷热...)
                                                </Radio>
                                                <Radio
                                                    key={0}
                                                    value={1}
                                                >
                                                    选择并添加常规规格(如大份、小份、位、份...)
                                                </Radio>
                                            </Space>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row gutter={[8, 0]}>
                        <Col span={data.type === "NEW" && newData && (specialData === 1 || specialData === 0) ? 24 : 0}>
                            <Row gutter={[8, 0]}>
                                <Col span={24}>
                                    <Form.Item
                                        name="branchStoreMenuWarehouseSpecId"
                                    >
                                        <Radio.Group onChange={onChangeRadio}>
                                            <Space direction="vertical">
                                                {
                                                    branchStoreMenuWarehouseSpecData.map((item: any, index: any) => {
                                                        return (
                                                            <>
                                                                <Radio
                                                                    key={item.id}
                                                                    disabled={item.state}
                                                                    value={item.id}
                                                                    onChange={() => onSelectData(item)}
                                                                //onChange={(e) => onChangeRadio(e, item.id)}
                                                                >
                                                                    {item.branchStoreMenuWarehouseSpecName + (item.isUse ? "  【当前门店规格为：启用】" : "  【当前门店规格为：关闭】")}
                                                                </Radio>
                                                            </>
                                                        )
                                                    })
                                                }
                                                {/* <Radio
                                                    key={999}
                                                    value="999"
                                                >
                                                    列表没有想要的,我需要重新添加
                                                </Radio> */}
                                            </Space>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row gutter={[8, 0]}>
                        <Col span={data.type === "EDIT" ? 24 : (newData && (specialData === 99 || specialData === 1 || specialData === 0)) ? 0 : 24}>
                            <Row gutter={[8, 0]}>
                                <Col span={24}>
                                    <Form.Item
                                        label="id"
                                        name="id">
                                        <Input disabled={true} />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        label="菜式名称"
                                        name="menuId">
                                        <Select
                                            disabled
                                            showSearch
                                            placeholder="菜式名称"
                                            optionFilterProp="children"
                                            filterOption={(input, option: any) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                            {getList.general(menuData, 'foodName')}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        label="扩展名称"
                                        name="otherFoodName"
                                        tooltip="如需这样显示：杯(450ML)的话，“杯”就是规格，“450ML”就是扩展名称。"
                                    >
                                        <Input placeholder='扩展菜名(当遇另类规格时使用)!' />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        label="规格"
                                        name="menuSpecName"
                                        rules={[{ required: true, message: '请输入规格!' }]}>
                                        <Input disabled={true} />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        label="价格"
                                        name="price"
                                        rules={[{ required: true, message: '请输入价格!' }]}>
                                        <InputNumber onChange={samePrice} disabled={priceData} min={0} />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        label="活动价"
                                        name="promotionPrice"
                                        rules={[{ required: true, message: '请输入活动价!' }]}>
                                        <InputNumber onChange={changePrice} disabled={priceData} min={0} />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        label="限购数量"
                                        name="limit"
                                        rules={[{ required: true, message: '请输入限购数量!' }]}>
                                        <InputNumber disabled={priceData} min={1} />
                                    </Form.Item>
                                </Col>

                                <Col span={data.sendPageData.foodType === 'setMeal' && chooseNumDisable ? 24 : 0}>
                                    <Form.Item
                                        label="可选数量(几选几)"
                                        name="chooseNum"
                                        rules={[{ required: data.sendPageData.foodType === 'setMeal' ? true : false, message: '请输入可选数量!' }]}>
                                        <InputNumber min={1} max={dataMax} />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        label="排序"
                                        name="sortBy">
                                        <InputNumber min={1} />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        label="特殊属性"
                                        name="isSpecial"
                                        valuePropName="checked">
                                        <Switch
                                            disabled={specialState}
                                            checkedChildren="是"
                                            unCheckedChildren="否" />
                                    </Form.Item>
                                </Col>

                                <Col span={data.sendPageData.foodType === 'setMeal' ? 0 : 24}>
                                    <Form.Item
                                        label="强制数量"
                                        name="isMandatory"
                                        tooltip="当遇到规格为【斤】时，如果特殊规格是需要加收的话，这里选择“强制”后，会强制所有特殊规格是收1份的价格，并不会乘以斤数。例如：象拔蚌是1.5斤，其中蚌胆滚粥(特殊规格)是49元/份，这里选择强制，这里就加收49，如果不选择强制，就会加收73.5。"
                                        valuePropName="checked"
                                        initialValue={false}>
                                        <Switch
                                            disabled={mandatoryState}
                                            checkedChildren="强制"
                                            unCheckedChildren="不强制" />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        label="阶段性打折"
                                        name="isPeriodDiscount"
                                        valuePropName="checked"
                                        initialValue={false}>
                                        <Switch
                                            disabled={periodDiscountState}
                                            checkedChildren="是"
                                            unCheckedChildren="否" />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        label="必选"
                                        name="isRequired"
                                        valuePropName="checked"
                                        initialValue={true}>
                                        <Switch
                                            disabled={requiredState}
                                            checkedChildren="是"
                                            unCheckedChildren="否" />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        label="客服专用"
                                        name="isStaff"
                                        valuePropName="checked"
                                        initialValue={false}>
                                        <Switch
                                            // disabled={requiredState}
                                            checkedChildren="是"
                                            unCheckedChildren="否" />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        label="默认显示"
                                        name="isDefault"
                                        valuePropName="checked"
                                        initialValue={false}>
                                        <Switch
                                            disabled={defaultState}
                                            checkedChildren="是"
                                            unCheckedChildren="否" />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        label="外部显示默认"
                                        name="isExternalDefault"
                                        valuePropName="checked"
                                        initialValue={false}>
                                        <Switch
                                            checkedChildren="是"
                                            unCheckedChildren="否" />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        label="状态"
                                        name="isUse"
                                        valuePropName="checked"
                                        initialValue={true}>
                                        <Switch checked={true}
                                            checkedChildren="启用"
                                            unCheckedChildren="关闭" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}