import { Form, Row, Col, Select, Button, Anchor } from 'antd';
import { useEffect, useState } from 'react';
import fetchApi from '../../../utils/api/fetchApi';
import systemConfig from '../../../config/systematic';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import getList from '../../universal/getList';
import antMessage from '../../../utils/extend/AntdNotification'
import stringHandle from '../../../utils/baseLib/stringHandle';
import { showCustomNotification, useNotificationInstance } from '../../../utils/extend/AntdNotificationEx';

/** 后端数据defaultService */
const defaultService = systemConfig.wmService;

/**
 * 系统配置
 * @returns 
 */
const Configs = () => {
    const [form] = Form.useForm(); //form 
    const [modalVisible, setModalVisible] = useState(true);
    // const [filesGroupData, setFilesGroupData] = useState({});
    const [nodesData, setNodesData] = useState([]);
    const [divCmpt, setDivCmpt] = useState<any>();
    const [buttonCmpt, setButtonCmpt] = useState<any>();
    /** 保存数据方法 */
    var saveFunction: Function;
    const notificationApi = useNotificationInstance()

    useEffect(() => {
        //同步获取数据 async - await
        const fetchData = async () => {
            let arr: any = [];


            arr = arr.concat({
                key: 'users',
                href: '#users',
                title: '用户设置',
                id: 'users'
            })
            setNodesData(arr);
        }
        fetchData();
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    //确定
    const handleOk = async (e: any) => {
        setModalVisible(false)
    };

    /**
     * 初始化组件
     * @param nodeId 节点id
     */
    const getDivComponent = async (nodeId: any) => {

        nodeId = stringHandle.removeExtension(nodeId, '#', 3);

        switch (nodeId) {


            case 'users': //文件配置节点

                let entrepotData;
                // let areaData;
                try {
                    const entrepotRes = await fetchApi.GET(defaultService, {
                        apiId: 'entrepot',
                        apiExtend: 'showListEx',
                        apiData:{
                            mountDataOptions:{
                                userMount: {
                                    // isEmployees: true,
                                    // isDept:true,
                                    isUserEntrepots:true
                                }
                               },
                        }
                    });
                    if (entrepotRes.success === true) {

                        entrepotData = entrepotRes.data;
                    }
                    // const areaRes = await fetchApi.GET(defaultService, {
                    //     apiId: 'area',
                    //     apiExtend: 'showListEx'
                    // });
                    // if (areaRes.success === true) {

                    //     areaData = areaRes.data;
                    // }
                }
                catch (e) {
                    console.log("e", e);
                }
                //2) 生成组件
                // setDivCmpt(<div id="users">
                //     <Form
                //         form={form}
                //         name="Edit">
                //         <Row gutter={[8, 0]}>
                //             {/* 以下可以修改 */}
                //             <Col span={12}>
                //                 <Form.Item
                //                     label="默认仓库"
                //                     name="entrepotId">
                //                     <Select
                //                         showSearch
                //                         placeholder="默认仓库"
                //                         optionFilterProp="children"

                //                         filterOption={(input, option: any) =>
                //                             option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                //                         {getList.general(entrepotData, 'entrepotName', "entrepotId")}
                //                     </Select>
                //                 </Form.Item>
                //             </Col>

                //             {/* <Col span={12}>
                //                 <Form.Item
                //                     label="默认区域"
                //                     name="areaId">
                //                     <Select
                //                         showSearch
                //                         placeholder="默认区域"
                //                         optionFilterProp="children"
                                        
                //                         filterOption={(input, option: any) =>
                //                             option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                //                         {getList.general(areaData, 'areaName',"areaId")}
                //                     </Select>
                //                 </Form.Item>
                //             </Col> */}




                //             {/* --- */}
                //         </Row>
                //     </Form>
                // </div>);
                //3) 获取节点内容
                fetchApi.GET(defaultService,
                    {
                        apiId: 'configNodes',
                        apiExtend: 'getConfig',
                        apiData: {
                            id: 'config',
                            nodeId,
                            isUser: true

                        }
                    }).then(res => {

                        if (res.success) {
                            const { users } = res.data
                            try {
                                const { entrepotId,
                                    // areaId
                                } = users;
                                //以下可以修改
                                form.setFieldsValue({
                                    'entrepotId': entrepotId,
                                    // 'areaId':areaId

                                });
                                //--------
                            }
                            catch (err: any) {
                                 showCustomNotification(notificationApi!, {
                                    type: 'error',
                                    message: '无法获取数据！',
                                    description: err.message,
                                });;
                            }
                        }
                    }).catch(err => {
                         showCustomNotification(notificationApi!, {
                                    type: 'error',
                                    message: '无法获取数据！',
                                    description: err.message,
                                });;
                    });
                //4) 保存数据
                saveFunction = () => {
                    /** 节点对象 */
                    const nodeObj = {
                        //以下可以修改
                        users: {
                            entrepotId: form.getFieldValue('entrepotId'),

                        }
                    }
                    fetchApi.PUT(defaultService,
                        {
                            apiId: 'configNodes',
                            apiExtend: 'setConfig',
                            apiData: {
                                id: 'config',
                                configName: '用户配置',
                                content: nodeObj,
                                isUser: true
                            }
                        }).then(res => {
                            if (res.success) {
                                fetchApi.GET(defaultService,
                                    {
                                        apiId: 'configNodes',
                                        apiExtend: 'getConfig',
                                        apiData: {
                                            id: 'config',
                                            nodeId,
                                            isUser: true

                                        }
                                    }).then((e) => {
                                        const {users}=e.data;
                                        sessionStorage.setItem("entrepotName", users.entrepotName)
                                        sessionStorage.setItem("userName", e.data.userName)
                                    })

                                 showCustomNotification(notificationApi!, {
                                    type: 'success',
                                    message: '保存数据成功！',
                                    description: 'success',
                                });;
                                setModalVisible(false);
                            }
                        }).catch(err => {
                             showCustomNotification(notificationApi!, {
                                    type: 'error',
                                    message: '保存数据失败！',
                                    description: err.message,
                                });
                        });
                }
                // setButtonCmpt(
                //     <Button onClick={() => saveFunction()}>保存数据</Button>
                // )
                break;
            default:
                setDivCmpt(undefined);
                setButtonCmpt(undefined);
                break;
        }
    };

    return (
        <>
            <AntDraggableModal
                title={'用户配置'}
                open={modalVisible}
                okText='关闭'
                onOk={handleOk}
                onCancel={handleOk}
                cancelButtonProps={{ style: { display: "none" } }}
                closable={false}
                maskClosable={true}
                width={800}>
                <Anchor
                    affix={true}
                    direction='horizontal'
                    items={nodesData}
                    onChange={getDivComponent}
                />
                <br />
                <div id='main'>
                    {divCmpt}
                    {buttonCmpt}
                </div>
            </AntDraggableModal>
        </>
    )
};

/**
 * 获取默认文件服务器
 */
Configs.getDefaultFilesServer = async () => {
    let res: any = {
        success: false,
        data: undefined
    };
    const configNodesRes = await fetchApi.GET(defaultService,
        {
            apiId: 'configNodes',
            apiExtend: 'getConfig',
            apiData: {
                id: 'config',
                nodeId: 'general'
            }
        })
    if (configNodesRes.success === true) {
        const { filesServerId } = configNodesRes.data;
        const filesServerRes = await fetchApi.GET(defaultService,
            {
                apiId: 'filesServer',
                apiVariable: filesServerId
            })
        res = filesServerRes
    }
    return res;
}

export default Configs;
