import { columnsheadId } from '../../universal/columnshead';
import WebUniversalNewEx, { initDataType, WUExtendPropsType } from '../../system/WebUniversalNewEx';
import ShopTypeEdit from './ShopTypeEdit';
import { Button, Space, Switch, Tooltip } from 'antd';
import MenuSalePeriod from '../menu/MenuSalePeriod';
import { useContext, useEffect, useRef, useState } from 'react';
import { SocketContext } from '../../../App';

export default function ShopType(props: any) {

    const ref = useRef<any>(undefined);

    //销售周期窗口
    const [menuSalePeriodVisible, setMenuSalePeriodVisible] = useState(false);

    //销售周期数据
    const [menuSalePeriodData, setMenuSalePeriodData] = useState<any>();

    /** api服务id */
    const apiId = 'shopType';

    const useContextData = useContext(SocketContext);
    const { contextData, setContextData } = useContextData;

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            // handleChangeOne = ref.current.handleChangeOne;
        }

        //同步获取数据 async - await
        const fetchData = async () => {
        }

        if (contextData?.currentBranchStoreId) {
            if (contextData?.refresh) {
                onRefresh({ branchStoreId: contextData?.currentBranchStoreId })
            }
        }

        fetchData();
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contextData?.currentBranchStoreId]); //初始化数据

    const onRefresh = (item: any) => {
        const { branchStoreId } = item;
        const currentData: initDataType = {
            apiId,
            sendPageData: {
                branchStoreId
            }
        }
        ref.current.initData(currentData);

        setContextData({ ...contextData, refresh: false })
    }

    //表头
    const columnsHead = [
        {
            title: '门店',
            dataIndex: 'branchStoreName',
            render: (text: any, record: any) => {
                return (
                    <div>{record.shopCategory.branchStore.brand.brandName + " " + record.shopCategory.branchStore.branchStoreName}</div>
                )
            }
        },
        {
            title: '大分类',
            dataIndex: 'shopCategoryName',
            render: (text: any, record: any) => {
                return (
                    <div>{record.shopCategory.shopCategoryName}</div>
                )
            }
        },
        {
            title: '小分类名称',
            dataIndex: 'shopTypeName',
        },
        {
            title: '小分类英文',
            dataIndex: 'shopTypeNameEn',
        },
        {
            title: '销售周期',
            dataIndex: 'menuSalePeriod',
            key: 'menuSalePeriod',
            render: (text: any, record: any) => {
                return (
                    <Space size="small">
                        <Button size={"small"} type="primary" onClick={() => handleMenuSalePeriod(record)}>销售周期</Button>
                    </Space>
                )
            }
        },
        {
            title: '客服专用',
            dataIndex: 'isStaff',
            render: (text: any, record: any) => (
                <Switch checkedChildren="是" unCheckedChildren="否"
                    checked={text} key={record.id}
                // onChange={(e) => handleChange(record.id, 'isStaff', e)}
                />
            )
        },
        {
            title: '打折',
            dataIndex: 'isDiscount',
            render: (text: any, record: any) => {
                if (record.isDiscount) {
                    return (
                        <Tooltip placement="topLeft" title={'当前折扣为 ' + record.maxDiscount} color='red'>
                            <Switch checkedChildren="是" unCheckedChildren="否"
                                checked={text} key={record.id}
                            />
                        </Tooltip>
                    )
                }
                else {
                    return (
                        <Switch checkedChildren="是" unCheckedChildren="否"
                            checked={text} key={record.id}
                        />
                    )
                }
            }
        },
        // {
        //     title: '最高折扣',
        //     dataIndex: 'maxDiscount',
        // },
        // {
        //     title: '排序',
        //     dataIndex: 'sortBy',
        // },
    ];

    //打开销售时段页面
    const handleMenuSalePeriod = (record: any) => {
        //console.log(record)
        sendData.shopTypeId = record.id;
        setMenuSalePeriodData(sendData);
        setMenuSalePeriodVisible(true);
    };

    //关闭销售时段页面
    const onCloseMenuSalePeriod = async (status: boolean) => {
        setMenuSalePeriodVisible(false);
        // if (status) {
        //     await initData();
        // }
    }

    const columns = columnsheadId
        .concat(columnsHead as []);

    //传关参数
    let sendData = {
        type: 'EDIT',
        shopTypeId: ''
    }

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '菜谱分类',
        apiId: 'shopType',
        showStates: true,
        columns,
        pagination: true,
        EditComponent: ShopTypeEdit,
        // sendPageData: { branchStoreId: localStorage.getItem("currentBranchStoreId") },
        sendPageData: { branchStoreId: contextData?.currentBranchStoreId },
        mountDataOptions: {
            userMount: {
                isDept: true
            }
        }
    };

    return (
        <>
            <WebUniversalNewEx props={extendProps} ref={ref}></WebUniversalNewEx>

            {
                menuSalePeriodVisible
                    ? (<MenuSalePeriod
                        closeModal={onCloseMenuSalePeriod} data={menuSalePeriodData} />)
                    : null
            }
        </>
    )
}