import fetchApi from '../utils/api/fetchApi';
import systematic from './systematic';

/** resType数据的类型 */
declare type resType = {
    /** 默认文件服务id */
    serviceId?: string,
    /** api服务Url */
    apiServiceUrl?: string,
    /** 管理服务Url */
    manageServiceUrl?: string,
    /** 根目录 */
    rootDirectory?: string,
    /** 文件服务器名称 */
    serverName?: string,
    /** 文件服务器地址 */
    url?: string
};

/**
 * configNodesApiData 类型
 */
declare type configNodesApiDataType = {
    /** id */
    id: string,
    /** 节点id */
    nodeId?: string
};

/** 系统配置扩展 */
const systemExpand = async () => { };

/**
 * 通用服务
 * @param apiData configNodes apiData 数据
 * @returns 
 */
systemExpand.getConfig = async (apiData: configNodesApiDataType) => {
    let res: resType = {};
    const wmService = systematic.wmService;
    const generalObj = await fetchApi.GET(wmService,
        {
            apiId: 'configNodes',
            apiExtend: 'getConfig',
            apiData
        })
    if (generalObj.success === true) {
        res = generalObj.data;
    }
    return res;
};

/** 文件服务 */
systemExpand.filesServer = async () => {
    // let res: resType = {};
    const wmService = systematic.wmService;
    return await fetchApi.GET(wmService,
        {
            apiId: 'configNodes',
            apiExtend: 'defaultFilesServer'
        })
    // if (generalObj.success === true) {
      
        
    //     res = generalObj.data;
    // }
    // return res;
};

/** 文件服务 */
systemExpand.filesServer2 = async () => {
    let res: resType = {};
    const wmService = systematic.wmService;
    const generalObj = await fetchApi.GET(wmService,
        {
            apiId: 'configNodes',
            apiExtend: 'defaultFilesServer'
        })
    if (generalObj.success === true) {
      
        
        res = generalObj.data;
    }
    return res;
};
/** 文件服务 */
systemExpand.filesServerEx = async (fileType:string) => {
    let res: resType = {};
    const wmService = systematic.wmService;
    
    const generalObj = await fetchApi.GET(wmService,
        {
            apiId: 'configNodes',
            apiExtend: 'defaultFilesServerEx',
            apiData:{
                fileType
            }
        })
    
    if (generalObj.success === true) {
        
        res = generalObj.data;
    }
    return res;
};
export default systemExpand;