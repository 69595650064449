import { useRef, useState } from 'react';
import WebUniversal, { WUExtendPropsType } from '../../../system/WebUniversalNewEx'
import { columnsheadId } from '../../../universal/columnshead';
import { Button, Tooltip } from 'antd';
import objectHandle from '../../../../utils/baseLib/objectHandle';
import systemConfig from '../../../../config/systematic';
import MqttOnlineTopic from './MqttOnlineTopic';
import AntDraggableModal from '../../../../utils/extend/AntDraggableModal';
import fetchApi from '../../../../utils/api/fetchApi';

/** 组件 */
const WebComponent = (props: any) => {
    const ref = useRef<any>(undefined);
    const [modalMqttOnlineTopic, setModalMqttOnlineTopic] = useState(false);
    const [mqttOnlineTopicData, setMqttOnlineTopicData] = useState<any>();

    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;

    /**
     * 发布
     * @param deviceId 设备id
     * @param mqttTopicId mqtt主题id
     */
    const mqttPublish = (deviceId: string, mqttTopicId: string) => {
        fetchApi.POST(wmService,
            {
                apiId: 'mqttOnline',
                apiExtend: 'publish',
                apiData: {
                    deviceId,
                    mqttTopicId
                }
            }).then(res => {
                console.log(res);

            });
    };

    /** 表头内容 */
    const columnsHead = [
        {
            title: 'mqttClientId',
            dataIndex: 'mqttClientId',
            render: (text: any) => <Tooltip placement="topLeft" title={text}>{
                (typeof text !== "undefined" && text !== null) ?
                    (text.length >= 8 ? text.substring(0, 8) + '......' : text)
                    : ''
            }</Tooltip>
        },
        {
            title: '主题',
            dataIndex: 'mqttTopic',
            render: (text: any) => (typeof text !== 'undefined' ? text.topicName : '')
        },
        {
            title: '唯一',
            dataIndex: 'isUniqueTopic',
            render: (text: any) => {
                return (<>
                    {text === true ?
                        <Tooltip placement="topLeft"
                            title={`唯一主题，如果: 是，topic由'<mqttTopicId>/<mqttClientId>'组成，默认：true`}>
                            <p style={{ color: 'yellow' }}>是</p> </Tooltip> : '否'}
                </>)
            }
        },
        {
            title: 'qos',
            dataIndex: 'qos',
            render: (text: any) => <Tooltip placement="topLeft" title={'服务质量，0（最多一次）; 1（至少一次）; 2（恰好一次）'}>{text}</Tooltip>
        },
        {
            title: '版本',
            dataIndex: 'clientVersion',
            render: (text: any) => <Tooltip placement="topLeft" title={'客户连接mqtt服务器的版本'}>{text}</Tooltip>
        },
        {
            title: '用户',
            dataIndex: 'user',
            render: (text: any) => {
                const userName = text ? text.userName : '';
                return (<Tooltip placement="topLeft" title={userName}>{
                    (objectHandle.isEmpty(userName) !== true) ?
                        (userName.length >= 4 ? userName.substring(0, 4) + '....' : userName)
                        : ''
                }</Tooltip>)
            }
        },
        {
            title: '客户',
            dataIndex: 'clientUser',
            render: (text: any) => (text ? text.userName : '')
        },
        {
            title: '设备',
            dataIndex: 'device',
            render: (text: any) => {
                const deviceName = text ? text.deviceName : '';
                return (<Tooltip placement="topLeft" title={deviceName}>{
                    (objectHandle.isEmpty(deviceName) !== true) ?
                        (deviceName.length >= 4 ? deviceName.substring(0, 4) + '....' : deviceName)
                        : ''
                }</Tooltip>)
            }
        },
        {
            title: '断开连接',
            // dataIndex: 'listenerId'
            render: (text: any, record: any) => (<Button>断开连接</Button>)
        },
        {
            title: '测试',
            dataIndex: 'deviceId',
            render: (text: any, record: any) => (<Button onClick={() => mqttPublish(text, record.mqttTopicId)}>测试</Button>)
        },
        {
            title: '信息',
            dataIndex: 'id',
            render: (text: any) => (<Button type='primary' onClick={
                () => {
                    setMqttOnlineTopicData({
                        mqttOnlineId: text
                    });
                    setModalMqttOnlineTopic(true);
                }
            }>信息</Button>)
        }
    ];

    /** 表头 */
    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: 'mqtt在线',
        apiId: 'mqttOnline',
        columns,
        showStates: true,
        pagination: true,
        showAdd: false,
        showEdit: false
    };

    return (
        <>
            <WebUniversal props={extendProps} ref={ref} />
            {modalMqttOnlineTopic ? (
                <AntDraggableModal
                    title={`mqtt在线主题`}
                    open={true}
                    okText='关闭'
                    cancelButtonProps={{ style: { display: "none" } }}
                    onOk={() => { setModalMqttOnlineTopic(false); }}
                    closable={false}
                    maskClosable={false}
                    width={1080}
                > <MqttOnlineTopic data={mqttOnlineTopicData} />
                </AntDraggableModal>)
                : null}
        </>
    )
}

export default WebComponent;
