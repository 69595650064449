import systemConfig from '../../../config/systematic';
import { Button, Col, DatePicker, Form, Input, Row, Select, Space, Table, Tooltip } from 'antd';
import React, { useContext, useEffect, useState } from 'react'
import getList from '../../universal/getList';
import fetchApi from '../../../utils/api/fetchApi';
import dayjs from 'dayjs';
import antMessage from '../../../utils/extend/AntdNotification'
import DownloadExcel from '../../report/DownloadExcel';
import { QuestionCircleFilled } from '@ant-design/icons';
import { SocketContext } from '../../../App';
import { showCustomNotification, useNotificationInstance } from '../../../utils/extend/AntdNotificationEx';
const { RangePicker } = DatePicker;

/**出品部门 */
export default function MakeDept(props: any) {
  /** 后端数据wmService */
  let wmService: any = systemConfig.wmService;
  const [form] = Form.useForm();
  const [sbData, setSbData] = useState<any>();

  const [sbName, setSbName] = useState<string>("所有");
  const [rows, setRows] = useState<Array<any>>([]);
  const [deptData, setDeptData] = useState<Array<any>>();
  const [userName, setUserName] = useState<string>("")
  const [caCoMonStatus, setCaCoMonStatus] = useState<Array<any>>();
  const [caCoMonStatusName, setCaCoMonStatusName] = useState<string>("所有");
  const useContextData = useContext(SocketContext);
  const { contextData,setContextData } = useContextData;
  const notificationApi = useNotificationInstance()

  useEffect(() => {
    const fetchData = async () => {
      form.setFieldsValue({
        "chooseTime": [dayjs(), dayjs()]
      })

      fetchApi.GET(wmService,
        {
          apiId: 'generalData',
          apiVariable: 'caCoMonStatus@dataStatusType',
          apiExtend: 'showLists'
        }).then(res => {
          if (res.success) {
            const { caCoMonStatus } = res.data
            setCaCoMonStatus(caCoMonStatus);
          }
        })
        if (contextData?.currentBranchStoreId) {
          if (contextData?.refresh) {
            await initArgument();
  
          }else{
            await initArgument();
          }
        }
      // 
    }
    fetchData()
   
  }, [contextData?.currentBranchStoreId]);
  const initArgument = async () => {
     
    let sb = await fetchApi.GET(wmService, {
      apiId: "sb",
      apiExtend: 'getBrandStoreIdToSb',
      apiData: {
        branchStoreId: contextData?.currentBranchStoreId
      }
    })

    if (sb.success) {
      setSbData(sb.data)
    }
    let dept = await fetchApi.GET(wmService, {
      apiId: "dept",
      apiExtend: 'getBrandStoreIdToDept',
      apiData: {
          branchStoreId: contextData?.currentBranchStoreId
      }
  })
  if (dept.success) {

      setDeptData(dept.data)
  }
  form.resetFields(["sbId", "deptIds"])
  setContextData({ ...contextData, refresh: false })
  }
  /** 表头内容 */
  let columnsHead: Array<any> = [
    // {
    //   title: "采购商",
    //   dataIndex: "buyerName",
    // },
    {
      title: "部门",
      dataIndex: "deptName",
    },
    {
      title: <div>数量<Tooltip title="菜品数量，不包括赠送，退菜，退货"><QuestionCircleFilled /></Tooltip> </div>,
      dataIndex: "amount",
    },
    {
      title: <div>金额(折前)  <Tooltip title="所有菜品金额，不包括赠送，退菜，退货"><QuestionCircleFilled /></Tooltip> </div>,
      dataIndex: "advancePrice",

    },
    {
      title: "折扣",
      dataIndex: "discountPrice",

    },
    {
      title: <div>金额(折后)  <Tooltip title="所有菜品金额，不包括赠送，退菜，退货"><QuestionCircleFilled /></Tooltip> </div>,
      dataIndex: "reducedPrice",

    },
    // {
    //   title: <div>金额(退菜)  <Tooltip title="仅退菜金额"><QuestionCircleFilled /></Tooltip> </div>,
    //   dataIndex: "foodBackPrice",

    // },
    // {
    //   title: <div>数量(退菜)  <Tooltip title="仅退菜数量"><QuestionCircleFilled /></Tooltip> </div>,
    //   dataIndex: "foodBackAmount",

    // },
    {
      title: <div>金额(退货)  <Tooltip title="仅退货金额,特指支付后，线上退款部分"><QuestionCircleFilled /></Tooltip> </div>,
      dataIndex: "refundPrice",

    },
    {
      title: <div>数量(退货)  <Tooltip title="仅退货数量,特指支付后，线上退款部分"><QuestionCircleFilled /></Tooltip> </div>,
      dataIndex: "refundAmount",

    },

    {
      title: <div>金额(赠送)  <Tooltip title="仅赠送金额"><QuestionCircleFilled /></Tooltip> </div>,
      dataIndex: "giftPrice",

    },
    {
      title: <div>数量(赠送)  <Tooltip title="仅赠送数量"><QuestionCircleFilled /></Tooltip> </div>,
      dataIndex: "giftAmount",

    },


  ]
  const onFinish = () => {
    let branchStoreId = contextData?.currentBranchStoreId;
    if (!branchStoreId) {
      antMessage("error", '错误', "必须选择分店");
      return false
    }

    form.validateFields()
      .then(async (values: any) => {
        fetchApi.GET(wmService, {
          apiId: "financeReport",
          apiExtend: 'makeDept',
          apiData: {
            ...values,
            branchStoreId
          }
        }).then((res) => {
              
          if (res.success) {
            setUserName(res.data.userName)

            setRows(res.data.rows)

          }
        }).catch((err) => {
           showCustomNotification(notificationApi!, {
                                    type: 'error',
                                    message: '错误',
                                    description: err,
                                });;
        })



      })
      .catch((err) => {

         showCustomNotification(notificationApi!, {
                                    type: 'warning',
                                    message:"无法通过数据校验",
                                description:  "请输入所需数据!"
                                });;
      })





  }
  const changeSb = (e: any, option: any) => {

    if (option?.children) setSbName(option.children)

  }
  const changedCaCoMonStatus = (e: any, option: any) => {
    let caCoMonStatusName: Array<any> = []
    for (let i = 0; i < option?.length; i++) {
      caCoMonStatusName.push(option[i].children)
    }


    setCaCoMonStatusName(caCoMonStatusName.join(","))

  }
  return (
    <>
      {/* <BranchStoreList setDeptData={setDeptData} setSbData={setSbData} form={form}></BranchStoreList> */}
      <Form
        form={form}
        onFinish={onFinish}
      >
        <Row>
          <Col span={6}>
            <Form.Item
              label="市别"
              name="sbId"
            >
              <Select
                onChange={changeSb}
                allowClear
                placeholder="市别"
                optionFilterProp="children"
                filterOption={(input, option: any) => {
                  return true
                }}
              >
                {getList.general(sbData, 'sbName')}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="部门"
              name="deptIds"
            >
              <Select
                // orderTypeData
                mode="multiple"
                showSearch
                allowClear
                placeholder="请选择部门!"
                optionFilterProp="children"

                onSearch={(e) => getList.search('dept', setDeptData, e, {
                  andWhereObj: {
                    branchStoreId: contextData?.currentBranchStoreId
                  }

                })}
                filterOption={(input, option: any) => {
                  return true
                }}
              >
                {getList.general(deptData, 'deptName')}
              </Select>
            </Form.Item>
          </Col>

          <Col span={4}>
            <Form.Item
              label="菜状态"
              name="caCoMonStatusId"
            >
              <Select
                onChange={changedCaCoMonStatus}
                // orderTypeData
                mode="multiple"
                showSearch
                placeholder="请选择菜状态!"
                optionFilterProp="children"
                filterOption={(input, option: any) => {
                  return true
                }}
              >
                {getList.general(caCoMonStatus)}
              </Select>
            </Form.Item>
          </Col>


          <Col span={6}>
            <Form.Item

              label="日期"
              name="chooseTime"
            >
              <RangePicker

                format="YYYY-MM-DD"
              />
            </Form.Item>
          </Col>





          <Col span={6}>
            <Form.Item

              label="时间"
              name="time"
            >
              <RangePicker
                picker={"time"}
                format="H"
              />
            </Form.Item>
          </Col>






          <Col span={4}>
            <Form.Item >
              <Space>
                <Button type="primary" htmlType="submit">
                  统计
                </Button >
                <Button onClick={() => DownloadExcel(rows, "makeDept", { date: form.getFieldValue("chooseTime"), sbName, userName, caCoMonStatusName })}>下载EXCEL文件</Button>

                {/* purContrast */}
              </Space>
            </Form.Item>
          </Col>
        </Row>

      </Form>

      <div style={{ marginTop: "20px" }}>
        <Table
          pagination={false}
          // rowKey={(record: any, index: any) => {
          //   console.log(record,index);
          //   return 1
          //   // return index
          // }}
          columns={columnsHead}
        dataSource={rows}

        >
        </Table>

      </div>
    </>
  )
}
