import systemConfig from '../../../config/systematic';
import { Button, Checkbox, Col, DatePicker, Form, Input, Row, Select, Space, Table, Tooltip } from 'antd';
import React, { useContext, useEffect, useState } from 'react'
import getList from '../../universal/getList';
import fetchApi from '../../../utils/api/fetchApi';
import dayjs from 'dayjs';
import antMessage from '../../../utils/extend/AntdNotification'
import DownloadExcel from '../../report/DownloadExcel';
import { QuestionCircleFilled } from '@ant-design/icons';
import { SocketContext } from '../../../App';
import arrayHandle from '../../../utils/baseLib/arrayHandle';
import { showCustomNotification, useNotificationInstance } from '../../../utils/extend/AntdNotificationEx';
const { RangePicker } = DatePicker;
/** 表头内容 */
let columnsHead: Array<any> = [

    {
        title: <div>楼层 </div>,

        dataIndex: "floor",
        key: "floor",
        width: 10,
        header: "楼层",
        sortBy: 1,
        init: true,

    },

    {
        title: <div>总单数<QuestionCircleFilled /> </div>,

        dataIndex: "counts",
        key: "counts",
        width: 15,
        header: "总单数",
        sortBy: 3,
        init: true,

    },
    {
        title: <div>金额<Tooltip title="实收金额"><QuestionCircleFilled /></Tooltip> </div>,
        dataIndex: "totalRealMoney",
        key: "totalRealMoney",
        width: 20,
        header: "金额",
        sortBy: 7,
        init: true,

    },
    {
        title: "金额(折扣)",
        dataIndex: "totalAgioMoney",
        key: "totalAgioMoney",
        width: 10,
        header: "金额(折扣)",
        sortBy: 11,
        init: true,

    },
    {
        title: "实收服务费",
        dataIndex: "totalServiceCharge",
        key: "totalServiceCharge",
        width: 12,
        header: "实收服务费",
        sortBy: 13,
        init: true,

    },





]
/**楼层统计 */
export default function BrandStoreFloorStatistics(props: any) {
    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;
    const [form] = Form.useForm();
    const [sbData, setSbData] = useState<any>();
    const [sbName, setSbName] = useState<string>("所有");
    const [rows, setRows] = useState<Array<any>>([]);
   

    // console.log("initHead",initHead);

    const [isAllSelected, setIsAllSelected] = useState(false);
    const [selectedColumns, setSelectedColumns] = useState<Array<any>>([]);
    const [userName, setUserName] = useState<string>("")
    const useContextData = useContext(SocketContext);
    const { contextData, setContextData } = useContextData;
    const notificationApi = useNotificationInstance()

    useEffect(() => {
        const fetchData = async () => {
            form.setFieldsValue({
                "chooseTime": [dayjs(), dayjs()]
            })
            let initHead = columnsHead.filter((item: any) => {
                if (item.init) {
                    return item
                }
            })

            setSelectedColumns(initHead)
            if (contextData?.currentBranchStoreId) {
                if (contextData?.refresh) {
                    await initArgument();

                } else {
                    await initArgument()
                }
            }

        }
        fetchData()


    }, [contextData?.currentBranchStoreId])
    const handleColumnToggle = (key: any) => {
        setSelectedColumns((prev: any) => {
            const newColumns = [...prev];
            const index = newColumns.findIndex(column => column.key === key);
            if (index > -1) {
                newColumns.splice(index, 1);
            } else {
                newColumns.push(columnsHead.find((column: any) => column.key === key));
            }
            setIsAllSelected(newColumns.length === columnsHead.length);
            return newColumns;
        });
    };
    const handleSelectAll = (e: any) => {
        setIsAllSelected(e.target.checked);
        setSelectedColumns(e.target.checked ? columnsHead : []);
    };

    const initArgument = async () => {

        let sb = await fetchApi.GET(wmService, {
            apiId: "sb",
            apiExtend: 'getBrandStoreIdToSb',
            apiData: {
                branchStoreId: contextData?.currentBranchStoreId
            }
        })

        if (sb.success) {
            setSbData(sb.data)
        }

        // let branchStoreFloorData = await fetchApi.GET(wmService, {
        //     apiId: "branchStoreFloor",
        //     apiExtend: 'showList',
        //     apiData: {
        //         mountDataOptions: {
        //             userMount: {
        //                 isEmployees: true,
        //                 isDept: true
        //             }
        //         },
        //         sendPageData: {
        //             branchStoreId: contextData?.currentBranchStoreId,
        //         }


        //     }
        // })

        // if (branchStoreFloorData.success) {

        //     setBranchStoreFloor(branchStoreFloorData.data)
        // }
        form.resetFields(["sbId"])
        setContextData({ ...contextData, refresh: false })
    }
    const onFinish = () => {
        let branchStoreId = contextData?.currentBranchStoreId;
        if (!branchStoreId) {
            antMessage("error", '错误', "必须选择分店");
            return false
        }
        form.validateFields()
            .then(async (values: any) => {
                fetchApi.GET(wmService, {
                    apiId: "financeReport",
                    apiExtend: 'brandStoreFloorStatistics',
                    apiData: {
                        ...values,
                        // branchStoreId:"d648d6a0b27c11ed9cb12187c1fd477a"
                        branchStoreId
                    }
                }).then((res) => {
                 console.log("res",res);
                 
                    if (res.success) {
                        setUserName(res.data.userName)
                        setRows(res.data.rows)
                    }
                }).catch((err) => {
                    showCustomNotification(notificationApi!, {
                        type: 'error',
                        message: '错误',
                        description: err,
                    });;
                })
            })
            .catch((err) => {

                showCustomNotification(notificationApi!, {
                    type: 'warning',
                    message: "无法通过数据校验",
                    description: "请输入所需数据!"
                });;
            })


    }
    const changeSb = (e: any, option: any) => {

        if (option?.children) setSbName(option.children)

    }

  

    // const changedBranchStoreFloor = (e: any, option: any) => {
    //     let branchStoreFloorName: Array<any> = []
    //     for (let i = 0; i < option.length; i++) {
    //         branchStoreFloorName.push(option[i].children)
    //     }
    //     setBranchStoreFloorName(branchStoreFloorName.join("|"))
    // }

    return (
        <>
            {/* <BranchStoreList form={form} setRoomData={setRoomData} setBranchStoreFloor={setBranchStoreFloor} setSbData={setSbData} setRoomTypeData={setRoomTypeData} ></BranchStoreList> */}
            <Row style={{ marginTop: 16 }}>
                <Col span={2}>
                    <Checkbox onChange={handleSelectAll} checked={isAllSelected}>
                        全选
                    </Checkbox>
                </Col>
                {columnsHead.map((column: any) => (
                    <Col key={column.key} span={2}>
                        <Checkbox
                            checked={selectedColumns.some((sc: any) => sc.key === column.key)}
                            onChange={() => handleColumnToggle(column.key)}
                        >
                            {column.title}
                        </Checkbox>
                    </Col>
                ))}
            </Row>
            <Form
                form={form}
                onFinish={onFinish}
            >
                <Row>

                    <Col span={6}>
                        <Form.Item
                            label="市别"
                            name="sbId"
                        >
                            <Select
                                onChange={changeSb}
                                allowClear
                                placeholder="市别"
                                optionFilterProp="children"
                                filterOption={(input, option: any) => {
                                    return true
                                }}
                            >
                                {getList.general(sbData, 'sbName')}
                            </Select>
                        </Form.Item>
                    </Col>

                    {/* <Col span={10}>
                        <Form.Item
                            label="分店楼层"
                            name="branchStoreFloorIds"
                        >
                            <Select
                                // orderTypeData
                                mode="multiple"
                                showSearch
                                allowClear
                                placeholder="请选择房型!"
                                optionFilterProp="children"
                                onChange={changedBranchStoreFloor}
                                filterOption={(input, option: any) => {
                                    return true
                                }}
                            >
                                {getList.general(branchStoreFloor, 'floor')}
                            </Select>
                        </Form.Item>
                    </Col> */}
                  



                    <Col span={6}>
                        <Form.Item
                            label="日期"
                            name="chooseTime"
                        >
                            <RangePicker
                                format="YYYY-MM-DD"
                            />
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item

                            label="时间"
                            name="time"
                        >
                            <RangePicker
                                picker={"time"}
                                format="H"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item >
                            <Space>
                                <Button type="primary" htmlType="submit">
                                    统计
                                </Button >
                                <Button onClick={() => DownloadExcel(rows, "brandStoreFloorStatistics", { date: form.getFieldValue("chooseTime"), sbName, userName, columnsHead: arrayHandle.sort(selectedColumns, "sortBy", "asc") })}>下载EXCEL文件</Button>

                                {/* purContrast */}
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>

            </Form>

            <div style={{ marginTop: "20px" }}>
                <Table
                    size={"middle"}
                    pagination={false}
                    // rowKey={(record: any, index: any) => {
                    //     return index
                    // }}
                    columns={arrayHandle.sort(selectedColumns, "sortBy", "asc")}

                    dataSource={rows}

                >
                </Table>

            </div>
        </>
    )
}
