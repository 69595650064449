import { Col, Form, Input, Row, Select, Switch } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import fetchApi from '../../../utils/api/fetchApi';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';
import getList from '../../universal/getList';
import systemConfig from '../../../config/systematic';

const { TextArea } = Input;

export default function ReserveMenuStyleEdit(props: any) {

    const [form] = Form.useForm(); //form 
    const { data } = props;

    const ref = useRef<any>(undefined);

    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;

    /**预订菜单模块 */
    const [reserveMenuModuleData, setReserveMenuModuleData] = useState<any>();

    const [disable, setDisable] = useState<any>(false);
    
    const [replaceData, setReplaceData] = useState<boolean>(true);

    const [reserveMenuModuleId, setReserveMenuModuleId] = useState<any>(false);

    /**数据 */
    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
            // form.setFieldsValue({ branchStoreId: localStorage.getItem("currentBranchStoreId") });

            ref.current.row.then(async (res: any) => {
                if (typeof res === 'undefined') { return }
                if (res.success === true) {
                    if (data.type === "EDIT") {
                        if (res.data.isReplace) {
                            setReplaceData(!res.data.isReplace);
                        }

                        setDisable(true);
                        setReserveMenuModuleId(res.data.reserveMenuModuleId);
                    }
                }
            })
        }

        const fetchData = async () => {

            fetchApi.GET(wmService, {
                apiId: 'generalData',
                apiVariable: 'reserveMenuModule',
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setReserveMenuModuleData(res.data);
                }
            });

        }
        fetchData()
    }, [])

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };

    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    /**
     * 更改Select
     */
    const changeSelect = async (key: any, rows: any, e: any) => {
        setReserveMenuModuleId(e);
        if (e !== 'category' || e !== 'foodName' || e !== 'detail') {
            form.setFieldsValue({ styleEn: null });
        }
        switch (key) {
            case "sortBy":
                for (var i = 0, len = rows.length; i < len; i++) {
                    if (rows[i].id === e) {
                        form.setFieldsValue({ sortBy: rows[i].sortBy });
                    }
                }
                break;
        }
    };

    /** 控制Switch */
    const handleSwitch = (e: any, setData: any) => {
        setData(!e);
    };

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'reserveMenuStyle'
    };

    return (
        <>
            <WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '预订菜单样式'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={1000}
            >
                <Form
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                // onKeyDown={(e: any) => {
                //     if (e.key === 'Enter') {
                //         handleOk(e)
                //     }
                // }}
                >
                    <Row gutter={[16, 0]}>
                        <Col span={24}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item
                                label="是否替换"
                                name="isReplace"
                                valuePropName="checked">
                                <Switch
                                    checkedChildren="是"
                                    unCheckedChildren="否" 
                                    onChange={(e) => handleSwitch(e, setReplaceData)}
                                    />
                            </Form.Item>
                        </Col>

                        <Col span={20}>
                            <Form.Item
                                label="替换内容"
                                name="replaceContent">
                                <Input disabled={replaceData}/>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="模块"
                                name="reserveMenuModuleId"
                                rules={[{ required: true, message: '请选择模块!' }]}
                            >
                                <Select
                                    showSearch
                                    disabled={disable}
                                    placeholder="模块"
                                    optionFilterProp="children"
                                    onChange={(e) => changeSelect("sortBy", reserveMenuModuleData, e)}
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(reserveMenuModuleData, 'name')}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="结构内容"
                                name="structureContent"
                                rules={[{ required: true, message: '请输入结构内容!' }]}
                            >
                                <TextArea
                                    placeholder="结构内容"
                                    autoSize={{ minRows: 5, maxRows: 7 }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="样式内容"
                                name="style"
                                rules={[{ required: true, message: '请输入样式内容!' }]}
                            >
                                <TextArea
                                    placeholder="样式内容"
                                    autoSize={{ minRows: 5, maxRows: 7 }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="样式内容(英)"
                                name="styleEn"
                                rules={[{ required: (reserveMenuModuleId === 'category' || reserveMenuModuleId === 'foodName' || reserveMenuModuleId === 'detail') ? true : false, message: '请输入样式内容(英)!' }]}
                            >
                                <TextArea
                                    disabled={(reserveMenuModuleId === 'category' || reserveMenuModuleId === 'foodName' || reserveMenuModuleId === 'detail') ? false : true}
                                    placeholder="样式内容(英)"
                                    autoSize={{ minRows: 5, maxRows: 7 }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="排序"
                                name="sortBy">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                <Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}