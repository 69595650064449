import { v1 as uuidv1 } from 'uuid';
import { defaultRtn, idLenghtType, letterFixType, rtnType } from '../declareTypes';

/** Uuid定量 大写、小写与数字 */
const shortArray = ["a", "b", "c", "d", "e", "f",
    "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s",
    "t", "u", "v", "w", "x", "y", "z", "0", "1", "2", "3", "4", "5",
    "6", "7", "8", "9", "A", "B", "C", "D", "E", "F", "G", "H", "I",
    "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V",
    "W", "X", "Y", "Z"];

/** Uuid定量 大写与数字 */
const shortUppercaseArray = ["A", "B", "C", "D", "E", "F",
    "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S",
    "T", "U", "V", "W", "X", "Y", "Z", "0", "1", "2", "3", "4", "5",
    "6", "7", "8", "9"];

/** Uuid定量 小写与数字 */
const shortLowercaseArray = ["a", "b", "c", "d", "e", "f",
    "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s",
    "t", "u", "v", "w", "x", "y", "z", "0", "1", "2", "3", "4", "5",
    "6", "7", "8", "9"];

/** id处理 */
const idHandle = async () => { };

/** interface shortUuidOptions */
export interface ShortUuidOptions {
    /** 短码长度，默认：32位 */
    idLenght?: idLenghtType,
    /** 字母类型，默认：lowercase */
    letterFix?: letterFixType
};

/**
 * 获取Uuid短码
 * @param options 选项
 * @returns 返回 Uuid
 */
idHandle.getShortUuid = (options?: ShortUuidOptions) => {
    let { idLenght, letterFix } = options || {};
    if (typeof idLenght === 'undefined') { idLenght = 32 };
    if (typeof letterFix === 'undefined') { letterFix = "LOWERCASE" };
    let uid = uuidv1();
    uid = uid.replace(/-/g, "");
    let buffer = [];
    let lenI = 16;
    switch (idLenght) {
        case 8:
            lenI = 8
            break;
        case 32:
            lenI = 32
            break;
        default:
            lenI = 16
            break;
    };
    for (let i = 0; i < lenI; i++) {
        let start = 0;
        let end = 0;
        switch (lenI) {
            case 8:
                start = i * 4;
                end = i * 4 + 4;
                break;
            case 32:
                if (letterFix !== "LOWERCASE") {
                    uid.toUpperCase();
                }
                return uid;
                break;
            default:
                start = i * 2;
                end = i * 2 + 2;
                break;
        }
        let str = uid.substring(start, end);
        if (letterFix === "LOWERCASE") {
            buffer.push(shortLowercaseArray[parseInt(str, 16) % 36])
        }
        else if (letterFix === "UPPERCASE") {
            buffer.push(shortUppercaseArray[parseInt(str, 16) % 36])
        }
        else {
            buffer.push(shortArray[parseInt(str, 16) % 62])
        }
    }
    return buffer.join("");
};

/** interface SnowflakeOptions */
export interface SnowflakeOptions {
    /** 前缀id */
    prefixId?: string,
    /** 5位机器ID (0-31) */
    workerId?: bigint,
    /** 5位数据中心ID (0-31) */
    dataCenterId?: bigint,
    /** 起始时间戳13位，毫秒/秒（默认2022-07-01 00:00:00 0000） */
    epoch?: bigint,
    /** 后缀id */
    suffixId?: string
};

/**
 * 创建Snowflake ID生成器（函数式实现）
 * @param options 选项
 */
idHandle.createSnowflake = (
    options?: SnowflakeOptions
) => {
    let { prefixId, workerId = 1n, dataCenterId = 1n, epoch = 1656633600000n, suffixId } = options || {};
    // 校验参数范围
    if (workerId < 0n || workerId > 31n) {
        workerId = 1n;
    }
    if (dataCenterId < 0n || dataCenterId > 31n) {
        dataCenterId = 1n;
    }
    // 位移配置
    /** 序列号位数 */
    const workerShift = 12n;
    /** workerShift + 5位机器ID */
    const dcShift = 17n;
    /** dcShift + 5位数据中心ID */
    const tsShift = 22n;
    /** 2^12 -1 */
    const seqMask = 4095n;
    // 状态变量
    let lastTimestamp = -1n;
    let sequence = 0n;
    /** 全局偏移量 */
    let timeOffset = 0n;
    // 核心生成方法
    return (createOptions?: {
        /** 前缀id */
        currentPrefixId?: string,
        /** 后缀id */
        currentSuffixId?: string
    }) => {
        const { currentPrefixId = prefixId, currentSuffixId = suffixId } = createOptions || {};
        let ts = BigInt(Date.now());
        // 时钟回拨检测
        if (ts < lastTimestamp) {
            timeOffset += lastTimestamp - ts;
            // 临时使用旧时间戳
            ts = lastTimestamp;
        } else {
            // 重置偏移量
            timeOffset = 0n;
        }
        // 同一毫秒内递增序列号
        if (ts === lastTimestamp) {
            sequence = (sequence + 1n) & seqMask;
            if (sequence === 0n) {
                // 序列耗尽时等待下一毫秒
                while ((ts = BigInt(Date.now())) <= lastTimestamp) { }
            }
        } else {
            sequence = 0n;
        }
        lastTimestamp = ts;
        /** 组合各部分生成ID */
        const snowflakeId = (
            ((ts - epoch) << tsShift) |
            (dataCenterId << dcShift) |
            (workerId << workerShift) |
            sequence
        ).toString();
        return `${typeof currentPrefixId !== 'undefined' ? currentPrefixId : ''}${snowflakeId}${typeof currentSuffixId !== 'undefined' ? currentSuffixId : ''}`;
    };
};

/**
 * 生成可排序短码
 * @param options 选项
 */
idHandle.generateSortableId = (options?: {
    /** 前缀id */
    prefixId?: string,
    /** 时间id长度，默认：8位，建议8位或以上 */
    timeIdLength?: number,
    /** 机器id，如需要，必需传入2位（校验），如: 'S1' */
    machineId?: string,
    /** 随机id长度，默认: 7 */
    randomIdLength?: number,
    /** 后缀id */
    suffixId?: string
}): rtnType => {
    const rtn = defaultRtn();
    try {
        const { prefixId, timeIdLength = 8, machineId, randomIdLength = 7, suffixId } = options || {};
        const BASE62 = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
        /** 时间戳转Base62 */
        const toBase62 = (num: number): string => {
            let result = '';
            do {
                result = BASE62[num % 62] + result;
                num = Math.floor(num / 62);
            } while (num > 0);
            return result.padStart(timeIdLength, '0');
        }
        /** 1. 时间戳部分 */
        const timePart = toBase62(Date.now());
        /** 2. 机器id */
        let machineIdPart = '';
        if (typeof machineId !== 'undefined') {
            // 机器码校验（必须为2位Base62字符）
            if (machineId.length !== 2 || !/^[0-9a-zA-Z]{2}$/.test(machineId)) {
                //如不符合规则，使用默认：'A1'
                machineIdPart = 'A1';
            } else {
                machineIdPart = machineId;
            }
        }
        /** 3. 安全随机部分（使用加密API）*/
        const randomPart = Array.from({ length: randomIdLength }, () => {
            const randomBuffer = new Uint32Array(1);
            crypto.getRandomValues(randomBuffer);
            return BASE62[randomBuffer[0] % 62];
        }).join('');
        //组合
        rtn.success = true;
        rtn.data = `${typeof prefixId !== 'undefined' ? prefixId : ''}${timePart}${machineIdPart}${randomPart}${typeof suffixId !== 'undefined' ? suffixId : ''}`;
    }
    catch (error: any) {
        rtn.message = '错误！';
        if (error) {
            const { message } = error;
            rtn.message = `错误！${message}`;
        }
    }
    return rtn;
};

export default idHandle;